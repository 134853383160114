<template>
  <div class="wp-block-group is-style-section alignfull select-investments bg-gray-1 pt-28">
    <div class="wp-block-columns">
      <div class="wp-block-column">
        <div class="flex items-baseline justify-between" data-sal="slide-up" data-sal-delay="150" data-sal-easing="ease-out-sine" data-sal-duration="600">
          <h3 class="has-h-3-font-size mb-16">{{ $t("Select Investments") }}</h3>
          <a class="btn btn--primary group relative flex items-start text-black" href="/portfolio/"
            ><svg class="bullet stroke-theme fill-theme absolute left-0 top-[4px] shrink-0 origin-left scale-100 transform transition-all duration-300 group-hover:scale-125 group-hover:fill-transparent" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" role="presentation">
              <circle cx="8" cy="8" r="6" stroke-width="2"></circle></svg
            ><span class="text block !font-normal !leading-24 transition-all duration-300 group-hover:translate-x-1">{{ $t("Portfolio") }}</span
            ><svg class="arrow stroke-current absolute right-0 top-[6px] shrink-0 transition-all duration-300" width="13" height="12" viewBox="0 0 13 12" fill="none" stroke="#1E1E1E" stroke-width="2" xmlns="http://www.w3.org/2000/svg" role="presentation">
              <path class="arrow transition-all duration-300" d="M6 1L11 6L6 11"></path>
              <path class="arrow-line transition-all duration-300" d="M11 6L0 6"></path></svg
          ></a>
        </div>
        <div class="wp-block-portfolio-carousel" data-sal="slide-up" data-sal-delay="150" data-sal-easing="ease-out-sine" data-sal-duration="600">
          <div class="relative">
            <!-- <button
                                class="portfolio--nav-l absolute top-1/2 -translate-y-1/2 left-0 z-20 hidden cursor-pointer items-center outline-[auto] transition-all md:flex swiper-button-disabled"
                                aria-label="Previous slide" disabled=""><svg
                                    class="rotate-90 transition-all duration-300" width="48" height="48"
                                    viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"
                                    role="presentation">
                                    <circle class="stroke-current fill-transparent transition-colors duration-300"
                                        cx="24" cy="24" r="23" transform="rotate(90 24 24)" stroke-width="2"></circle>
                                    <path class="stroke-current transition-all duration-300 delay-300"
                                        d="M31 21L24 28L17 21" stroke-width="2"></path>
                                    <path stroke-dasharray="0"
                                        class="stroke-current transition-all delay-100 duration-300" d="M24 15L24 31"
                                        stroke-width="2" style="stroke-dasharray: 16; stroke-dashoffset: -16;"></path>
                                </svg>
                            </button> -->
            <div class="swiper swiper-initialized swiper-horizontal mr-[72px] rounded-[8px] md:mx-[72px]">
              <div class="swiper-wrapper w-max" style="transform: translate3d(0px, 0px, 0px)">
                <div class="swiper-slide h-auto w-auto swiper-slide-active" style="margin-right: 24px">
                  <div id="adare-pharmaceuticals" class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]" role="button" tabindex="0"><img src="@/assets/img/platforms/capital/tpgCapital/Adare-logo-1.png" alt="Adare Pharmaceuticals logo" class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24" /></div>
                </div>
                <div class="swiper-slide h-auto w-auto swiper-slide-next" style="margin-right: 24px">
                  <div id="advent-software" class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]" role="button" tabindex="0"><img src="@/assets/img/platforms/capital/tpgCapital/Advent.png" alt="Advent Software logo" class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24" /></div>
                </div>
                <div class="swiper-slide h-auto w-auto" style="margin-right: 24px">
                  <div id="allogene-therapeutics" class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]" role="button" tabindex="0"><img src="@/assets/img/platforms/capital/tpgCapital/Allogene.png" alt="Allogene Therapeutics logo" class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24" /></div>
                </div>
                <div class="swiper-slide h-auto w-auto" style="margin-right: 24px">
                  <div id="alltel" class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]" role="button" tabindex="0"><img src="@/assets/img/platforms/capital/tpgCapital/Alltel.png" alt="Alltel logo" class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24" /></div>
                </div>
                <div class="swiper-slide h-auto w-auto" style="margin-right: 24px">
                  <div id="aptalis-pharma" class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]" role="button" tabindex="0"><img src="@/assets/img/platforms/capital/tpgCapital/Aptalis.png" alt="Aptalis Pharma logo" class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24" /></div>
                </div>
                <div class="swiper-slide h-auto w-auto" style="margin-right: 24px">
                  <div id="armstrong" class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]" role="button" tabindex="0"><img src="@/assets/img/platforms/capital/tpgCapital/Armstrong.png" alt="Armstrong logo" class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24" /></div>
                </div>
                <div class="swiper-slide h-auto w-auto" style="margin-right: 24px">
                  <div id="askbio" class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]" role="button" tabindex="0"><img src="@/assets/img/platforms/capital/tpgCapital/AskBio.png" alt="AskBio logo" class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24" /></div>
                </div>
                <div class="swiper-slide h-auto w-auto" style="margin-right: 24px">
                  <div id="astound" class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]" role="button" tabindex="0"><img src="@/assets/img/platforms/capital/tpgCapital/Astound.png" alt="Astound logo" class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24" /></div>
                </div>
                <div class="swiper-slide h-auto w-auto" style="margin-right: 24px">
                  <div id="beaver-visitec-international" class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]" role="button" tabindex="0"><img src="@/assets/img/platforms/capital/tpgCapital/Beaver-Visitec-International.png" alt="Beaver-Visitec International logo" class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24" /></div>
                </div>
                <div class="swiper-slide h-auto w-auto" style="margin-right: 24px">
                  <div id="beringer" class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]" role="button" tabindex="0"><img src="@/assets/img/platforms/capital/tpgCapital/beringer.png" alt="Beringer logo" class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24" /></div>
                </div>
                <div class="swiper-slide h-auto w-auto" style="margin-right: 24px">
                  <div id="boomi" class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]" role="button" tabindex="0"><img src="@/assets/img/platforms/capital/tpgCapital/boomi-logo.png" alt="Boomi logo" class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24" /></div>
                </div>
                <div class="swiper-slide h-auto w-auto" style="margin-right: 24px">
                  <div id="burger-king" class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]" role="button" tabindex="0"><img src="@/assets/img/platforms/capital/tpgCapital/Burger-King.png" alt="Burger King logo" class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24" /></div>
                </div>
                <div class="swiper-slide h-auto w-auto" style="margin-right: 24px">
                  <div id="ccc-information-services" class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]" role="button" tabindex="0"><img src="@/assets/img/platforms/capital/tpgCapital/CCC-Information-Services.png" alt="CCC Information Services logo" class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24" /></div>
                </div>
                <div class="swiper-slide h-auto w-auto" style="margin-right: 24px">
                  <div id="chobani" class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]" role="button" tabindex="0"><img src="@/assets/img/platforms/capital/tpgCapital/Chobani.png" alt="Chobani logo" class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24" /></div>
                </div>
                <div class="swiper-slide h-auto w-auto" style="margin-right: 24px">
                  <div id="convey-health" class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]" role="button" tabindex="0"><img src="@/assets/img/platforms/capital/tpgCapital/Convey.png" alt="Convey Health logo" class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24" /></div>
                </div>
                <div class="swiper-slide h-auto w-auto" style="margin-right: 24px">
                  <div id="creative-artists-agency" class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]" role="button" tabindex="0"><img src="@/assets/img/platforms/capital/tpgCapital/Creative-Artists-Agency.png" alt="Creative Artists Agency logo" class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24" /></div>
                </div>
                <div class="swiper-slide h-auto w-auto" style="margin-right: 24px">
                  <div id="delinea" class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]" role="button" tabindex="0"><img src="@/assets/img/platforms/capital/tpgCapital/delinea-logo-wordmark-tagline-tm-rgb-purple.png" alt="Delinea logo" class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24" /></div>
                </div>
                <!-- <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="digital-ai"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Digital.ai1_.png"
                                                alt="Digital.ai logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="directv"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/DirectTV.png"
                                                alt="DirecTV logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="ellodi-pharmaceuticals"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/ellodi2.png"
                                                alt="Ellodi Pharmaceuticals logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="ellucian"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/ellucian.png"
                                                alt="Ellucian logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="enlivant"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Enlivant.png"
                                                alt="Enlivant logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="entertainent-partners"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Entertainment-Partners-logo.png"
                                                alt="Entertainent Partners logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="envisionrx"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/EnvisionRx.png"
                                                alt="EnvisionRX logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div> -->
                <!-- <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="exactech"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Exactech.png"
                                                alt="Exactech logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="eze-software-group"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Eze-Software-Group.png"
                                                alt="Eze Software Group logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="fenwal"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/fenwal.png"
                                                alt="Fenwal logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="fidelity-national-financial-services"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Fidelity-National-Financial.png"
                                                alt="Fidelity National Financial Services logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="gelsons"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/gelsons.png"
                                                alt="Gelson’s logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="global-music-rights"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Global-Music-Rights.png"
                                                alt="Global Music Rights logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="graphic-packaging"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Graphic-Packaging-1.png"
                                                alt="Graphic Packaging logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="grohe"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/grohe.png"
                                                alt="Grohe logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="healthscope"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Healthscope-1.png"
                                                alt="Healthscope logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="hotwire"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Hotwire.png"
                                                alt="Hotwire logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="ims-health"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/IMS-Health-1.png"
                                                alt="IMS Health logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="immucor"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/immucor.png"
                                                alt="Immucor logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="intergraph"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/intergraph.png"
                                                alt="Intergraph logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="kadiant"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Kadiant.png"
                                                alt="Kadiant logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="kelsey-seybold"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Kelsey-Seybold.png"
                                                alt="Kelsey-Seybold logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="kindred-healthcare"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Kindred-Healthcare.png"
                                                alt="Kindred Healthcare logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="llamasoft"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Llamasoft.png"
                                                alt="LLamasoft logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="lpl"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/LPL.png" alt="LPL logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="lenovo"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Lenovo.png"
                                                alt="Lenovo logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="lenta"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Lenta.png"
                                                alt="Lenta logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="life-time"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Lifetime.png"
                                                alt="Life Time logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="lifestance-health"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/LifeStance-Health.png"
                                                alt="LifeStance Health logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="lynda-com"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Lynda.png"
                                                alt="Lynda.com logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="memc"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/MEMC.png" alt="MEMC logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="mcafee"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/McAfee.png"
                                                alt="McAfee logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="mey"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/mey.png" alt="Mey logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="neiman-marcus"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Neiman-Marcus.png"
                                                alt="Neiman Marcus logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="nintex"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Nintex.png"
                                                alt="Nintex logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="norwegian-cruise-line"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Norwegian-Cruise-Line.png"
                                                alt="Norwegian Cruise Line logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="ontex"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Ontex.png"
                                                alt="Ontex logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="par-pharmaceutical"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Par-Pharmaceutical.png"
                                                alt="Par Pharmaceutical logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="petco"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Petco.png"
                                                alt="Petco logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="planview"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Planview.png"
                                                alt="Planview logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="pointpark-properties"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/PointPark-Properties.png"
                                                alt="PointPark Properties logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="quintiles"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Quintiles.png"
                                                alt="Quintiles logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="st-residential"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/ST-Residential.png"
                                                alt="ST Residential logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="sabre"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Sabre.png"
                                                alt="Sabre logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="seagate"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Seagate.png"
                                                alt="Seagate logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="surgical-care-affiliates"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Surgical-Care-Affiliates.png"
                                                alt="Surgical Care Affiliates logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="the-warranty-group"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Warranty-Group-The.png"
                                                alt="The Warranty Group logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="transplace-holdings"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Transplace.png"
                                                alt="Transplace Holdings logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="transporeon"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Transporeon.png"
                                                alt="Transporeon logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="troon"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Troon.png"
                                                alt="Troon logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="uber"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Uber.png" alt="Uber logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="vertafore"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Vertafore.png"
                                                alt="Vertafore logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="vice-media"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Vice-Media.png"
                                                alt="Vice Media logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="viking-cruises"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Viking-Cruises.png"
                                                alt="Viking Cruises logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="wellsky"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/WellSky.png"
                                                alt="WellSky logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div>
                                    <div class="swiper-slide h-auto w-auto" style="margin-right: 24px;">
                                        <div id="wind-river"
                                            class="group aspect-1 flex-[0_0_134px] cursor-pointer select-none snap-start rounded-[8px] bg-white p-[31px] md:p-[34px] xl:flex-[0_0_164px]"
                                            role="button" tabindex="0"><img
                                                src="@/assets/img/platforms/capital/tpgCapital/Wind-River.png"
                                                alt="Wind River logo"
                                                class="h-[72px] w-[72px] object-contain grayscale filter group-hover:grayscale-0 md:h-24 md:w-24">
                                        </div>
                                    </div> -->
              </div>
            </div>
            <!-- <button
                                class="portfolio--nav-r absolute top-1/2 -translate-y-1/2 right-0 z-20 flex cursor-pointer items-center outline-[auto] transition-all"
                                aria-label="Next slide"><svg class="-rotate-90 transition-all duration-300" width="48"
                                    height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"
                                    role="presentation">
                                    <circle class="stroke-current fill-transparent transition-colors duration-300"
                                        cx="24" cy="24" r="23" transform="rotate(90 24 24)" stroke-width="2"></circle>
                                    <path class="stroke-current transition-all duration-300 delay-300"
                                        d="M31 21L24 28L17 21" stroke-width="2"></path>
                                    <path stroke-dasharray="0"
                                        class="stroke-current transition-all delay-100 duration-300" d="M24 15L24 31"
                                        stroke-width="2" style="stroke-dasharray: 16; stroke-dashoffset: -16;"></path>
                                </svg>
                            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup></script>

<style lang="less" scoped></style>
