const Chinese_Taiwan = {
    'Approach': '接近',
    'Our Approach': '我們的方法',
    'Healthcare': '醫療保健',
    'SET (Software & Enterprise Tech)': 'SET（軟件和企業技術）',
    'IDMC (Internet, Digital Media & Communications)': 'IDMC（互聯網，數字媒體和通信）',
    'Consumer': '消費者',
    'Business Services': '商業服務',

    'Platforms': '平臺',
    'Capital': '資本',
    'Capital Overview': '資本概覽',
    'TPG Capital': 'TPG資本',
    'TPG Capital Asia': 'TPG亞洲資本',
    'TPG Healthcare Partners': 'TPG醫療保健合作伙伴',

    'Growth': '增長',
    'Growth Overview': '增長概覽',
    'TPG Growth': 'TPG增長',
    'TPG Digital Media': 'TPG數字媒體',
    'TPG Tech Adjacencies': 'TPG 技術相鄰關係',
    'TPG Life Sciences Innovations': 'TPG生命科學創新',

    'Impact': '影響',
    'Impact Overview': '影響概覽',
    'The Rise Funds': '上升基金',
    'TPG Rise Climate': 'TPG上升氣候',
    'Evercare': 'Evercare',

    'TPG Angelo Gordon': 'TPG安傑洛戈登',
    'TPG Angelo Gordon Overview': 'TPG安傑洛戈登概述',
    'TPG AG Credit Solutions': 'TPG AG信用解決方案',
    'TPG AG CLOs': 'TPG AG CLOs',
    'TPG AG Structured Credit & Specialty Finance': 'TPG AG 結構化信貸和專業金融',
    'TPG AG Middle Market Direct Lending': 'TPG AG 中間市場直接貸款',
    'TPG AG Multi-Strategy': 'TPG AG多戰略',
    'TPG AG Global Real Estate': 'TPG AG全球房地產',
    'TPG AG Net Lease Real Estate': 'TPG AG淨租賃房地產',

    'Real Estate': '房地產',
    'Real Estate Overview': '房地產概覽',
    'TPG Real Estate Partners': 'TPG房地產合作伙伴',
    'TPG Thematic Advantage Core-Plus': 'TPG主題優勢核心+',
    'TPG Real Estate Finance Trust, Inc.': 'TPG房地產金融信託公司',
    'TPG Real Estate Finance Trust': 'TPG房地產金融信託',
    
    'Market Solutions': '市場解決方案',
    'Market Solutions Overview': '市場解決方案概述',
    'Capital Markets': '資本市場',
    'TPG Public Equity Partners': 'TPG公共股權合作伙伴',
    'TPG Pace Group': 'TPG Pace Group',

    'Portfolio': '投資組合',

    'Engagement': '參與',
    'ESG': 'ESG',
    'Diversity, Equity, & Inclusion': '多樣性、公平性和包容性',
    
    'News & Insights': '新聞 & 見解',
    'Insights': '洞察力',
    'Press Releases': '新聞稿',
    'Newsletters': '『時事通訊』',
    'Media Contacts': '媒體聯繫人',
    
    'About': '關於',
    'About Us ': '關於我們',
    'Who We Are ': '我們是誰',
    'Contact Us': '聯繫我們',

    'LP Login': 'LP 登錄',
    'Shareholders': '股東',

    '© 2024 Tarrant Capital IP, LLC, All Rights Reserved. TPG, the Half Star logo, and related marks and logos are service marks or registered marks owned by Tarrant Capital IP, LLC.': '© 2024 Tarrant Capital IP, LLC, 保留所有權利。 TPG、Half Star徽標及相關商標和徽標均爲Tarrant Capital IP, LLC擁有的服務商標或註冊商標。',
    'Legal Notices & Terms Of Use': '法律聲明和使用條款',
    'Privacy & Cybersecurity': '隱私與網絡安全',
    'CCPA Privacy Statement': '《CCPA隱私聲明》',
    'UK/EU Disclosures': '英國/歐盟披露',
    'Web Fraud & Phishing': '網絡欺詐和網絡釣魚',
    'Transparency in Coverage': '覆蓋範圍透明度',

    'Meet TPG': '遇見TPG',
    'Focused on Innovation': '注重創新',
    'A leader in the alternative asset space, TPG manages $224 billion in assets through a principled focus on innovation.': '作爲替代資產領域的領導者，TPG通過原則性地關注創新來管理2,240億美元的資產。',

    'Overview': '概覽',
    'Built for a distinctive approach': '爲獨特的方法而構建',
    'Through TPG’s family office roots,entrepreneurial heritage, and West Coast base, we have developed a differentiated approach to alternative investments based on innovation, organic growth, and a culture of openness and collaboration. Since our founding in 1992, we have developed a distinct position in the industry, with a broadly diversified set of investment strategies, including private equity, impact, credit, real estate, and market solutions.': '通過TPG家族辦公室的根源，創業遺產和西海岸基地，我們開發了一種基於創新，有機增長以及開放與合作文化的差異化替代投資方法。 自1992年成立以來，我們在行業中發展了獨特的地位，擁有廣泛多樣化的投資策略，包括私募股權，影響力，信貸，房地產和市場解決方案。',
    'Our Portfolio Companies': '我們的投資組合公司',

    'TPG at a Glance': 'TPG一覽',
    '$224B': '$224B',
    'AUM': 'AUM',
    '1800+': '1800+',
    'Employees around the world': '世界各地的員工',
    '400': '400',
    'Diverse Directors appointed by our portfolio companies since 2017': '自2017年以來由投資組合公司任命的多元化董事',
    '300+': '300+',
    'Active Portfolio Companies Headquartered in more than 30 countries': '總部位於30多個國家的活躍投資組合公司',
    '52': '52',
    'Operations professionals with specialized sector knowledge and experience': '具有專業行業知識和經驗的運營專業人員',
    '75%': '75%',
    'of TPG’s investors are invested across three or more of our products': 'TPG的投資者投資於我們的三種或更多產品',

    'What We Do': '我們做什麼',
    'Our Platforms': '我們的平臺',
    'TPG puts capital to work through six platforms. Throughout our history, we have grown organically into opportunities we identified through deep thematic work in key sectors and geographies, and through strategic acquisitions to diversify our offerings.': 'TPG通過六個平臺投入資金。 縱觀我們的歷史，我們已經有機地成長爲機會，我們通過在關鍵部門和地區的深入主題工作，並通過戰略收購，以多樣化我們的產品發現',

    '$71B AUM': '$710億澳元',
    'Large-scale control-oriented private equity investing': '大規模控制型私募股權投資',
    '$27B AUM': '$ 270億澳元',
    'Growth equity and middle market private equity investing': '成長股和中端市場私募股權投資',
    '$19B AUM': '$190億澳元',
    'Private equity investing focused on both societal and financial outcomes': '私募股權投資專注於社會和財務結果',
    '$80B AUM': '$800億澳元',
    'Global credit and real estate investing platform': '全球信貸和房地產投資平臺',
    '$18B AUM': '$180億澳元',
    'Diversified, thematic Real Estate investing platform': '多元化、主題化的房地產投資平臺',
    '$8B AUM': '$80億澳元',
    'Differentiated strategies built to address specific market opportunities': '爲應對特定市場機會而建立的差異化戰略',

    'TPG News': 'TPG新聞',
    'All News': '所有新聞',
    'Sector Insights': '行業洞察',
    'Attractive Deal Dynamics in the GP-Led Secondaries Market': 'GP主導的二手車市場的誘人交易動態',
    '5月.13日.2024': '5月.13日.2024',
    'Read More': '閱讀更多',
    
    'Press Release': '新聞稿',
    'TPG Raises Nearly US$8 Billion for Asian Private Equity and Real Estate': 'TPG爲亞洲私募股權和房地產籌集了近80億美元',
    '5月.08日.2024': '5月.08日.2024',

    'TPG Reports First Quarter 2024 Results': 'TPG報告2024年第一季度業績',

    'A Distinct Style Of Investing': '獨特的投資風格',

    'TPG was built with a unique perspective and approach': 'TPG是以獨特的視角和方法構建的',
    'At TPG, we are transformational investors. We aim to drive meaningful long-term value and leave our companies healthier, more strategic, and more growth-oriented than they were when we invested.': '在TPG，我們是轉型投資者。 我們的目標是推動有意義的長期價值，使我們的公司比我們投資時更健康，更具戰略性和更注重增長。',
    'Sector-focused insight shared across platforms': '跨平臺共享的以行業爲中心的見解',
    'Our focus on sectors is at the core of our investment strategy. Our investment teams are principally organized around sectors—which means that investors who focus on diverse products and strategies across the firm work together to build deep insight and relationship networks in our key sectors. Sharing ideas and intellectual capital across the firm gives us distinctive insight, and this cross-platform leverage enables us to bring the right capital solutions to compelling opportunities.': '我們對行業的關注是我們投資戰略的核心。 我們的投資團隊主要圍繞行業組織，這意味着專注於公司不同產品和戰略的投資者共同努力，在我們的關鍵領域建立深入的洞察力和關係網絡。 在整個公司中分享想法和知識資本爲我們提供了獨特的見解，這種跨平臺的槓桿作用使我們能夠爲引人注目的機會帶來正確的資本解決方案。',
    'We call this approach “shared teams and shared themes,” and we build it into our culture by having open investment committees that can be attended by professionals from all platforms, products, and levels of seniority.': '我們將這種方法稱爲"共享團隊和共享主題"，我們通過擁有開放的投資委員會將其構建到我們的文化中，該委員會可以由來自所有平臺，產品和資歷級別的專業人士參加。',
    'TPG Insights: The AI Revolution': 'TPG洞察: AI革命',

    'Sector': '部門',
    'A market-leading, cross-platform approach to complex and impactful sector': '一種市場領先的跨平臺方法，用於複雜和有影響力的行業',
    'The global healthcare market is highly complex, but it is one of the most compelling and impactful sectors we invest in. The healthcare market is constantly evolving in response to the challenges of the global disease burden as well as market forces such as increasing consumerization of healthcare services. At the same time, the discovery and adoption of new technologies are introducing innovation and opportunity into a system that has historically struggled with inefficiency and opacity.': '全球醫療保健市場非常複雜，但它是我們投資的最具吸引力和影響力的行業之一。 醫療保健市場正在不斷髮展，以應對全球疾病負擔的挑戰以及市場力量，如醫療保健服務的消費化。 與此同時，新技術的發現和採用正在將創新和機會引入一個歷史上一直與低效和不透明度作鬥爭的系統中。',
    'Longstanding leaders in healthcare investing': '醫療保健投資領域的長期領導者',
    'In this complex environment, TPG’s focus remains simple—to build and grow companies that are adding value to the healthcare system and enabling better outcomes for patients. Over our 20-year history, we have partnered with founders and management teams to drive enduring growth and create products and services that have delivered breakthrough innovation, reduced costs, and expanded access to high-quality care.': '在這個複雜的環境中，TPG的重點仍然很簡單 - 建立和發展爲醫療保健系統增加價值併爲患者帶來更好結果的公司。 在我們20年的歷史中，我們與創始人和管理團隊合作，推動持久增長，創造具有突破性創新，降低成本和擴大獲得高質量護理的產品和服務。',
    'TPG’s global healthcare franchise is led by one of the most experienced teams in private equity. The team invests thematically across strategies:': 'TPG的全球醫療保健特許經營權由私募股權領域最有經驗的團隊之一領導。 該團隊在戰略上進行了主題投資',
    
    'Next-Generation and Value-Centric Care Delivery: Leading care delivery models that are expanding access to underserved populations, reducing healthcare costs through shifts in site of care and introducing patient-centric and risk-bearing solutions. Representative investments include Evolent Health, LifeStance Health, Kelsey-Seybold Clinic, Monogram Health, GoHealth, Zipline, and United Family Healthcare.': '下一代和以價值爲中心的護理交付:領先的護理交付模式，正在擴大對服務不足人羣的獲取，通過護理地點的轉變降低醫療保健成本，並引入以患者爲中心和承擔風險的解決方案。 代表性投資包括Evolent Health，LifeStance Health，Kelsey-Seybold Clinic，Monogram Health，GoHealth，Zipline和聯合家庭醫療保健。',
    'Investing Behind Innovation: Pharmaceuticals, diagnostics and devices that represent step function innovations that transform patient outcomes, along with the essential enablers of such innovation. Representative investments include Allogene, AskBio, BVI, Novotech, and Precision for Medicine.': '創新背後的投資: 藥物、診斷和設備代表了改變患者結果的逐步功能創新，以及這種創新的基本推動因素。 代表性投資包括Allogene，AskBio，BVI，Novotech和Precision for Medicine',
    'Technology Enablement: Healthcare technology and related solutions that leverage the power of automation, data & analytics, and interoperability to improve care and patient choice in their own health. Representative investments include Dingdang Health, EnvisionRx, IQVIA, Q-Centrix, and WellSky.': '技術支持: 醫療保健技術和相關解決方案，利用自動化、數據和分析以及互操作性，改善自身健康的護理和患者選擇。 代表性投資包括Dingdang Health，EnvisionRx，IQVIA，Q-Centrix和WellSky。',
    'Investing for Transformation: Proprietary angles created by the TPG Healthcare team’s robust set of strategic and operational capabilities, including our deep industry knowledge and relationships, experience in building out executive teams and strategic roadmaps, and supporting operational resources and capabilities. Representative investments include Adare, Kindred at Home, Kindred Healthcare, Par Pharmaceutical, and Fenwal.': '投資轉型:由TPG醫療保健團隊強大的戰略和運營能力創造的專有角度，包括我們深厚的行業知識和關係，建立高管團隊和戰略路線圖的經驗，以及支持運營資源和能力。 代表性投資包括Adare，Kindred at Home，Kindred Healthcare，Par Pharmaceutical和Fenwal。',
    
    'Leaders in Healthcare Investing': '醫療保健投資的領導者',
    'Learn more about our market-leading healthcare business': '瞭解更多關於我們市場領先的醫療保健業務',
    'Signup for TPG’s LinkedIn Newsletter': '註冊TPG的LinkedIn通訊',
    
    'Flexible growth capital for next-generation market leaders': '爲下一代市場領導者提供靈活的增長資本',
    'Technology is the backbone of our economy. Tech-enabled disruptors continue to change the way we live and work, while businesses across sectors are facing the need for digital transformation in order to compete and serve the evolving needs of their customers. TPG has been investing in Software & Enterprise Technology for over 20 years, building and supporting companies that transform the way we think about productivity and innovation within organizations.': '技術是我們經濟的支柱。 技術驅動的顛覆者繼續改變我們的生活和工作方式，而各個行業的企業都面臨着數字化轉型的需求，以競爭和服務客戶不斷變化的需求。 TPG在軟件和企業技術領域投資超過20年，建立和支持公司，改變我們對組織內部生產力和創新的思考方式。',
    
    'Investing across the technology ecosystem': '投資整個技術生態系統',
    'TPG’s Software and Enterprise Technology team brings deep sector expertise and operating capabilities to companies across all stages of growth. We have worked with disruptors to build their playbooks, and we know how to transform today’s platforms into next-generation leaders. Our approach prioritizes active and engaged partnership, working with management teams to make their companies stronger and more strategic than they were before our investment.': 'TPG的軟件和企業技術團隊爲各個增長階段的公司帶來了深厚的行業專業知識和運營能力。 我們與顛覆者合作構建他們的遊戲手冊，我們知道如何將今天的平臺轉變爲下一代領導者。 我們的方法優先考慮積極和參與的合作伙伴關係，與管理團隊合作，使他們的公司比我們投資之前更強大，更具戰略意義。',
    'TPG has partnered with dozens of game-changing companies that are creating new solutions and driving efficiencies in their markets. We invest in Software & Enterprise Technology through multiple strategies across the firm:': 'TPG已經與數十家改變遊戲規則的公司合作，這些公司正在創造新的解決方案，並提高市場的效率。 我們通過整個公司的多種戰略投資軟件和企業技術',
    'TPG Capital has made transformative investments to build market leaders including Boomi, CCC Information Services, McAfee, Nintex, Planview, ThycoticCentrify, and Transporeon': 'TPG Capital進行了變革性投資，以建立包括Boomi，CCC信息服務，McAfee，Nintex，Planview，ThycoticCentrify和Transporeon在內的市場領導者',
    'TPG Growth has invested in next-generation businesses including Box, C3.ai, Greenhouse, MX, Onfido,Tanium, and Zscaler': 'TPG Growth已經投資了下一代業務，包括Box，C3.ai ，Greenhouse，MX，Onfido，Tanium和Zscaler',
    'TPG Technology Adjacencies (TTAD) has invested in innovative software platforms including EIS, FreedomPay, project44, Sauce Labs, Toast, and Zenoti': 'TPG Technology Adjacencies (TTAD) 投資於創新軟件平臺，包括 EIS、FreedomPay、Project44、Sauce Labs、Toast 和 Zenoti。',
    'TPG Digital Media (TDM) partners with businesses including Goal.com and Stack Commerce': 'TPG數字媒體（TDM）與包括Goal.com 和Stack Commerce在內的企業合作',
    'The Rise Fund partners with businesses that use software to enable and amplify social impact, including DreamBox, EverFi, Greenhouse and Renaissance Learning': 'Rise Fund與使用軟件實現和擴大社會影響的企業合作，包括DreamBox，EverFi，Greenhouse和Renaissance Learning。',
    
    'Read TPG’s Software & Enterprise Technology Year-End Recap': '閱讀TPG的軟件和企業技術年終回顧',
    
    'Building companies that are transforming the global economy': '建設正在改變全球經濟的公司',
    'TPG was founded in 1992, with our first investment operations centered in the San Francisco Bay area. Our firm developed alongside innovative and disruptive companies that are transforming the world’s economy. This history gives us a unique insight and access into the rapidly changing internet, digital media & communications (IDMC) space, and our deep experience in this sector enables us to identify promising companies, entrepreneurs, and ideas and help them grow.': 'TPG成立於1992年，我們的第一個投資業務集中在舊金山灣區。 我們的公司與正在改變世界經濟的創新和顛覆性公司一起發展。 這段歷史爲我們提供了對快速變化的互聯網，數字媒體和通信（IDMC）領域的獨特見解和訪問，我們在該領域的豐富經驗使我們能夠識別有前途的公司，企業家和想法，並幫助它們成長。',
    
    'Unlocking potential': '解鎖潛力',
    'We invest in market-leading brands with differentiated business models that reflect evolving consumer preferences and have the ability to create network effects. By offering institutional support and global resources, we enable disruptive companies to reach their full potential and unlock greater possibilities.': '我們投資於具有差異化商業模式的市場領先品牌，這些模式反映了不斷變化的消費者偏好，並有能力創造網絡效應。 通過提供機構支持和全球資源，我們使顛覆性公司能夠充分發揮其潛力並釋放更大的可能性。',
    'TPG has invested in some of the most influential companies in the IDMC space:': 'TPG投資了一些IDMC領域最具影響力的公司',
    
    'TPG Capital has partnered with established leaders including Astound Broadband, CAA, and Entertainment Partners': 'TPG Capital已經與包括Astound Broadband，CAA和娛樂合作伙伴在內的知名領導者合作',
    'TPG Capital Asia has invested in regional leaders including Jio, PropertyGuru, Kakao Mobility, and Wharf T&T': 'TPG Capital Asia投資了Jio，PropertyGuru，Kakao Mobility和Wharf T&T等地區領導者',
    'TPG Growth was an early investor in global disruptors including Airbnb, Calm, Spotify, and Uber': 'TPG Growth是包括Airbnb、Calm、Spotify和Uber在內的全球顛覆者的早期投資者。',
    'TPG Digital Media has invested in growing platforms including Fandom, Goal.com, and StackCommerce': 'TPG Digital Media投資了包括Fandom，Goal.com 和StackCommerce在內的成長平臺',
    'TTAD has invested in companies enabling the flexible economy and online learning, including Kajabi and Age of Learning': 'TTAD投資了實現靈活經濟和在線學習的公司，包括Kajabi和Age of Learning',
    'The Rise Fund has invested in growth-stage, mission-driven companies including Acorns, Benevity, and Human Interest': 'The Rise Fund投資了成長階段的任務驅動型公司，包括Acorns，Benevity和Human Interest',
    
    'Read more about TPG’s investment in DirecTV': '閱讀更多關於TPG在Direc的投資電視',
    
    'Growth-focused partnerships with unique consumer companies': '與獨特的消費公司建立以增長爲重點的合作伙伴關係',
    'Over our 30-year history, TPG has helped build some of the world’s most iconic consumer brands. Today, TPG’s approach to the consumer sector is focused on finding companies that stand out, with a differentiated position within their category, exceptional consumer loyalty, and significant potential for long-term growth.': '在我們30年的歷史中，TPG幫助建立了一些世界上最具標誌性的消費品牌。 如今，TPG對消費領域的方法專注於尋找那些脫穎而出的公司，這些公司在其類別中具有差異化的地位，卓越的消費者忠誠度和巨大的長期增長潛力。',
    'A targeted portfolio in high-growth consumer categories': '高增長消費者類別中的目標投資組合',
    'We look for distinctive companies in secularly well-positioned categories with ongoing growth opportunities that our capital, network, and resources can help unlock. Our portfolio spans beauty and personal care, food and beverage, services, travel and leisure, e-commerce, dining, education, and fitness and wellness.': '我們尋找處於特殊位置的類別中的獨特公司，這些公司擁有持續的增長機會，我們的資本，網絡和資源可以幫助解鎖。 我們的產品組合涵蓋美容和個人護理，食品和飲料，服務，旅遊和休閒，電子商務，餐飲，教育以及健身和健康。',
    'TPG has a long history of investing in the consumer sector across multiple strategies:': 'TPG在多種戰略中投資消費領域有着悠久的歷史',
    'TPG Capital has invested in leading global consumer companies including Chobani, Gelson’s, Life Time, Norwegian Cruise Lines, Petco, Troon, and Viking Cruises': "TPG Capital投資了全球領先的消費公司，包括Chobani，Gelson's，Life Time，挪威郵輪，Petco，Troon和Viking Cruises。",
    'TPG Capital Asia has invested in Hong Kong-based jewelry brand APM Monaco and Australian pet care company Greencross': 'TPG Capital Asia已投資香港珠寶品牌APM摩納哥和澳大利亞寵物護理公司Greencross',
    'TPG Growth has invested to help scale well-known brands including Beautycounter, Crunch Fitness, e.l.f Cosmetics, ipsy, and SmartSweets': 'PG Growth已投資幫助擴大知名品牌，包括Beautycounter，Crunch Fitness，e.l.f Cosmetics，ipsy和SmartSweets',
    
    'Learn more about TPG’s recent investment in Troon': '瞭解更多關於TPG最近在Troon的投資',

    'Backing critical service providers in growing industries': '支持成長型行業的關鍵服務提供商',
    'The business services sector provides a broad opportunity to deploy TPG’s operational capabilities and growth strategies to service providers across a range of industries. As companies seek to reduce fixed costs and outsource non-core services, we see growing opportunities to support and build companies that provide those services, leveraging TPG’s cross-industry expertise and deep sector insights.': '商業服務部門提供了一個廣泛的機會，可以將TPG的運營能力和增長戰略部署到各個行業的服務提供商中。 隨着公司尋求降低固定成本和外包非核心服務，我們看到越來越多的機會來支持和建立提供這些服務的公司，利用TPG的跨行業專業知識和深入的行業見解。',
    'Creating competitive advantage through scale': '通過規模創造競爭優勢',
    'Our investment experience has demonstrated the importance of scale in the businesses services sector. We focus on companies that provide mission-critical services in growing end-markets in which we can create a stronger competitive advantage through increased scale, driven by both augmented organic growth and market consolidation. Within business services, we focus on key subsectors including professional services, services to the financial sector, data and information services, supply chain and logistics, and industrial services, among others. We look for companies in these subsectors with competitive, resilient business models that serve growing end-markets.': '我們的投資經驗證明了規模在商業服務業中的重要性。 我們專注於在不斷增長的終端市場提供關鍵任務服務的公司，在這些市場中，我們可以在有機增長和市場整合的推動下，通過擴大規模創造更強大的競爭優勢。 在商業服務領域，我們專注於關鍵子行業，包括專業服務，金融部門服務，數據和信息服務，供應鏈和物流以及工業服務等。 我們在這些細分行業尋找具有競爭力，有彈性的商業模式的公司，爲不斷增長的終端市場提供服務。',
    'TPG’s approach to the business services sector spans several strategies:': 'TPG的商業服務部門的方法跨越幾個戰略',
    'TPG Capital has partnered with services companies that are category-defining and leaders in their respective industries, including Transplace, Cushman & Wakefield, Troon, CAA, Entertainment Partners, CCC, The Warranty Group, LPL Financial, and Fidelity National Information Services': 'TPG Capital與各自行業的類別定義和領導者服務公司合作，包括Transplace，Cushman&Wakefield，Troon，CAA，娛樂合作伙伴，CCC，The Warranty Group，LPL Financial和Fidelity National Information Services',
    'TPG Growth has invested in innovative growth-stage companies including CLEAResult, People 2.0, Resource Label Group, Keter Environmental Services, Denali Water Solutions, and Tenth Revolution Group': 'TPG Growth投資了包括CLEAResult、People 2.0、Resource Label Group、Keter Environmentmenal Services、Denali Water Solutions和Tenth Revolution Group在內的創新成長階段公司',
    
    'Learn more about TPG’s investment in Transplace': '瞭解更多關於TPG在Transplace的投資',

    'Platform': '平臺',
    'Large-scale global private equity investing platform with a sector-focused, growth-oriented strategy': '以行業爲中心，以增長爲導向的大規模全球私募股權投資平臺',
    
    'Long-term, transformational investing': '長期轉型投資',
    'TPG Capital was the first business formed within TPG when the firm was founded in 1992. Today, our Capital platform primarily makes large-scale, control-oriented private equity investments. We pursue opportunities across geographies and specialize in sectors where we have developed unique expertise over time.': 'TPG Capital是TPG於1992年成立時成立的第一家業務。 今天，我們的Capital平臺主要進行大規模的，以控制爲導向的私募股權投資。 我們追求跨越地域的機會，並專注於隨着時間的推移，我們已經開發出獨特專業知識的行業',
    '$71B': '$710億',
    'In assets under management': '在管理的資產中',
    '1992': '1992',
    'Year Founded': '成立年份',
    '$79B': '$790億',
    'Invested Since Inception': '自成立以來投資',

    'TPG Capital has nearly three decades of experience partnering with leading companies around the world. We bring a disciplined, highly thematic approach to investing that allows us to uncover compelling investment opportunities and drive transformational growth at scale.': 'TPG Capital擁有與全球領先公司合作的近30年經驗。 我們帶來了紀律嚴明、高度主題化的投資方法，使我們能夠發現引人注目的投資機會，並推動大規模轉型增長。',
    '$42.2B': '$422億',
    'TPG Asia': 'TPG Asia',
    'TPG was one of the first alternative asset management firms to establish a dedicated Asia franchise, opening an office in Shanghai in 1994. Today, the Asia Pacific region is the world’s growth engine, and TPG Capital Asia is leveraging our deep expertise and strong regional presence to help build the emerging leaders in this dynamic market, with a particular focus on areas where technology can drive disruptive growth.': 'TPG是首批建立亞洲特許經營權的另類資產管理公司之一，於1994年在上海開設辦事處。 如今，亞太地區是全球增長引擎，TPG Capital Asia正在利用我們深厚的專業知識和強大的區域影響力，幫助在這個充滿活力的市場中建立新興領導者，特別關注技術可以推動顛覆性增長的領域。',
    '$21.8B': '$218億',
    'We launched TPG Healthcare Partners (THP) in 2018 as an extension of our market-leading healthcare franchise in response to significant growth across the US and European healthcare market. We focus on driving growth and expanding capabilities in companies that offer innovative, value-based solutions to current challenges in the healthcare system.': '我們在2018年推出了TPG醫療保健合作伙伴（THP），作爲我們市場領先的醫療保健特許經營權的延伸，以應對美國和歐洲醫療保健市場的顯着增長。 我們專注於推動增長和擴大公司的能力，這些公司爲醫療保健系統當前的挑戰提供創新的，基於價值的解決方案。',
    '$7.0B': '$70億',
    
    'Our other platforms': '我們的其他平臺',

    'Growth-oriented, thematic investing at scale': '以增長爲導向，大規模主題投資',
    'Deep sector expertise, differentiated deal types, and value creation through operational improvement': '深厚的行業專業知識，差異化的交易類型，並通過運營改進創造價值',
    'We spend significant time researching and building relationships in our core sectors in order to develop conviction and differentiated insight into the themes and opportunities we pursue. Our patient, long-term orientation and flexible approach enables us to establish creative partnerships that result in stronger and more growth-oriented companies.': '我們花大量時間研究和建立我們核心部門的關係，以便對我們追求的主題和機會產生信念和差異化見解。 我們耐心、長期導向和靈活的方法使我們能夠建立創造性的合作伙伴關係，從而建立更強大、更以增長爲導向的公司。',
    'TPG Capital has invested more than $67 billion since 1993 across the economy’s most important sectors: Healthcare, with businesses including Monogram Health, LifeStance Health, and WellSky; Software & Enterprise Technology, with investments including McAfee, Boomi and Wind River; IDMC, with companies such as CAA and DirecTV; Consumer, with companies such as Troon; and Business Services, with investments such as Transplace.': '自1993年以來，TPG Capital已在經濟最重要的領域投資超過670億美元: 醫療保健，業務包括Monogram Health，LifeStance Health和WellSky;軟件和企業技術，投資包括McAfee，Boomi和Wind River;IDMC，與CAA和Direc等公司合作電視;消費者，與Troon等公司合作;和商業服務，與Transplace等投資',
   
    'TPG Capital Leadership: Why Focus is Key to Effective Investing': 'TPG資本領導力: 爲什麼專注是有效投資的關鍵',

    'Select Investments': '選擇投資',

    'Leveraging broad and deep regional expertise to build market leaders in an epicenter of global growth': '利用廣泛而深入的區域專業知識，在全球增長的中心建立市場領導者',

    'Geographically distributed, thematically focused teams that drive value through partnership': '地理分佈、以主題爲中心的團隊，通過合作伙伴關係推動價值',
    'TPG’s strong track record in Asia has established us as a value-creating partner with a strong culture of integrity. Our team is distributed across Australia, China, India, Korea, and Southeast Asia, where we build regional platforms by investing through a variety of transaction structures, including partnerships with governments, families, and corporations.': 'TPG在亞洲的良好記錄使我們成爲具有強大誠信文化的價值創造合作伙伴。 我們的團隊分佈在澳大利亞，中國，印度，韓國和東南亞，我們通過各種交易結構進行投資，包括與政府，家庭和公司的合作伙伴關係來構建區域平臺。',
    'Connectivity with TPG’s global investment ecosystem drives our thematic growth-oriented investment approach in our core sectors, including Consumer, Healthcare, Financial Services, and TMT/New Economy. Since its inception, TPG Asia has invested more than $13 billion and realized more than $16 billion across our core regions and sectors.': '與TPG全球投資生態系統的連接推動了我們在覈心領域（包括消費者，醫療保健，金融服務，TMT /新經濟）的主題性增長型投資方法。 自成立以來，TPG Asia已投資超過130億美元，並在我們的核心地區和行業實現了超過160億美元。',
    
    'Read more about TPG Capital Asia’s investment in Fractal AI': '閱讀更多關於TPG Capital Asia對Fractal AI的投資',
    
    'Leaders in dedicated, large-scale healthcare private equity investing': '致力於大規模醫療保健私募股權投資的領導者',
    'TPG Healthcare Partners (THP) was launched in 2018 as an extension of our market-leading healthcare franchise, based on our strong conviction in the opportunity for outsized investment returns in large-scale healthcare buyouts, combined with the lack of scaled private equity funds dedicated to healthcare. We believe the TPG Capital Healthcare team is uniquely positioned to deploy capital in interesting healthcare companies that will build upon the team’s successful track record of investing in healthcare over the last few decades.': 'TPG Healthcare Partners（THP）成立於2018年，是我們市場領先的醫療保健特許經營權的延伸，基於我們對大規模醫療保健收購獲得巨大投資回報的強烈信念，再加上缺乏專門用於醫療保健的私募股權基金。 我們相信，TPG Capital Healthcare團隊具有獨特的優勢，可以在有趣的醫療保健公司中部署資本，這些公司將在團隊過去幾十年投資醫療保健的成功記錄的基礎上發展。',
    
    'At $4.5 billion, THP is one of the largest pools of capital dedicated to large-cap healthcare investing': 'THP爲45億美元，是致力於大型醫療保健投資的最大資本池之一',
    'THP strives to invest in companies that offer innovative solutions to current challenges in the healthcare system, with a playbook that emphasizes heavy organic investment in R&D, sales and marketing, and accretive business development that can drive substantial, outsized growth.': 'THP努力投資於那些爲醫療保健系統當前挑戰提供創新解決方案的公司，其劇本強調在研發，銷售和營銷方面的大量有機投資，以及可以推動大幅超大規模增長的業務發展。',
    'THP investments include next-generation care delivery platforms including LifeStance, Kelsey-Seybold, and Monogram Health; innovative companies transforming patient outcomes such as AskBio; and other market-leading healthcare technology and services companies including WellSky, Convey, and BGB Group.': 'THP投資包括下一代護理交付平臺，包括LifeStance，Kelsey-Seybold和Monogram Health;創新公司，如AskBio;以及其他市場領先的醫療保健技術和服務公司，包括WellSky，Convey和BGB Group。',
    'Learn more about TPG Capital’s healthcare investing practice': '瞭解更多關於TPG Capital的醫療保健投資實踐',

    'Growth investing with a West Coast perspective and a focus on high-growth sectors': '從西海岸的角度和關注高增長部門的增長投資',
    'Flexible capital to help growing business scale': '靈活的資本，幫助不斷增長的業務規模',
    'TPG’s Growth platform provides us with the ability to invest in companies that are earlier in their lifecycle, are smaller in size, or have profiles that are different from what we’d consider for our Capital platform. Our family of growth funds has developed organically as new areas of opportunity emerge, particularly in technology and media.': 'TPG的增長平臺使我們能夠投資於生命週期較早的公司，規模較小，或者具有與我們對Capital平臺所考慮的不同配置文件。 我們的增長基金家族隨着新的機會領域的出現而有機地發展，特別是在技術和媒體領域。',
    '$27B': '$270億',
    '2007': '2007',
    '$14B': '$140億',
    
    'Growth equity is one of the fastest growing private equity strategies in the market today. TPG was early to recognize this opportunity, launching TPG Growth in 2007 to meet the unique needs of earlier-stage companies, from traditional minority growth investing to growth buyouts and specialty capital. TPG’s West Coast roots, value-added operating approach, global reach, and sector depth give us a distinct advantage in growth investing and position us as a valued partner for entrepreneurs.': '成長股是當今市場上增長最快的私募股權策略之一。 TPG很早就意識到了這個機會，於2007年推出了TPG Growth，以滿足早期公司的獨特需求，從傳統的少數增長投資到增長收購和專業資本。 TPG的西海岸根基、增值運營方法、全球覆蓋面和行業深度，使我們在增長投資方面具有明顯的優勢，並將我們定位爲企業家的重要合作伙伴。',
    '$17.8B': '$178億',
    
    'Established in 2017, TPG Digital Media (TDM) is a flexible source of capital created specifically to pursue investment opportunities in digital media. TDM leverages TPG’s long history of studying and pursuing themes around the creation and distribution of content to build market-leading businesses in attractive verticals within the digital media landscape.': 'TPG Digital Media（TDM）成立於2017年，是專爲追求數字媒體投資機會而創建的靈活資金來源。 TDM利用TPG長期以來研究和追求內容創建和分發主題的悠久歷史，在數字媒體領域建立具有吸引力的垂直領域的市場領先企業。',
    '$1.8B': '$18億',

    'TPG Technology Adjacencies': 'TPG 技術相鄰項',
    'TPG Technology Adjacencies (TTAD) developed organically out of our technology investing practice in 2018. We saw significant value being created in the private markets as tech companies increasingly opted to stay private through more advanced stages of growth. This is a new era in the technology market that requires flexible capital with a full-suite, full-cycle approach to investing, and we created TTAD specifically for this opportunity.': 'TPG Technology Adjacencies（TTAD）在2018年根據我們的技術投資實踐有機發展。 我們看到私人市場創造了巨大的價值，因爲科技公司越來越多地選擇通過更高級的增長階段保持私有化。 這是一個技術市場的新時代，需要靈活的資本，採用全套件，全週期的投資方法，我們專門爲這個機會創建了TTAD。',
    '$6.9B': '$69億',
    'As the pace of research, development, and innovation in the life sciences sector accelerates, TPG is uniquely positioned to be a capital provider of choice to emerging leaders. TPG Life Sciences Innovations (LSI) is a new strategy that will invest in companies that are developing new therapeutic modalities, biologics, and small molecules, as well as later-stage opportunities in digital health, medical devices, diagnostics, and innovation services.': '隨着生命科學領域研究、開發和創新的步伐加快，TPG處於獨特的地位，成爲新興領導者選擇的資本提供商。 TPG生命科學創新（LSI）是一項新戰略，將投資於正在開發新治療模式，生物製劑和小分子的公司，以及數字健康，醫療設備，診斷和創新服務的後期機會。',
    '$2.5B': '$25億',
    'in historical TPG life sciences investments': '在歷史TPG生命科學投資',
    
    'Established leaders in growth investing with a distinct position in the market': '在增長型投資方面建立了領先地位，在市場上具有獨特的地位',
    'Growth investing is one of the fastest growing private equity strategies in the market today. TPG was early to recognize this opportunity, launching TPG Growth in 2007 to meet the unique needs of earlier-stage companies, from traditional minority growth investing to growth buyouts and specialty capital. TPG’s West Coast roots, value-added operating approach, global reach, and sector depth give us a distinct advantage in growth investing and position us as a valued partner for entrepreneurs.': '增長投資是當今市場上增長最快的私募股權策略之一。 TPG很早就意識到了這個機會，於2007年推出了TPG Growth，以滿足早期公司的獨特需求，從傳統的少數增長投資到增長收購和專業資本。 TPG的西海岸根基、增值運營方法、全球覆蓋面和行業深度，使我們在增長投資方面具有明顯的優勢，並將我們定位爲企業家的重要合作伙伴。',
    'Leveraging the power of our ecosystem': '利用我們生態系統的力量',
    'TPG Growth brings the strength of the firm’s worldwide ecosystem to growth-stage companies. Our global perspective, differentiated thematic insight, and deep sector specialization enables us to be highly opportunistic yet disciplined investors, transferring learnings from developed to developing markets. TPG’s dedicated operations team delivers business-building engagement, deep industry expertise, and creative problem-solving to help our companies grow and scale.': 'TPG Growth爲成長階段的公司帶來了公司全球生態系統的力量。 我們的全球視野，差異化的主題洞察力和深厚的行業專業化使我們能夠成爲高度機會主義但紀律嚴明的投資者，將發達國家的經驗轉移到發展中市場。 TPG的專門運營團隊提供業務建設參與，深厚的行業專長和創造性解決問題的能力，以幫助我們的公司成長和規模。',
    'Over 15 years, we’ve built a successful track record by pursuing sectors where we have a differentiated investment approach, including Healthcare investments such as Precision Medicine, Vaxcyte, Medical Solutions, and CTSI; Software & Enterprise Technology investments including MX, Tanium, and Zscaler; Business Services companies such as Denali Water Solutions, Halo Branded Solutions, and Tenth Revolution Group; Consumer companies including Beautycounter, Crunch Fitness, and Fender; and IDMC companies including Uber, Spotify, AirBnB, and Prodigy Education.': '15年來，我們通過追求具有差異化投資方法的行業，建立了成功的記錄，包括精準醫療，Vaxcyte，醫療解決方案和CTSI等醫療保健投資;軟件和企業技術投資，包括MX，Tanium。 和Zscaler;商業服務公司，如Denali Water Solutions，Halo Branded Solutions和Tenth Revolution Group;消費者公司，包括Beautycounter，Crunch Fitness和Fender;以及IDMC公司，包括Uber，Spotify，AirBnB和Prodigy Education。',

    
    "Learn more about TPG Growth's partnership with Beautycounter": "瞭解更多關於TPG Growth與Beautycounter的合作關係",
    
    'Specialty capital for opportunities in the rapidly expanding digital media sector': '在快速發展的數字媒體領域尋找機會的專業資本',
    'Established in 2018, TPG Digital Media (TDM) is a flexible source of capital created specifically to pursue investment opportunities in digital media. TDM leverages TPG’s long history of studying and pursuing themes around the creation and distribution of content to build market-leading businesses in attractive verticals within the digital media landscape.': 'TPG Digital Media（TDM）成立於2018年，是專爲追求數字媒體投資機會而創建的靈活資金來源。 TDM利用TPG長期以來研究和追求內容創建和分發主題的悠久歷史，在數字媒體領域建立具有吸引力的垂直領域的市場領先企業。',
    'A comprehensive, transformative approach': '全面的、變革性的方法',
    'TDM is focused on making control equity investments through flexible capital structures. We take a long-term approach to building digital media businesses through comprehensive support for operational and financial transformation.': 'TDM專注於通過靈活的資本結構進行控制股權投資。 我們採取長期方法，通過全面支持運營和財務轉型來建立數字媒體業務。',

    'Since inception, TDM has deployed $377 million in companies including Fandom, Goal.com and Stack Commerce.': '自成立以來，TDM已經在包括Fandom，Goal.com 和Stack Commerce在內的公司部署了3.77億美元。',
    'Read more about TDM’s investment in Stack Commerce': 'Read more about TDM 在堆棧商務方面的投資',
    'Flexible, solutions-oriented capital for the evolving technology industry': '爲不斷髮展的技術行業提供靈活的、面向解決方案的資本',
    'Dedicated capital for the next generation of tech leaders': '爲下一代技術領導者提供專用資本',
    'TTAD leverages TPG’s deep, longstanding experience investing in tech companies across all stages of growth to make minority investments focused on providing primary capital solutions for companies seeking additional capital for growth as well as creative capital for founders, employees, and early investors looking for liquidity.': 'TTAD利用TPG在各個增長階段對科技公司的深厚長期投資經驗，使少數投資專注於爲尋求額外增長資本的公司提供主要資本解決方案，併爲創始人，員工和尋求流動性的早期投資者提供創意資本。',
    'Powered by TPG’s unique positioning in the tech marketplace, TTAD has deployed more than $1.8 billion across two funds in businesses focused internet, software, digital media, and other technology sectors. Our investments include Age of Learning, C3.ai, Course Hero, Dream 11, EIS Group, FreedomPay, Kajabi, Kaseya, Sauce Labs, Toast, and Zenoti.': '憑藉TPG在科技市場的獨特定位，TTAD在兩個基金中部署了超過18億美元的資金，用於專注於互聯網、軟件、數字媒體和其他技術領域的業務。 我們的投資包括Age of Learning, C3.ai , Course Hero, Dream 11, EIS Group, FreedomPay, Kajabi, Kaseya, Sauce Labs，Toast和Zenoti。',
    
    'Learn more about TPG’s Software & Enterprise Technology investing practice': '瞭解更多關於TPG的軟件和企業技術投資實踐',
    
    'Bringing TPG’s scale and expertise to drive Innovation in life sciences': '利用TPG的規模和專業知識推動生命科學創新',
    'TPG’s Life Sciences Innovations (LSI) fund will invest in novel therapeutics as well as digital health, medical devices, diagnostics, and tech-enabled services, leveraging our core team’s and TPG’s broad expertise in healthcare to focus on supporting companies that are meaningfully improving patients’ outcomes. LSI will invest across therapeutic areas and stages from company creation to IPO. We pride ourselves on being great partners, with a differentiated ability to scale our investment based on companies’ needs.': 'TPG的生命科學創新（LSI）基金將投資於新型治療以及數字健康，醫療設備，診斷和技術支持服務，利用我們的核心團隊和TPG在醫療保健方面的廣泛專業知識，專注於支持那些有意義地改善患者結果的公司。 LSI將在從公司創建到IPO的治療領域和階段進行投資。 我們爲自己是偉大的合作伙伴而感到自豪，具有根據公司需求擴大投資的差異化能力。',
    
    'Built on a track record of successfully investing in and supporting cutting-edge life science companies': '建立在成功投資和支持尖端生命科學公司的良好記錄之上',
    'LSI builds on TPG’s history of successful healthcare investing, including $750 million invested in life sciences in the last three years and $7.6 billion invested in healthcare over the last 10 years.': 'LSI建立在TPG成功的醫療保健投資歷史之上，包括過去三年投資7.5億美元用於生命科學，過去10年投資76億美元用於醫療保健。',
    'LSI has deep scientific and clinical domain expertise embedded within a market-leading healthcare investment franchise. The team is able to provide customized expertise from an extended network of TPG advisors to support the growth of our portfolio companies. As the pace of research, development, and innovation in the life sciences sector accelerates, LSI is well positioned to support the growth of young companies, from company creation through pre-clinical and clinical development to commercial stages.': 'LSI擁有深厚的科學和臨牀領域專業知識，嵌入市場領先的醫療保健投資特許經營權中。 該團隊能夠從擴展的TPG顧問網絡中提供定製的專業知識，以支持我們投資組合公司的增長。 隨着生命科學領域研究、開發和創新的步伐加快，LSI已處於有利地位，可以支持年輕公司的成長，從公司創建到臨牀前和臨牀開發再到商業階段。',
    
    'One of the largest private impact investing platforms in the industry with a demonstrated ability to drive competitive returns and meaningful, quantifiable impact': '業內最大的私人影響力投資平臺之一，具有推動競爭性回報和有意義的可量化影響的能力',
    'Impact solutions at scale': '大規模影響解決方案',
    'At TPG, we have a fundamental belief that private enterprise has a critical role to play in addressing global societal challenges. Our Impact platform is committed to driving both competitive financial returns and measurable societal benefits at scale. With the launch of The Rise Fund in 2016, we were the first global alternative asset manager to develop an impact investing business with more than $1 billion in assets under management, and today, we have built what we believe is the largest private equity impact investing platform.': '在TPG，我們堅信私營企業在應對全球社會挑戰方面可以發揮關鍵作用。 我們的Impact平臺致力於推動具有競爭力的財務回報和可衡量的社會效益。 隨着2016年The Rise Fund的推出，我們是第一個管理超過10億美元資產的影響力投資業務的全球另類資產管理公司，今天，我們建立了我們認爲最大的私募股權影響力投資平臺',
    
    '$19B': '$190億',
    '2016': '2016',
    '$3B+': '$30億',
    'The Rise Fund': '上升基金',
    'The Rise Funds were founded in 2016 by TPG in partnership with Bono and Jeff Skoll with the goal of putting commercial capital to work toward helping build profitable businesses that deliver positive and sustainable impact. As part of TPG, The Rise Funds offer investment resources, business-building skills, and a global network to help our portfolio companies accelerate growth and impact. The Rise Funds’ core areas of focus include climate and conservation, education, financial inclusion, food and agriculture, healthcare, and impact services.': 'The Rise Funds由TPG於2016年與Bono和Jeff Skoll合作成立，旨在利用商業資本幫助建立有利可圖的業務，產生積極和可持續的影響。 作爲TPG的一部分，The Rise Funds提供投資資源，業務建設技能和全球網絡，以幫助我們的投資組合公司加速增長和影響。 上升基金的核心重點領域包括氣候和保護，教育，普惠金融，糧食和農業，醫療保健和影響服務。',
    '$8.8B': '$88億',
    'Established in 2021, TPG Rise Climate is our dedicated climate impact investing product. The fund was created to address the challenges of global climate change as well as the opportunities to scale innovative businesses that can enable quantifiable carbon aversion. TPG Rise Climate’s core areas of focus include clean energy, enabling solutions, decarbonized transport, greening industrials and agriculture and natural solutions.': 'TPG Rise Climate成立於2021年，是我們專門的氣候影響投資產品。 該基金旨在應對全球氣候變化的挑戰以及擴大創新業務的機會，以實現可量化的碳厭惡。 TPG Rise Climate的核心關注領域包括清潔能源，使能解決方案，脫碳運輸，綠色工業和農業和自然解決方案。',
    '$9.5B': '$95億',
    'Evercare is an emerging markets healthcare fund dedicated to providing affordable, high-quality care. The fund’s portfolio of 30 hospitals, 16 clinics, 82 diagnostic centers, and two brownfield assets are run through a common operating platform, The Evercare Group, providing an integrated healthcare delivery platform in emerging markets across Africa and South Asia, including India, Pakistan, Bangladesh, Kenya and Nigeria.': 'Evercare是一家致力於提供負擔得起的高質量護理的新興市場醫療保健基金。 該基金由30家醫院，16家診所，82家診斷中心和兩家棕地資產組成，通過一個共同的運營平臺The Evercare Group在非洲和南亞新興市場（包括印度，巴基斯坦，孟加拉國，肯尼亞和尼日利亞）提供綜合醫療保健交付平臺。',
    '$395M': '3.95億美元',
    'The Evercare Group': 'Evercare集團',
    
    'Global credit and real estate investing platform backed by fundamental research and a commitment to capital protection': '以基礎研究和資本保護承諾爲後盾的全球信貸和房地產投資平臺',
    'Investing in inefficient markets to generate consistent returns': '投資低效的市場以產生一致的回報',
    'Angelo Gordon was acquired by TPG in 2023, becoming a new diversified credit and real estate investing platform within the firm. With nearly 35 years of experience investing across a broad range of credit and real estate strategies, TPG Angelo Gordon seeks alternative investment opportunities that will deliver consistent absolute returns for clients. Our investment approach relies on disciplined portfolio construction backed by rigorous research and a strong focus on capital preservation.': 'Angelo Gordon於2023年被TPG收購，成爲公司內部一個新的多元化信貸和房地產投資平臺。 TPG Angelo Gordon在廣泛的信貸和房地產策略方面擁有近35年的投資經驗，尋求爲客戶提供一致的絕對回報的替代投資機會。 我們的投資方法依賴於紀律嚴明的投資組合建設，並輔之以嚴格的研究和對資本保全的強烈關注。',
    '$80B': '80億美元',
    'In Assets Under Management*': '在管理的資產中*',
    '1988': '1988',
    '* TPG Angelo Gordon’s currently stated AUM of approximately $80 billion as of March 31, 2024 reflects fund-level asset-related leverage. Prior to May 15, 2023, TPG Angelo Gordon calculated its AUM as net assets under management excluding leverage, which resulted in TPG Angelo Gordon AUM of approximately $53 billion last reported as of December 31, 2022. The difference reflects a change in TPG Angelo Gordon’s AUM calculation methodology and not any material change to TPG Angelo Gordon’s investment advisory business. For a description of the factors TPG Angelo Gordon considers when calculating AUM, please see the disclosure linked here.': '* TPG Angelo Gordon目前表示，截至2024年3月31日，AUM約爲800億美元，反映了基金級資產相關槓桿。 在2023年5月15日之前，TPG Angelo Gordon將其AUM計算爲管理下的淨資產，不包括槓桿，導致TPG Angelo Gordon AUM截至2022年12月31日上次報告約爲530億美元。 這一差異反映了TPG Angelo Gordon的AUM計算方法的變化，而不是TPG Angelo Gordon的投資諮詢業務的任何重大變化。 有關TPG Angelo Gordon在計算AUM時考慮的因素的說明，請參閱此處鏈接的披露',
    'TPG Angelo Gordon Credit Solutions': 'TPG安傑洛戈登信貸解決方案',
    "TPG Angelo Gordon's Credit Solutions business has a differentiated, all-weather, solutions-based approach that can be executed in any market environment.": "TPG Angelo Gordon的信用解決方案業務具有差異化，全天候，基於解決方案的方法，可以在任何市場環境中執行。",
    '$13.4B': '134億美元',

    'TPG Angelo Gordon CLOs': 'TPG安傑洛戈登CLOs',
    'TPG Angelo Gordon’s CLO team invests in predominantly non-investment grade credit instruments.': 'TPG Angelo Gordon的CLO團隊主要投資於非投資級信用工具。',
    '$8.5B': '85億美元',
    'TPG Angelo Gordon Structured Credit & Specialty Finance': 'TPG安傑洛戈登結構化信貸和專業金融',
    'Our global structured credit and specialty finance capabilities allow us to offer a broad-based, fully integrated platform covering public and private opportunities across a wide range of securitized and other asset-based credit.': '我們的全球結構性信貸和專業融資能力使我們能夠提供一個基礎廣泛、完全集成的平臺，涵蓋廣泛的證券化和其他基於資產的信貸中的公共和私人機會。',
    '$16.0B': '160億美元',
    'TPG Angelo Gordon Middle Market Direct Lending': 'TPG Angelo Gordon中間市場直接貸款',
    'TPG Angelo Gordon’s middle market direct lending team builds on the firm’s long-term history in deep credit underwriting.': 'TPG Angelo Gordon的中間市場直接貸款團隊建立在公司在深度信貸承銷方面的長期歷史之上。',
    '$21.3B': '213億美元',
    'TPG Angelo Gordon Multi-Strategy': 'TPG安傑洛戈登多戰略',
    'TPG Angelo Gordon’s multi-strategy investment platform channels our extensive analytic capabilities to invest flexibly throughout market cycles.': 'TPG Angelo Gordon的多戰略投資平臺引導我們廣泛的分析能力，在整個市場週期中靈活投資。',
    '$2.1B': '21億美元',
    'TPG Angelo Gordon Global Real Estate': 'TPG安傑洛戈登全球房地產',
    'TPG Angelo Gordon Global Real Estate acquires underperforming assets in the U.S, Europe, and Asia, where we can employ our value-add skills to improve property performance.': 'TPG Angelo Gordon Global Real Estate收購了美國、歐洲和亞洲表現不佳的資產，在那裏我們可以利用我們的增值技能來提高房地產表現。',
    '$16.4B': '164億美元',
    'TPG Angelo Gordon Net Lease Real Estate': 'TPG安傑洛戈登淨租賃房地產',
    'TPG Angelo Gordon’s Net Lease business provides real estate sale-leaseback financing to less-than-investment grade owner-occupiers of corporate real estate.': 'TPG Angelo Gordon的淨租賃業務爲低於投資級的企業房地產自住者提供房地產銷售 - 租賃融資。',
    '$2.2B': '22億美元',
    
    'A differentiated, all-weather, solutions-based approach that can be executed in any market environment': '一種差異化、全天候、基於解決方案的方法，可以在任何市場環境中執行',
    'We are not reliant on bankruptcies, defaults, restructuring processes, or deep distress to create investment opportunities. Instead, we proactively seek to align ourselves with company management teams, business owners, and private equity sponsors, and we use our structuring creativity and substantial capital base to design bespoke financing transactions. In doing this, we look to strategically drive win-win outcomes by partnering with companies to help resolve complex, idiosyncratic situations.': '我們不依賴破產、違約、重組過程或深度困境來創造投資機會。 相反，我們積極尋求與公司管理團隊，企業主和私募股權贊助商保持一致，並利用我們的結構創造力和雄厚的資本基礎來設計定製的融資交易。 在此過程中，我們希望通過與公司合作，從戰略上推動雙贏的結果，以幫助解決複雜，特殊的情況。',
    'We pursue transactions primarily in North America and Europe, and we have the flexibility to dynamically pivot between the public and private markets to capitalize on what we believe are the most attractive investment opportunities.': '我們主要在北美和歐洲進行交易，並且我們有靈活性在公共和私人市場之間動態轉向，以利用我們認爲最具吸引力的投資機會。',
    'With 20+ professionals located across New York and Europe, our investment team leverages their substantial industry expertise along with the full resources of Angelo Gordon’s approximately $61 billion credit platform and the firm’s 30+ years of credit investing experience.': '我們在紐約和歐洲擁有20多名專業人士，我們的投資團隊利用他們豐富的行業專業知識，以及Angelo Gordon約610億美元的信貸平臺的全部資源和該公司30多年的信貸投資經驗。',
    'Investing predominantly in non-investment grade credit instruments': '主要投資於非投資級信用工具',

    'The team aims to execute an absolute, total return investment strategy that seeks to generate returns from both current income and capital appreciation.': '該團隊旨在執行絕對的總回報投資策略，旨在從當前收入和資本增值中產生回報。',
    'With a primary focus on senior secured floating rate loans, the strategy may also opportunistically invest in mezzanine debt, high yield bonds, distressed securities, and other credit related instruments including indexes and/or derivatives. The team actively monitors and trades the portfolio with the goal of improving credit quality and generating incremental return.': '由於主要關注高級有擔保浮動利率貸款，該戰略也可能機會性地投資於夾層債務，高收益債券，不良證券和其他信貸相關工具，包括指數和/或衍生品。 該團隊積極監控和交易投資組合，以提高信用質量併產生增量回報。',
    'The team has a twenty-year history of managing CLOs via its Northwoods Capital platform and invests through these structured vehicles, co-mingled funds and managed accounts. The strategy has a long bias and utilizes fundamental credit analysis to identify attractive investment opportunities, often finding value in overlooked or misunderstood securities. The team is currently focused on the North American market but also invests outside of North America.': '該團隊通過其Northwoods Capital平臺管理CLO已有20年的歷史，並通過這些結構化工具，混合基金和管理賬戶進行投資。 該策略具有長期的偏見，並利用基本信用分析來識別有吸引力的投資機會，往往在被忽視或誤解的證券中找到價值。 該團隊目前專注於北美市場，但也投資於北美以外的地區。',
    
    'Broad-based, fully integrated strategy covering public and private opportunities across a wide range of securitized and other asset-based credit': '基於廣泛、完全集成的戰略，涵蓋廣泛的證券化和其他基於資產的信貸中的公共和私人機會',
    'Our fundamental research approach, combined with our long-standing presence across numerous sub-asset classes, has resulted in what we believe to be an all-weather business that can identify consistent and attractive risk-adjusted return opportunities through various economic scenarios and market cycles.': '我們的基本研究方法，加上我們在衆多子資產類別的長期存在，已經導致我們認爲是全天候業務，可以通過各種經濟場景和市場週期確定一致和有吸引力的風險調整回報機會。',
    'The strategy has expanded since its inception in 2006 and can take advantage of opportunities across the liquidity spectrum in numerous asset types spanning consumer, residential and commercial real estate and specialty lending markets. Our strategies invest in both securities as well as privately negotiated structured solutions, whole loan purchases, special situations, and direct origination. The platform also includes a residential whole loan business, which is consistently a market leader in the securitization space. This proprietary product provides access to loan level information and market insight that augments constant analysis of empirical market data. ': '自2006年成立以來，該戰略已經擴大，並可以利用多種資產類型的機會，包括消費，住宅和商業房地產和專業貸款市場。 我們的戰略投資於證券以及私人談判的結構化解決方案，整個貸款購買，特殊情況以及直接起源。 該平臺還包括住宅整體貸款業務，該業務一直是證券化領域的市場領導者。 這種專有產品提供貸款水平的信息和市場洞察力，增強了對經驗市場數據的持續分析。',
    'Our proprietary investment process is driven by three pillars, which have been developed and cultivated by the investment team: Market Access and Intelligence, Fundamental Credit Analysis, and Legal/Structural and Cash Flow Underwriting. Our investment professionals have navigated various market cycles and bring a wide range of experience across all major segments of the securitized and other asset-based credit markets. ': '我們的自有投資流程由投資團隊開發和培養的三大支柱驅動: 市場準入和情報，基本信用分析，法律/結構和現金流承銷。 我們的投資專業人員已經駕馭了各種市場週期，並在證券化和其他基於資產的信貸市場的所有主要領域帶來了廣泛的經驗。',
    
    'Building on the team’s long history in deep credit underwriting': '建立在團隊在深度信用承保方面的悠久歷史之上',
    'Through our middle market direct lending business, TPG Twin Brook Capital Partners, we provide cash-flow based financing solutions for the middle market private equity community in North America.': '通過我們的中間市場直接貸款業務TPG Twin Brook Capital Partners，我們爲北美中間市場私募股權社區提供基於現金流的融資解決方案。',
    'TPG Angelo Gordon’s flexible product suite allows for tailored financing solutions for leveraged buyouts, recapitalizations, add-on acquisitions, growth capital, and other situations for companies that typically have EBITDA between $3 million and $50 million, with a focus on companies with less than $25 million of EBITDA. The group focuses on senior secured loans with a concentration in first liens with a loan-to-value target of 40% to 55%, although opportunistic investments may include second liens, asset-backed securities, unitranche loans, and mezzanine loans, likely in conjunction with providing more senior financing in the same transaction.': 'TPG Angelo Gordon靈活的產品套件允許爲槓桿收購，資本重組，附加收購，增長資本和其他情況量身定製的融資解決方案，適用於EBITDA通常在300萬至5000萬美元之間的公司，重點是EBITDA低於2500萬美元的公司。 該集團專注於優先留置權的高級擔保貸款，貸款價值目標爲40%至55%，儘管機會主義投資可能包括第二留置權，資產支持證券，單位貸款和夾層貸款，可能與在同一交易中提供更高級的融資有關。',
    
    'Channeling our extensive analytic capabilities to invest flexibly throughout market cycles': '引導我們廣泛的分析能力，在整個市場週期中靈活投資',
    "The platform utilizes TPG Angelo Gordon's significant scale and reach across multiple asset classes and jurisdictions and draws on the highly specialized expertise of our single-strategy credit and arbitrage investment teams to deliver broad sourcing and execution capabilities.  ": "該平臺利用TPG Angelo Gordon在多個資產類別和管轄區的巨大規模和覆蓋範圍，並利用我們單一戰略信貸和套利投資團隊的高度專業知識提供廣泛的採購和執行能力。",
    "Our multi-strategy commingled funds and separate accounts are overseen by a dedicated team and are fundamentally driven by TPG Angelo Gordon's powerful research engine and deeply collaborative investment team culture.": "我們的多策略混合基金和單獨賬戶由一個專門的團隊監督，從根本上由TPG Angelo Gordon強大的研究引擎和深度合作的投資團隊文化驅動。",
    'Drawing on a range of opportunities across the liquidity spectrum, the multi-strategy team dynamically crafts solutions that evolve to match market complexities and act rapidly on emerging themes. Importantly, the multi-strategy portfolios reflect a series of carefully orchestrated bottom-up analyses which combine fundamental valuation, extensive credit work, and process analysis.': '利用流動性範圍的一系列機會，多戰略團隊動態地制定解決方案，以適應市場複雜性並迅速針對新興主題採取行動。 重要的是，多策略投資組合反映了一系列精心策劃的自下而上的分析，這些分析結合了基本面估值、廣泛的信貸工作和流程分析。',
    
    'Aiming to create value in everything we buy, and everywhere we buy it': '旨在爲我們購買的一切以及我們購買的任何地方創造價值',
    'TPG Angelo Gordon has been investing in commercial real estate since 1993 and has acquired over $45 billion of properties globally. ': '自1993年以來，TPG Angelo Gordon一直在投資商業房地產，並在全球範圍內收購了超過450億美元的房產。',
    "We take a value-added approach through the acquisition of sub-performing real estate properties across a broad range of geographic markets and product types. These assets are often attractively priced due to a lack of operating income, asset complexity, and the inefficiency of the sales process.": "我們採取增值方法，通過收購廣泛的地理市場和產品類型的房地產表現不佳。 這些資產通常由於缺乏運營收入、資產複雜性和銷售流程的低效率而具有吸引力的定價。",
    'Our approach to value creation ranges from the less intensive – improving existing operations, leasing, and renovations – to more intensive activity such as major repositioning, change of use, and, occasionally, ground-up development.': '我們的價值創造方法從不那麼密集的 - 改善現有的運營，租賃和翻新 - 到更密集的活動，如重大重新定位，改變用途，偶爾還有基礎開發。',
    'Our global real estate team’s experience encompasses all aspects of real estate investment and management, including acquisition, financing, leasing, construction management, and disposition. Our hands-on operational real estate expertise has proven critical to our success in acquiring and repositioning assets.': '我們的全球房地產團隊的經驗涵蓋房地產投資和管理的各個方面，包括收購，融資，租賃，建築管理和處置。 事實證明，我們實踐的運營房地產專業知識對我們成功收購和重新定位資產至關重要',
    'U.S. Real Estate': '美國房地產',
    'TPG Angelo Gordon began investing in real estate in the U.S. in 1993 and has acquired over $27 billion of properties in this region.': 'TPG Angelo Gordon於1993年開始在美國投資房地產，並在該地區收購了超過270億美元的房產。',
    'Learn More': '瞭解更多',
    'Asia Real Estate': '亞洲房地產',
    'TPG Angelo Gordon has acquired over $12 billion of properties in Asia since it began investing in the region in 2005.': '自2005年開始在亞洲投資以來，TPG Angelo Gordon已經在亞洲收購了超過120億美元的房產。',
    'Europe Real Estate': '歐洲房地產',
    'TPG Angelo Gordon has been investing in real estate in Europe since 2009 and has acquired over $6 billion of properties in this region.': 'TPG Angelo Gordon自2009年以來一直在歐洲投資房地產，並在該地區收購了超過60億美元的房產。',
    
    'Providing real estate sale-leaseback financing to less-than-investment grade owner-occupiers of corporate real estate': '爲低於投資級的企業房地產自住者提供房地產售回租賃融資',
    'Companies can access 100% of their real estate value while maintaining long-term operational control. The use of proceeds can include:': '公司可以100%獲得房地產價值，同時保持長期的運營控制。 收益的使用可以包括',
    'Facility expansions': '設施擴展',
    'Debt reduction': '減少債務',
    'M&A': 'M&A',
    'Growth/expansion financing': '增長/擴張融資',
    'Dividend recapitalizations': '股息資本重組',
    'Leveraged/management buyouts': '槓桿收購/管理層收購',
    'Corporate restructuring/exit financing': '企業重組/退出融資',
    'Synthetic lease conversions': '合成租賃轉換',
    'In addition to working with privately-held and public large and middle market companies, our team has extensive experience providing sale-leaseback financing to financial sponsors and their portfolio companies.': '除了與私營和公共大中型市場公司合作外，我們的團隊還擁有豐富的經驗，爲金融贊助商及其投資組合公司提供售後回租融資。',
    'Angelo Gordon, with its significant experience as a principal investor in credit and real estate assets, provides an underwriting platform in the net lease industry, enabling the Net Lease group to execute transactions with a high degree of certainty, timeliness, and sophistication.': '作爲信貸和房地產資產的主要投資者，Angelo Gordon擁有豐富的經驗，爲淨租賃行業提供了一個承銷平臺，使淨租賃集團能夠以高度的確定性，及時性和複雜性執行交易。',
    
    'Diversified Real Estate platform with multiple strategies including private equity, core plus, and residential and commercial debt': '多元化房地產平臺，包括私募股權，核心+以及住宅和商業債務在內的多種策略',
    'Systematic approach to real estate investing': '房地產投資的系統方法',
    'We established our real estate investing practice to systematically pursue secular growth trends and opportunities borne from dislocation. We seek to utilize a thematic approach to generate attractive returns for our investors throughout cycles. Since inception, our real estate investing efforts have grown substantially, and today, we invest through several distinct strategies.': '我們建立了房地產投資實踐，系統地追求長期增長趨勢和錯位帶來的機會。 我們尋求採用主題方法，在整個週期內爲投資者創造有吸引力的回報。 自成立以來，我們的房地產投資工作大幅增加，今天，我們通過幾種不同的策略進行投資',
    '$18B': '$180億',
    '2009': '2009',
    '$6B+': '$60 億+',
    
    'Established in 2009, TPG Real Estate Partners (TREP) is TPG’s dedicated opportunistic real estate equity investment platform. TREP has built a differentiated investment portfolio comprised primarily of real estate-rich platforms and portfolios located in the United States and Europe.': 'TPG Real Estate Partners（TREP）成立於2009年，是TPG專門的機會主義房地產股權投資平臺。 TREP建立了一個差異化的投資組合，主要由位於美國和歐洲的房地產豐富平臺和投資組合組成。',
    '$11.7B': '$117億',
    
    'TPG Real Estate Thematic Advantage Core-Plus': 'TPG房地產主題優勢核心+',
    'Established in 2021, TPG Real Estate created Thematic Advantage Core-Plus (TAC+) to pursue real estate investments in the US with stabilized tenancy and high potential for enduring cash flow. The TAC+ strategy invests in strategic real estate assets with inherent value and appreciation potential based on location, quality, and tenancy.': 'TPG Real Estate成立於2021年，創建了Thematic Advantage Core-Plus（TAC +），旨在在美國進行房地產投資，其租賃穩定，現金流持久潛力巨大。 TAC+戰略投資於戰略房地產資產，其固有價值和升值潛力基於位置，質量和租約。',
    '$1.7B': '$17億',
    'TPG established TPG Real Estate Finance Trust, Inc. (NYSE: TRTX) in late 2014 and took the business public in July 2017. TRTX directly originates, acquires, and manages commercial mortgage loans and other commercial real estate-related debt instruments in North America. The TRTX portfolio consists of first mortgage loans (or interests therein) and mezzanine loans, with total commitments of $5.2 billion.': 'TPG於2014年底成立了TPG Real Estate Finance Trust, Inc.（紐約證券交易所代碼:TRTX），並於2017年7月上市。 TRTX在北美直接發起，收購和管理商業抵押貸款和其他商業房地產相關債務工具。 TRTX投資組合包括首套抵押貸款（或其利息）和夾層貸款，總承諾額爲52億美元。',
    '$3.8B': '$38億',
    
    'A differentiated approach to opportunistic real estate investing': '機會主義房地產投資的差異化方法',

    'Value creation at the property, portfolio, and platform levels': '物業、投資組合和平臺層面的價值創造',
    'The foundation of TREP’s investment strategy is a research and data-driven approach to theme generation, allowing us to be highly selective in identifying the most compelling opportunities in targeted sectors and geographies. During TREP’s ownership, our value creation strategies focus on driving returns across three critical dimensions: optimization of property-level performance, aggregation and curation of strategically aligned portfolios, and enhancement of platform capabilities. We generally pursue these strategies in partnership with dedicated management teams. TREP is committed to responsible investing practices and the deliberate consideration of environmental, social, and governance factors across all stages of the investment process.': 'TREP投資戰略的基礎是研究和數據驅動的主題生成方法，使我們能夠高度選擇性，以確定目標行業和地區中最引人注目的機會。 在TREP的所有權期間，我們的價值創造戰略側重於在三個關鍵維度上推動回報:優化物業級績效，聚合和策劃戰略一致的投資組合，以及增強平臺能力。 我們通常與專門的管理團隊合作實施這些策略。 TREP致力於負責任的投資實踐，並在投資過程的所有階段仔細考慮環境，社會和治理因素。',
    'Recent investments reflect our current thematic focus areas, including Alloy Properties, a US-based life sciences and innovation portfolio aggregation strategy; Cinespace Studios, a content production studio platform based in the U.S. and Canada; B2R Partners, a US single-family build-to-rent venture; and multiple industrial strategies in both the US and Europe.': '最近的投資反映了我們當前的主題重點領域，包括Alloy Properties，美國的生命科學和創新組合聚合戰略;Cinespace Studios，美國和加拿大的內容製作工作室平臺;B2R Partners， 一家美國單戶建租企業;以及美國和歐洲的多種工業戰略',
    
    'Learn more about TREP’s recent investment in Cinespace Studios': '瞭解更多關於TREP最近對Cinespace Studios的投資',
    
    'Investing thematically in high-quality real estate to drive consistent long-term performance': '以主題方式投資高質量的房地產，以推動持續的長期業績',
    'Established in 2021, TPG Real Estate created Thematic Advantage Core-Plus (TAC+) to pursue real estate investments in the US with a low risk profile and high potential for enduring cash flow. The TAC+ strategy invests in strategic real estate assets with inherent stability based on location, quality, and tenancy.': 'TPG Real Estate成立於2021年，創建了Thematic Advantage Core-Plus（TAC+），旨在在美國進行低風險和高持續現金流潛力的房地產投資。 TAC+戰略投資於戰略房地產資產，其固有的穩定性基於位置，質量和租約。',
    'Built on a foundation of longstanding real estate investing expertise': '建立在長期房地產投資專業知識的基礎之上',
    'TAC+ leverages select established real estate investment themes where we have deep sector insights, distinctive experience, and a strong market presence.': 'TAC+利用精選的成熟房地產投資主題，我們擁有深厚的行業洞察力，獨特的經驗和強大的市場存在。',
    
    'Experience and innovation in commercial real estate financing': '商業地產融資的經驗與創新',
    'TPG established TPG Real Estate Finance Trust, Inc. (NYSE: TRTX) in late 2014 and took the business public in July 2017. TRTX directly originates, acquires, and manages commercial mortgage loans and other commercial real estate-related debt instruments in primary and select secondary markets in North America. The TRTX portfolio consists primarily of first mortgage loans (or interests therein), with total commitments of $5.2 billion.': 'TPG於2014年底成立了TPG Real Estate Finance Trust, Inc.（紐約證券交易所代碼:TRTX），並於2017年7月上市。 TRTX在北美主要和特定二級市場直接發起，收購和管理商業抵押貸款和其他商業房地產相關債務工具。 TRTX投資組合主要由首套抵押貸款（或其利息）組成，承諾總額爲52億美元',
    
    'Flexible capital solutions for institutional owners of value-add real estate': '爲增值房地產的機構所有者提供靈活的資本解決方案',
    'TRTX creates flexible, highly structured financing solutions for property owners with transitional capital needs across a wide spectrum of real estate asset types. Through connectivity with TPG’s global platform, TRTX benefits from the firm’s scope of knowledge, expertise, relationships, and intellectual capital.': 'TRTX爲具有廣泛房地產資產類型過渡資本需求的業主提供了靈活、高度結構化的融資解決方案。 通過與TPG全球平臺的連接，TRTX受益於公司的知識，專業知識，關係和知識資本的範圍。',
    'With decades of experience as lenders, our team has unparalleled market insight and an extensive relationship network. Externally managed by an affiliate of TPG, TRTX aims to provide attractive risk-adjusted returns to its stockholders over time through cash distributions and stock price appreciation.': '憑藉數十年的貸款經驗，我們的團隊擁有無與倫比的市場洞察力和廣泛的關係網絡。 TRTX由TPG的子公司外部管理，旨在通過現金分配和股價升值爲其股東提供具有吸引力的風險調整回報。',
    
    'To learn more, please visit the TPG RE Finance Trust Website': '欲瞭解更多信息，請訪問TPG RE Finance Trust網站',
    
    'Our Market Solutions platform leverages the full TPG ecosystem to create differentiated products that address specific market opportunities.': '我們的市場解決方案平臺利用完整的TPG生態系統來創造差異化的產品，以應對特定的市場機會。',
    'Powered by the TPG Ecosystem': '由TPG生態系統提供支持',
    'TPG’s Market Solutions platform provides a suite of differentiated products for specific market opportunities across capital markets, public markets investing, SPACs, and private market solutions.': 'TPG的市場解決方案平臺爲資本市場、公共市場投資、SPAC和私人市場解決方案的特定市場機會提供了一套差異化的產品。',
    '$8B': '$80億',
    '2006': '2006',
    'Advising across the capital structure in all aspects of debt and equity financings, our capital markets group has been developing bespoke financing solutions while integrating seamlessly with our investment deal teams for over 15 years.': '在債務和股權融資的各個方面爲資本結構提供諮詢，我們的資本市場集團一直在開發定製的融資解決方案，同時與我們的投資交易團隊無縫集成超過15年。',
    
    'Debt Capital Markets': '債務資本市場',
    'TPG’s Debt Capital Markets team acts as an independent, fully functioning broker-dealer, with the ability to place a broad range of financing solutions, not only for our own portfolio, but also for capital providers outside of TPG.': 'TPG的債務資本市場團隊是一個獨立的，功能齊全的經紀商，不僅能夠爲我們自己的投資組合提供廣泛的融資解決方案，而且能夠爲TPG以外的資本提供商提供融資解決方案。',
    '$160B': '160億美元',
    'in corporate debt raised across market cap and sectors': '在跨市值和部門籌集的公司債務中',
    
    'Equity Capital Markets': '股權資本市場',
    'TPG’s Equity Capital Markets team acts as a lead advisor and underwriter on capital raises and monetization of our ownership stakes in the public equity markets, including initial public offerings, SPACs, follow-on offerings, equity-linked products, and subsequent realizations. We also provide dual-track and structured equity solutions advisory services.': 'TPG的股權資本市場團隊擔任首席顧問和承銷商，負責我們在公共股票市場的所有股權融資和貨幣化，包括首次公開發行，SPACs，後續發行，股權相關產品和隨後的變現。 我們還提供雙軌和結構化股權解決方案諮詢服務',
    '170+': '170+',
    'Public equity realizations executed since 2014': '自2014年以來執行的公共股本實現',
    
    'Public Markets Investing': '公共市場投資',
    'Drawing on the full intellectual capital and sector-focused resources from our private investment business, TPG manages a number of strategies that invest in public companies—albeit each with a unique approach.': '利用我們私人投資業務的全部知識資本和以部門爲中心的資源，TPG管理着許多投資於上市公司的戰略 - 儘管每種戰略都有獨特的方法。',
    'TPG Public Equities (TPEP)': 'TPG公共股票（TPEP）',
    'Established in 2013, TPG Public Equity Partners (TPEP) seeks to generate superior risk-adjusted returns through deep, fundamental private equity-style research in the public markets. TPEP currently manages a long/short fund (approximately $2.6 billion) and a long-only fund (approximately $1.8 billion), both of which are managed with broad, opportunistic mandates that allow TPEP to invest globally across different sectors and market capitalizations.': 'TPG Public Equity Partners（TPEP）成立於2013年，旨在通過在公開市場進行深入的基本私募股權研究來產生卓越的風險調整回報。 TPEP目前管理着一隻長/短基金（約26億美元）和一隻長只基金（約18億美元），這兩隻基金都以廣泛的機會主義授權進行管理，使TPEP能夠在不同部門和市值上進行全球投資。',
    '$3.3B': '33億美元',
    'TPG Public Equities': 'TPG公共股票',
    'TPG Strategic Capital Fund (TSCF)': 'TPG戰略資本基金（TSCF）',
    'We launched TPG Strategic Capital Fund (TSCF) in 2020 in response to an opportunity we saw to combine the expertise of our private equity business with the firm’s public equity investing capabilities to build long-term equity value in public companies. TSCF makes concentrated investments in public companies where we can work constructively with management and boards to drive meaningful strategic and operational transitions.': '我們在2020年推出了TPG戰略資本基金（TSCF），以迴應我們看到的一個機會，將我們的私募股權業務的專業知識與公司的公共股權投資能力相結合，以在上市公司中建立長期股權價值。 TSCF對上市公司進行集中投資，我們可以與管理層和董事會進行建設性的合作，以推動有意義的戰略和運營轉型。',
    '$625M': '6.25億美元',
    'TPG Strategic Capital Fund': 'TPG戰略資本基金',
    
    'Special Purpose Acquisition Companies (SPACs)': '特殊目的收購公司（SPACs）',
    'Since launching our SPAC sponsorship platform, the Pace Group, in 2015, TPG has raised seven vehicles for over $3 billion in IPO proceeds, while also driving market-leading terms and structures.': '自2015年推出SPAC贊助平臺Pace Group以來，TPG已經籌集了7輛汽車，IPO收入超過30億美元，同時推動了市場領先的條款和結構。',
    
    'The Pace Group': 'The Pace Group',
    "TPG Pace Group is the firm's dedicated permanent capital platform, created in 2015 with the objective of sponsoring SPACs and other permanent capital solutions for companies. TPG Pace Group has a long-term, patient, and highly flexible capital base, allowing us to seek transactions across industries and geographies. The team leverages insights and skillsets from across the firm, including our Capital Markets group, our Operations group, and Y Analytics, to offer a differentiated value proposition to potential targets.": "TPG Pace Group是該公司於2015年創建的專用永久資本平臺，旨在爲公司贊助SPAC和其他永久資本解決方案。 TPG Pace Group擁有長期、耐心和高度靈活的資本基礎，使我們能夠尋求跨行業和地區的交易。 該團隊利用來自整個公司的見解和技能，包括我們的資本市場小組，我們的運營小組和Y Analytics，爲潛在目標提供差異化的價值主張。",
    '7': '7',
    'Pace vehicles raised over the last six years': '在過去六年中提升的Pace車輛',
   
    'Private Market Solutions': '私人市場解決方案',
    'TPG’s private markets solutions business invests in high-quality, stable private equity assets alongside third-party sponsors, typically through continuation vehicles, funds, or third-party investment managers who retain control of the assets. Our private markets solutions business is highly complementary to our existing investment capabilities.': 'TPG的私人市場解決方案業務與第三方贊助商一起投資於高質量，穩定的私募股權資產，通常通過保留資產控制的延續工具，基金或第三方投資經理。 我們的私人市場解決方案業務與我們現有的投資能力高度互補。',
    
    'TPG NewQuest': 'TPG NewQuest',
    'Established in 2011, TPG NewQuest is one of Asia’s leading secondary private equity managers, which specializes in providing strategic liquidity solutions to private equity asset owners and has a focused strategy of acquiring secondary private equity positions in companies across the Asia Pacific region. TPG NewQuest is principally focused on bespoke general partner-led secondary transactions, distinguishing it from the majority of secondary firms in the market today by delivering attractive hybrid returns, targeting secondary-type DPIs and primary-style multiples.': 'TPG NewQuest成立於2011年，是亞洲領先的二級私募股權經理之一，專門爲私募股權資產所有者提供戰略流動性解決方案，並具有收購亞太地區公司二級私募股權頭寸的專注戰略。 TPG NewQuest主要專注於定製的普通合夥人領導的二級交易，通過提供有吸引力的混合回報，針對二級DPI和一級倍數，將其與當今市場上大多數二級公司區分開來',
    '30+': '30+',
    'Investment Professionals across five offices in Asia': '亞洲五個辦事處的投資專業人士',
    
    'TPG GP Solutions': 'TPG GP解決方案',
    'Established in 2021, TPG GP Solutions (TGS) invests in high-quality private equity assets that are primarily based in North America and Europe, in partnership with third-party general partners. TGS brings a private equity approach to the general partner-led secondaries market and leverages the insights of the broader TPG ecosystem in addition to the TGS team’s deep investing experience.': 'TPG GP Solutions（TGS）成立於2021年，與第三方普通合夥人合作，投資於主要在北美和歐洲的高質量私募股權資產。 TGS爲普通合夥人領導的二級市場帶來了私募股權投資方法，並利用了更廣泛的TPG生態系統的見解，以及TGS團隊的深厚投資經驗。',
    '9': '9',
    'Experienced direct and secondaries investors across North America and Europe': '北美和歐洲經驗豐富的直接和間接投資者',
    
    'A private equity approach to public equity investing': '公共股權投資的私募股權方法',
    'Established in 2013, TPG Public Equity Partners (TPEP) seeks to generate superior risk-adjusted returns through deep, fundamental private equity-style research in the public markets. TPEP currently manages a long/short fund (approximately $2.2 billion) and a long-only fund (approximately $1.7 billion), both of which are managed with broad, opportunistic mandates that allow TPEP to invest globally across different sectors and market capitalizations.': 'TPG Public Equity Partners（TPEP）成立於2013年，旨在通過在公開市場進行深入的基本私募股權研究來產生卓越的風險調整回報。 TPEP目前管理着一隻長/短基金（約22億美元）和一隻長只基金（約17億美元），這兩隻基金都擁有廣泛的機會主義授權，允許TPEP在不同部門和市值上進行全球投資。',
    
    'Fully integrated within TPG': '完全集成在TPG中',
    'A key aspect of TPEP’s approach is that it is integrated with our private investment business, allowing TPEP to collaborate with sector-focused teams across TPG and leverage the intellectual capital and resources of the entire firm.': 'TPEP方法的一個關鍵方面是它與我們的私人投資業務集成，允許TPEP與整個TPG的以行業爲中心的團隊合作，並利用整個公司的知識資本和資源。',
   
    'Select Investments.': '選擇投資',
    
    'Diversity, Equity, and Inclusion': '多樣性、公平性和包容性',
    'We believe that the quality of our investments and our ability to build great companies depend on the originality of our insights': '我們相信，我們投資的質量和建立偉大公司的能力取決於我們洞察力的獨創性。',
    'Promoting diversity, equity and inclusion is a core value at TPG, embedded into the highest levels of our firm and guided by our Diversity, Equity & Inclusion Council. Created in 2015, the DE&I Council is a 14-member partner steering committee led by Firm Partner Maryanne Hancock and President Todd Sisitsky. It is supported by three advisory groups focused on recruiting and engagement with our external ecosystem and internal teams.': '促進多樣性，公平性和包容性是TPG的核心價值，植根於我們公司的最高層，並由我們的多樣性，公平和包容性委員會指導。 DE&I Council成立於2015年，是一個由14名成員組成的合作伙伴指導委員會，由公司合夥人Maryanne Hancock和總裁Todd Sisitsky領導。 它得到了三個諮詢小組的支持，這些諮詢小組專注於招募和參與我們的外部生態系統和內部團隊。',
    'Board Diversity Initiative': '董事會多樣性倡議',
    'We have advanced board diversity on our portfolio companies’ boards of directors in terms of gender, race, ethnicity, and sexual orientation. As of June 2023, more than 80% of our portfolio companies’ US-based boards have both gender and racial/ethnic diversity, and our companies have appointed more than 400 diverse directors since 2017. To broaden our executive network and influence our larger ecosystem, we have worked with leading diverse director organizations—such as the Thirty Percent Coalition, Women Corporate Directors, Him for Her, Ascend-Pinnacle, the Latino Corporate Directors Association, The Alumni Society, Board Diversity Action Alliance, Black Women on Boards, The Boule Foundation, LGBTQ+ Corporate Directors, and Stanford Women on Boards—as collaborators, speakers, and often sponsors.': '我們在性別，種族，種族和性取向方面，我們投資組合公司的董事會具有先進的董事會多樣性。 截至2023年6月，我們投資組合公司80%以上的美國董事會既有性別也有種族/族裔多樣性，自2017年以來，我們公司已任命了400多名多元化董事。 爲了擴大我們的執行網絡並影響我們更大的生態系統，我們與領先的多元化董事組織合作 - 例如三成聯盟，女性公司董事，他爲她，昇天 - 釘子戶，拉丁裔公司董事協會，校友會，董事會多元化行動聯盟，董事會中的黑人女性。 Boule基金會，LGBTQ +公司董事和斯坦福大學董事會中的女性 - 作爲合作者，演講者，通常是贊助商。',
    
    'Investing in Diverse-led Investment Managers': '投資於多元化的投資經理人',
    'In 2019, TPG launched the TPG NEXT initiative, which develops emerging and diverse alternative asset managers. TPG NEXT launched with an investment in Harlem Capital Partners, a venture capital firm focused on investing in companies with diverse founders. TPG NEXT has since made investments in LandSpire Group, a real estate investment fund aiming to provide under-resourced communities with the necessary infrastructure to create equitable growth and a sustainable ecosystem, and VamosVentures, an impact venture capital fund working to deliver meaningful impact through wealth creation, social mobility, unique tech solutions, and taking a visible stand as diverse investors.': '2019年，TPG啓動了TPG NEXT計劃，該計劃旨在開發新興和多樣化的替代資產管理公司。 TPG NEXT投資Harlem Capital Partners，這是一家專注於投資創始人多樣化的公司的風險投資公司。 此後，TPG NEXT對LandSpire Group進行了投資，該基金旨在爲資源不足的社區提供必要的基礎設施，以創造公平增長和可持續生態系統，以及VamosVentures，一個致力於通過創造財富產生有意義影響的風險資本基金。 社會流動性，獨特的技術解決方案，並採取可見的立場作爲多樣化的投資者',
    
    'TPG engages on public issues to advance equality, such as immigration, gender and racial discrimination, women’s issues and access to education. For example, in 2017, TPG authored guidance for our portfolio companies encouraging preservation of Deferred Action for Childhood Arrivals (DACA) and providing support and financial resources to those impacted. In 2018, TPG joined the Business Coalition for the Equality Act. In 2019, TPG was the only private equity firm to sign an amicus brief to the U.S. Supreme Court in support of non-discrimination protections for LGBTQ+ people in federal civil rights law.': 'TPG致力於促進平等的公共問題，如移民，性別和種族歧視，婦女問題和受教育的機會。 例如，在2017年，TPG爲我們的投資組合公司撰寫了指導意見，鼓勵保留兒童入境遞延行動（DACA），併爲受影響者提供支持和財政資源。 2018年，TPG加入了平等法案商業聯盟。 2019年，TPG是唯一一家向美國最高法院簽署法庭之友簡報的私募股權公司，以支持聯邦民權法中對LGBTQ +人的非歧視保護。',
    
    'TPG has a longstanding commitment to fostering strong ESG performance as a firm and in our investment practices': 'TPG長期以來一直致力於促進作爲一家公司和我們的投資實踐中的強勁ESG業績',
    'TPG first adopted a Global ESG Performance Policy in 2012 and became a signatory to the UN Principles of Responsible Investment in 2013. Each year, we continue to strengthen and deepen the integration of ESG performance throughout the firm.': 'TPG於2012年首次採用全球ESG績效政策，並於2013年成爲聯合國負責任投資原則的簽署國。 每年，我們都會繼續加強和深化整個公司ESG績效的整合',
    '2023 Annual ESG Review': '2023年度ESG回顧',
    "TPG Inc.'s ESG Policy": "TPG Inc.的ESG政策",
    
    'Y Analytics': 'Y Analytics',
    'Y Analytics, a public benefit organization that is wholly owned by TPG, operates the firm’s ESG performance arm. Y Analytics’ mission is to increase not only the amount of capital that is allocated toward impact, but also to improve the effectiveness of capital invested toward the greater good. TPG’s ESG Strategy Council unites the leadership of Y Analytics with the leaders of multiple functions that touch ESG issues, including Legal, Compliance, Human Resources & Human Capital and Operations. Together, the ESG Strategy Council and Y Analytics provide leadership and support to our investment professionals on ESG topics throughout the lifecycle of our investments.': 'Y Analytics是一家由TPG全資擁有的公益組織，負責運營公司的ESG績效部門。 Y Analytics的使命是不僅增加分配給影響力的資本數量，而且提高投資於更大利益的資本的有效性。 TPG 的 ESG 戰略委員會將 Y Analytics 的領導地位與涉及 ESG 問題（包括法律、合規性、人力資源和人力資本以及運營）的多個職能部門的領導者結合起來。 ESG Strategy Council 和 Y Analytics 在我們投資的整個生命週期內爲我們的投資專業人士提供 ESG 主題的領導和支持。',
    
    'Environmental Impact': '環境影響',
    "TPG is also focused on the firm's own environmental impact, including our firm-wide operational and financed emissions as well as risks in our portfolio. We have analyzed and verified our firm-wide operational emissions since 2019, and regularly engage with our invested companies on financially material climate and environmental priorities.": "TPG還專注於公司自身的環境影響，包括我們全公司的運營和融資排放以及我們投資組合中的風險。 自2019年以來，我們一直在分析和驗證我們公司的運營排放，並定期與我們的投資公司就財務上重要的氣候和環境優先事項進行接觸。",
    
    "TPG's Partnership with Ownership Works": "TPG與所有權作品的合作關係",
    'TPG is proud to be a founding partner of Ownership Works, a nonprofit that launched in April 2022 with a mission to increase prosperity through shared ownership at work. With the support of over 60 partners across the private, public and nonprofit sectors, Ownership Works will develop and help implement broad-based employee ownership programs to create better work environments and financial opportunities for employees, and to help businesses improve their performance by attracting and retaining engaged employees who are invested in their company’s success.': 'TPG很自豪能成爲Ownership Works的創始合作伙伴，Ownership Works於2022年4月成立，其使命是通過工作中的共享所有權增加繁榮。 在私營、公共和非營利部門的60多個合作伙伴的支持下，Ownership Works將制定並幫助實施基礎廣泛的員工所有權計劃，爲員工創造更好的工作環境和財務機會。 並通過吸引和留住爲公司成功而投資的敬業員工來幫助企業提高業績。',
    
    'Read our 2023 Annual ESG Review': '閱讀我們的2023年度ESG評論',
   
    'Diversity, Equity, And Inclusion': '多樣性、公平性和包容性',
    'Promoting diversity, equity and inclusion is a core value at TPG': '促進多樣性、公平性和包容性，是TPG的核心價值。',
    
    'At TPG, we believe that the quality of our investments and our ability to build great companies depend on the originality of our insights. Promoting diversity, equity and inclusion is a core value at TPG, embedded into the highest levels of our firm and guided by our Diversity, Equity & Inclusion Council. Created in 2015, the DE&I Council is a 14-member partner steering committee led by Firm Partner Maryanne Hancock and President Todd Sisitsky. It is supported by three advisory groups focused on recruiting and engagement with our external ecosystem and internal teams.': '在TPG，我們相信我們的投資質量和建立偉大公司的能力取決於我們洞察力的獨創性。 促進多樣性，公平性和包容性是TPG的核心價值，植根於我們公司的最高層，並由我們的多元化，公平和包容性委員會指導。 DE&I Council成立於2015年，是一個由14名成員組成的合作伙伴指導委員會，由公司合夥人Maryanne Hancock和總裁Todd Sisitsky領導。 它得到了三個諮詢小組的支持，這些諮詢小組專注於招募和參與我們的外部生態系統和內部團隊。',
    
    'TPG is committed to providing equal employment opportunities to all employees and applicants without regard to race, religion, color, sex, gender identity and expression, sexual orientation, pregnancy, national origin, ancestry, citizenship status, uniform service member and veteran status, marital status, age, disability, or any other category protected by applicable Federal, State, and local laws. This policy applies to all terms and conditions of employment, including, but not limited to, recruiting, hiring, placement, discipline, promotions, termination, layoff, transfer, leaves of absence, benefits, compensation, and training. All TPG employees are required to adhere to this policy.': 'TPG致力於爲所有員工和申請人提供平等的就業機會，而不考慮種族，宗教，膚色，性別，性別認同和表達，性取向，懷孕，民族血統、公民身份、制服服務成員和退伍軍人身份、婚姻狀況、年齡、殘疾、性別認同和表達。 或任何其他受適用的聯邦、州和地方法律保護的類別。 本政策適用於所有僱用條款和條件，包括但不限於招聘，僱用，安置，紀律，晉升，解僱，調動，休假，福利、補償和培訓。 所有TPG員工都必須遵守該政策',
    'TPG is committed to promoting a culture of ethical conduct and complying with applicable laws, regulations, and policies, and expects all employees to follow this commitment in all aspects of their work. The Firm encourages and expects all employees to raise concerns about any conduct that the employee believes, in good faith, could create legal, regulatory, or reputational harm to the Firm.': 'TPG致力於促進道德操守文化，遵守適用的法律、法規和政策，並希望所有員工在其工作的各個方面都遵循這一承諾。 該公司鼓勵並期望所有員工對員工認爲善意的任何行爲表示擔憂，這些行爲可能會對公司造成法律，監管或聲譽損害。',
    'Vendor Anti-Discrimination Policy': '供應商反歧視政策',
    
    'Ecosystem, Engaged.': '生態系統，參與',
    
    'TPG’s strategy is anchored by our deep sector knowledge and experience building healthy, successful businesses. Explore the partnerships and the insights behind our investments.': 'TPG的戰略以我們深厚的行業知識和經驗爲基礎，建立健康，成功的企業。 探索我們投資背後的合作伙伴關係和見解',
    
    'TPG Insights': 'TPG Insights',
    
    'All Insights': '所有見解',
    
    'NEWSLETTER': '『時事通訊』',
    
    'Capital and the Climate Revolution: Jim Coulter on the Scale, Scope, and Complexity of Climate Investing': '《資本與氣候革命:吉姆·庫爾特談氣候投資的規模、範圍和複雜性》(Capital and the Climate Revolution: Jim Coulter on the Scale, Scope, and Complexity of Climate Investing)',
    
    'In our latest edition of The TPG Take, we sit down with Jim Coulter, TPG Co-Founder, Executive Chairman, and Managing Partner of TPG Rise Climate, to discuss his perspectives on the climate investing market, the challenges and opportunities that the growing investing landscape presents, and the important': '在我們最新版的TPG Take中，我們與TPG聯合創始人，執行董事長兼TPG Rise Climate的管理合夥人Jim Coulter坐下來，討論他對氣候投資市場，不斷增長的投資環境帶來的挑戰和機遇以及重要性的看法',
    
    '4月.22日.2024': '4月.22日.2024',
    
    'SECTOR INSIGHTS': '行業洞察',
    
    'The Consumer Landscape in Focus': '聚焦的消費者景觀',
    
    'Our recent investment in Classic Collision, one of the fastest growing collision repair platforms, reflects our longstanding focus on consumer services. Partner Paul Hackwell shared what excites him about the broader consumer landscape and the opportunities we see for scaled, customer-first services platforms like': '我們最近對Classic Collision的投資是增長最快的碰撞修復平臺之一，反映了我們對消費者服務的長期關注。 合作伙伴Paul Hackwell分享了他對於更廣泛的消費者格局以及我們看到的大規模客戶優先服務平臺的機會感到興奮的事情',
    
    '4月.08日.2024': '4月.08日.2024',
    'Asset-Based Credit: The Post-Bank Era': '基於資產的信貸: 《後銀行時代》',
    'In this new series, TPG Angelo Gordon’s Structured Credit & Specialty Finance team offers a deep dive on an emerging, but core, private credit asset class: specialty or asset-based private credit.': '在這個新系列中，TPG Angelo Gordon的結構化信貸和專業金融團隊提供了一個新興但核心的私人信貸資產類別:專業或基於資產的私人信貸。',
    
    '3月.20日.2024': '3月.20日.2024',
    
    'Filter': '過濾器',
    
    'TPG Angelo Gordon’s Q2 2024 Capital Markets Perspectives': 'TPG Angelo Gordon的2024年第二季度資本市場展望',

    'Increasing Opportunity in the Net Lease Space': '在淨租賃空間增加機會',
    
    'TPG Rise Climate Conversations': 'TPG上升氣候對話',
    
    'TPG Insights: Private Markets: Tectonic Shifts': 'TPG Insights: 私人市場: 構造變化',
    
    'Insights on the Mid-Market Lending Outlook with TPG Twin Brook’s Pete Notter': 'TPG Twin Brook的Pete Notter對中期市場貸款前景的看法',
    'ALL PRESS RELEASES': '所有新聞稿',
    
    '4月.26日.2024': '4月.26日.2024',
    'IK Partners divests Yellow Hive to a newly established continuation fund': 'IK Partners將Yellow Hive剝離給新成立的續約基金',
    '4月.17日.2024': '4月.17日.2024',
    'Sayari Closes $235 Million Strategic Investment from TPG, Inclusive of Additional Capital for Organic Growth and M&A': 'Sayari完成TPG2.35億美元戰略投資，包括用於有機增長和併購的額外資本',
    '4月.04日.2024': '4月.04日.2024',
    'TPG Announces Results of Subsequent Acceptance Period following Voluntary Reopening of its Takeover Offer of Intervest; Announces Reopening of its Offer as a Simplified Squeeze-Out and Reduction of the Bid Price with the Dividend to be Approved by Intervest’s AGM': 'TPG宣佈自願重新開放其收購要約後的接受期結果;宣佈將其要約重新開放爲簡化擠壓並降低投標價格，並由參與者的AGM批准的分紅',
    '4月.03日.2024': '4月.03日.2024',
    'TPG Agrees to Acquire Classic Collision': 'TPG同意收購經典碰撞',
    '3月.06日.2024': '3月.06日.2024',
    'New impact venture Syre launches with a mission to decarbonize textile industry': '新的影響力風險投資公司Syre推出，其使命是使紡織行業脫碳',
    '2月.29日.2024': '2月.29日.2024',
    'TPG Announces Pricing of Senior Notes': 'TPG宣佈高級債券定價',
    '2月.28日.2024': '2月.28日.2024',
    'TPG Announces Results of Initial Acceptance Period for its Takeover Offer of Intervest': 'TPG宣佈其收購要約的初始接受期結果',
    '2月.27日.2024': '2月.27日.2024',
    'TPG Announces Public Offering of Senior Notes and Fixed-Rate Junior Subordinated Notes': 'TPG宣佈公開發行高級債券和固定利率初級次級債券',
    '2月.26日.2024': '2月.26日.2024',
    'TPG Announces Pricing of Secondary Public Offering': 'TPG宣佈二級公開發行定價',
    'TPG Announces Launch of Secondary Public Offering': 'TPG宣佈啓動二次公開發行',
    '2月.13日.2024': '2月.13日.2024',
    'TPG Reports Fourth Quarter and Full Year 2023 Results': 'TPG報告2023年第四季度和全年業績',
    '2月.05日.2024': '2月.05日.2024',
    'Scott Lebovitz Joins TPG as Partner to Lead TPG Rise Climate Infrastructure Investing': 'Scott Lebovitz加入TPG，成爲TPG崛起氣候基礎設施投資的合作伙伴',

    'Newsletter Archive': '『時事通訊檔案』',
    'Date': '日期',
    'Title': '標題',
    '4月/22日/2024': '4月/22日/2024',
    '3月/18日/2024': '3月/18日/2024',
    '2024 in Focus with CEO Jon Winkelried: Macro, Credit, and More': '2024年，CEO喬恩·溫克爾裏德（Jon Winkelried）: 宏觀、信貸和更多',
    
    'General Contact': '一般聯繫人',

    'TPG is a leading global alternative asset manager with $224 billion in assets under management': 'TPG資本是全球領先的另類資產管理公司，管理着2,240億美元的資產',
    "Jim Coulter and David Bonderman, former colleagues at the Bass Family Office, created TPG in 1992 and opened the firm's first offices in San Francisco. Today, TPG is led by CEO Jon Winkelried, who became sole CEO in 2021 after serving as Co-CEO since 2015.": "Bass家族辦公室的前同事Jim Coulter和David Bonderman於1992年創立了TPG，並在舊金山開設了第一家辦事處。 今天，TPG由首席執行官Jon Winkelried領導，他在2015年擔任聯合首席執行官後於2021年成爲唯一的首席執行官。",
    'A Unique Perspective': '獨特的視角',
    'With our family office roots, entrepreneurial heritage, and West Coast base, TPG has developed a distinctive approach to alternative investments based on innovation-led growth, an affinity for disruption and technology, and a distinctive culture of openness and collaboration.': '憑藉我們的家族辦公室根源，創業遺產和西海岸基地，TPG已經開發出一種獨特的替代投資方法，基於創新主導的增長，對顛覆和技術的親和力，以及獨特的開放與合作文化。',
    'Innovation and Organic Growth': '創新與有機增長',
    'Our principled focus on innovation has resulted in a disciplined, organic evolution of our business. Incubating, launching, and scaling new platforms and products organically—often early in the development of important industry trends—is embedded in our DNA. Over 30 years, we have developed an ecosystem of insight, engagement, and collaboration across our platforms and products, which currently include more than 300 active portfolio companies headquartered in more than 30 countries. With an extensive track record, a diversified set of investment strategies, and a strategic orientation towards areas of high growth, such as technology, healthcare, and impact, we are helping shape the future of alternative asset management.': '我們對創新的原則關注導致了我們業務的紀律，有機發展。 有機地孵化、推出和擴展新平臺和產品——通常是在重要行業趨勢發展的早期——已經嵌入到我們的DNA中。 30多年來，我們開發了一個跨平臺和產品的洞察力，參與和協作的生態系統，目前包括總部位於30多個國家的300多家活躍投資組合公司。 憑藉廣泛的業績記錄，多元化的投資策略，以及對高增長領域的戰略定位，如技術，醫療保健和影響，我們正在幫助塑造替代資產管理的未來。',
    'Strategic Acquisition': '戰略收購',
    'In 2023, TPG acquired Angelo Gordon, marking a significant expansion into credit investing and offering real estate capabilities that are complementary to our current strategies. This strategic transaction meaningfully expanded our investing capabilities and broadens our product offering, underscoring our continued focus on growing and scaling through diversification.': '2023年，TPG收購了Angelo Gordon，標誌着信貸投資的重大擴張，並提供與我們當前戰略互補的房地產能力。 這項戰略交易有意義地擴大了我們的投資能力，擴大了我們的產品範圍，突顯了我們對通過多元化發展和擴展的持續關注。',
    
    'Leadership': '領導力',
    'TPG’s leadership team is made up of world-class executives and business leaders who have an average of 27 years of professional experience, including an average tenure at TPG of more than 12 years, and who are supported by a deep bench of talented professionals. We have a substantial global footprint and network for sourcing transactions, raising capital and driving value in our investments.': 'TPG的領導團隊由世界級的高管和商業領袖組成，他們平均擁有27年的專業經驗，包括在TPG的平均任期超過12年，並得到大量才華橫溢的專業人士的支持。 我們擁有龐大的全球足跡和網絡，用於採購交易，籌集資金並推動我們的投資價值。',
    'Arun Agarwal': 'Arun Agarwal',
    'TPG Capital, TPG Growth, TPG Technology Adjacencies': 'TPG Capital, TPG Growth, TPG技術',
    'Americas': '美洲',
    'Bharati Agarwal': 'Bharati Agarwal',
    'Asia': '亞洲',
    'Nitin Agarwal': '尼丁·阿加瓦爾',
    'Dan Allen': '丹·艾倫',
    'Matt Allessio': 'Matt Allessio',
    'Operations, The Rise Funds, TPG Growth': '運營，上升基金，TPG增長',
    'Michael Antilety': '邁克爾·安提萊蒂',
    'Jeff Arvin': '傑夫·阿爾文',
    'Mayank Bajpai': 'Mayank Bajpai',
    'The Rise Funds, TPG Growth': '上升基金，TPG增長',
    'Karthik Balu': 'Karthik Balu',
    'Operations, TPG Capital': '運營，TPG資本',
    'Stephen Bamford': '斯蒂芬·班福德',
    'Client & Capital Formation': '客戶與資本形成',
    'Europe': '歐洲',
    'Avi Banyasz': 'Avi Banyasz',
    'Luke Barrett': '盧克·巴雷特',
    'TPG Holdings': 'TPG控股',
    'Simit Batra': '模擬巴特拉',
    'Celeste Bauby': '塞萊斯特·鮑比',
    'Josh Baumgarten': '喬什·鮑姆加滕',

    'Investor Relations': '投資者關係',
    'Primary Media Contact': '主要媒體聯繫人',
    'Africa': '非洲',
    'Bridget Nurre Jennions': '布里吉特·努爾雷·珍妮絲',
    'Elaine Li': '李依蓮',
    'Australia': '澳大利亞',
    'Jim Kelly': '吉姆·凱利',
    'India': '印度',
    'Snigdha Nair': 'Snigdha Nair',
    'Investor Relations Team': '投資者關係團隊',
    'Investor Portal Login': '投資者門戶登錄',

    'Our Offices': '我們的辦公室',
    'New York': '紐約',
    'TPG': 'TPG',
    '888 7th Avenue': '888第七大道',
    '35th Floor': '35樓',
    'New York, NY 10106': '紐約, NY 10106',
    '245 Park Avenue': '公園大道245號',
    'New York, NY 10167': '紐約, NY 10167',
    'View Map': '查看地圖',
    'San Francisco': '舊金山',
    '345 California Street': '加州街345號',
    'Suite 3300': '套房3300',
    'San Francisco, CA 94104': '舊金山, CA 94104',
    '101 California St, Suite 2820': '101 California St, Suite 2820',
    'San Francisco, CA 94111': '舊金山, CA 94111',
    'Chicago': '芝加哥',
    '111 South Wacker Drive': '111 南瓦克驅動器',
    '36th Flr.': '36th Flr',
    'Chicago, IL 60606': 'Chicago, IL 60606',
    'Fort Worth': '沃思堡',
    '301 Commerce Street': '301商業街',
    'Fort Worth, TX 76102': '沃思堡, TX 76102',
    'Los Angeles': '洛杉磯',
    '2000 Avenue of the Stars,': '2000星光大道',
    'Suite 1020': '套房1020',
    'Los Angeles, CA 90067': '洛杉磯，CA 90067',
    'Washington, D.C.': '華盛頓特區',
    '900 16th Street, N.W.': '900 16th Street, N.W.',
    'Suite 200': '套房200',
    'Washington, D.C. 20006': '華盛頓特區20006',
    'Beijing': '北京',
    'Suite 3801, China World Tower 3': '中國世界3號樓3801套房',
    'No.1, Jianguomenwai Avenue,': '建國門外大街1號',
    'Chaoyang District, Beijing 100004': '北京市朝陽區 100004',
    'Room 906-907A, 9th Floor,': '9樓906-907A室',
    'Building H': '建築H',
    'Phoenix Place, No. A5, Shuguang Xili': '鳳凰廣場，曙光西里甲5號',
    'Beijing, China 100028': '北京, 中國 100028',
    'Hong Kong': '香港',
    'Suite 2807-2808': '套房 2807-2808',
    'Two Exchange Square': '兩個交易廣場',
    '8 Connaught Place': '8康諾特廣場',
    'Unit 2902, 29/F, Prosperity Tower': '『繁榮大廈29樓2902室』',
    '39 Queen’s Road Central': '皇后大道中39號',
    'Central, Hong Kong': '中環，香港',
    'Suite 2806': '套房 2806',
    'Mumbai': 'Mumbai',
    '1004, The Capital': '1004 資本',
    'Plot No. C-70, G-Block': '地塊編號。 C-70, G-Block',
    'Bandra Kurla Complex, Bandra (E)': 'Bandra Kurla Complex, 班德拉(E)',
    'Mumbai 400 051, India': '孟買400 051，印度',
    '904 and 905, C Wing, One BKC, G-Block': '904和905，C翼，一個BKC，G座',
    'Bandra Kurla Complex, Bandra (East)': 'Bandra Kurla Complex, 班德拉(東部)',
    'Mumbai 400051, India': '孟買400051，印度',
    'Seoul': '首爾',
    '20th Floor, Parnas Tower': '帕納斯大廈20樓',
    '521 Teheran-ro, Gangnam-gu': '江南區德黑蘭路521號',
    '06164 Seoul, Korea': '06164 首爾, 韓國',
    '9F, A Tower, K Twin Towers': 'K雙子塔A座9樓',
    '50 Jongno 1-Gil, Jongno-Gu': '50鍾路1街, 鍾路區',
    'Seoul, Korea 03142': '首爾, 韓國 03142',
    'Singapore': '新加坡',
    '83 Clemenceau Avenue': '83 Clemenceau Avenue',
    '11-01 UE Square': '11-01 UE廣場',
    'Singapore 239920': '新加坡239920',
    '1 Raffles Quay, #34-01': '1萊佛士碼頭, #34-01',
    'One Raffles Quay, North Tower': '萊佛士碼頭北塔 One Raffles Quay, North Tower',
    'Singapore 048583': '新加坡048583',
    '8 Marina Boulevard': '8濱海大道',
    'Marina Bay Financial Centre Tower 1': '瑪麗娜灣金融中心1號樓',
    'Level 11': '11級',
    'Singapore 018981': '新加坡018981',
    'Dubai': '迪拜',
    'Gate Building Floor 15 Unit 7': '門樓層 15 單元 7',
    'P.O. Box 121208': 'P.O. Box 121208',
    'DIFC, Dubai': '迪拜DIFC',
    'UAE': '阿聯酋',
    'Shenzhen': '深圳',
    'Unit 601-02, Shenzhen International Qianhai E-metro Tower': '深圳國際前海地鐵大廈601-02室',
    'No. 99 Gang Cheng Street, Nanshan District, Shenzhen,': '深圳市南山區港城街99號',
    'Guangdong, China 518066': '廣東, 中國 518066',
    'Tokyo': '東京',
    'Roppongi Hills Mori Tower 17th Fl.': '六本木Hills森塔17樓',
    '6-10-1, Roppongi': '6-10-1, Roppongi',
    'Minato-ku, Tokyo, 106-6117': '東京都港區，106-6117',
    'Shanghai': '上海',
    'Suite 2032-2035, 20/F': 'Suite 2032-2035, 20/F',
    'HKRI Centre Two': '『香港研究學會第二中心』',
    '288 Shimen First Road': '288 石門一路',
    'Shanghai 200040, China': '上海 200040, 中國',
    'London': '倫敦',
    '5th Floor, Park House': '五樓，公園之家',
    '116 Park Street': '公園街116號',
    'London, W1K 6AF': 'London, W1K 6AF',
    'United Kingdom': '聯合王國',
    '23 Savile Row': '23薩維爾行',
    'London W1S 2ET': 'London W1S 2ET',
    'Amsterdam': '阿姆斯特丹',
    'Vijzelstraat 72 – Floor 7b': 'Vijzelstraat 72 – 樓層 7b',
    '1017 HL Amsterdam': '1017 HL 阿姆斯特丹',
    'The Netherlands': '荷蘭',
    'Copenhagen': '哥本哈根',
    'B.V. Danish Branch': "B.V. ', 英國廣播公司 丹麥分公司",
    'Zieglers Gaard, Nybrogade 12, 1203': '齊格勒斯·加爾德，Nybrogade 12，1203',
    'København, Denmark': 'København, 丹麥',
    'Luxembourg': '盧森堡',
    'The Goldbell Centre': '金鈴中心',
    '5 rue Eugene Ruppert': '5路尤金·魯珀特',
    'L-2453 Luxembourg': 'L-2453 盧森堡',
    'Frankfurt': '法蘭克福',
    'Taunustor 1, Office 18.14': 'Taunustor 1, Office 18.14',
    '60310 Frankfurt am Main': '60310 法蘭克福美因河畔',
    'Germany': '德國',
    'Milan': '米蘭',
    'Via San Raffaele 1': 'Via San Raffaele 1" - 聖拉斐爾1號公路',
    'Milan 20121': '米蘭20121',
    'Italy': '意大利',
    'Melbourne': '墨爾本',
    'Level 38, South Tower': '南樓38層',
    '80 Collins Street': '80柯林斯街',
    'Melbourne, Victoria 3000': '墨爾本, 維多利亞3000',

    'TPG Rise Climate to Acquire Olympus Terminals, Leading Renewable Fuels Logistics Provider': 'TPG Rise Climate收購領先的可再生燃料物流提供商奧林巴斯碼頭',
    '5月.07日.2024': '5月.07日.2024',
    '5月.02日.2024': '5月.02日.2024',
    '5月.01日.2024': '5月.01日.2024',

    'Products': '產品',
    'Explore Capital': '探索資本',
    'Explore Growth': '探索增長',
    'Explore Impact': '探索影響',
    'Explore Market Solutions': '探索市場解決方案',
    'Explore Real Estate': '探索房地產',
    'Explore TPG Angelo Gordon': '探索TPG安傑洛戈登',
    'TPEP': 'TPEP',
    'Private Markets Solutions': '私人市場解決方案',

    'About Us': '關於我們',
    'Who We Are': '我們是誰',

    "INSIGHTS": "洞察力",
    "Share": "分享",
    "Matt Jones, Co-Managing Partner of TPG GP Solutions, shares his thoughts on how the dynamics in the GP-led secondaries market are driving attractive deal flow.": "TPG GP Solutions的聯合管理合夥人Matt Jones分享了他對GP主導的二手市場動態如何推動有吸引力的交易流程的看法。",
    "Learn more about the sectors we’re pursuing firmwide:": "瞭解更多關於我們在全公司範圍內追求的行業",
    "SET (Software & Enterprise Technology)": "SET（軟件與企業技術）",
    "We are pleased to announce that the latest edition of TPG Angelo Gordon’s Capital Markets Perspectives (“CMP”) is now available. This quarterly publication provides information and TPG Angelo Gordon portfolio managers’ views on a range of credit and real estate markets.": "我們很高興地宣佈，TPG Angelo Gordon's Capital Markets Perspectives（\"CMP\"）的最新版本現已上市。 這份季度出版物提供了信息和TPG Angelo Gordon投資組合經理對一系列信貸和房地產市場的看法。",
    "Visit our dedicated CMP microsite to view the most recent edition of the CMP and access an archive of past quarterly publications.": "訪問我們專門的CMP微網站，查看CMP的最新版本，並訪問過去季度出版物的存檔。",
    "CMP microsite": "CMP微網站",
    "When founding TPG Angelo Gordon’s Net Lease Real Estate strategy almost 20 years ago, explaining the niche product to unfamiliar business leaders was a common occurrence for Gordon Whiting. Fast forward to 2024, and sale-leasebacks have become a ubiquitous financing tool for companies, with the associated net lease investment strategy presenting an equally attractive opportunity for investors. Against that backdrop, Whiting recently sat down with PERE to share his views on the increasing opportunity in the net lease space today.": "在近20年前創立TPG Angelo Gordon的淨租賃房地產戰略時，向不熟悉的商業領袖解釋這種利基產品是Gordon Whiting經常發生的事情。 快進到2024年，退租已成爲公司無處不在的融資工具，相關的淨租賃投資戰略爲投資者提供了同樣有吸引力的機會。 在這種情況下，Whiting最近與PERE坐下來分享他對當今淨租賃領域日益增長的機會的看法。",

    "Watch the case study": "观看案例研究",
    "Creating constructive partnerships with public companies": "與上市公司建立建設性夥伴關係",
    "TSCF is currently investing out of a $1.1 billion fund.": "TSCF目前正從一個11億美元的基金進行投資。",
    "TSCF leverages the deep capabilities of the broader TPG platform to help strengthen governance and provide resources for strategic and operational improvement in our portfolio companies.": "TSCF利用更廣泛的TPG平台的深厚能力，幫助加強治理，並為我們的組合公司提供戰略和營運改進的資源。",
    "Strategic secondary investment solutions for private market investors": "為私人市場投資者提供策略性的二手投資解決方案",
    "We established TPG GP Solutions (TGS) in 2021 to address a growing demand for liquidity and financing solutions for general partners that manage private market assets.": "我們在2021年成立了TPG GP Solutions (TGS)，以滿足管理私人市場資產的普通合夥人對流動性和融資解決方案日益增長的需求。",
    "A built-for-purpose specialist focused exclusively on the GP-led market": "專門為目的而建的專家，專注於GP主導的市場",
    "During our more than 30 years of investing, we’ve seen that strong growth in any market leads to the rise of derivative markets—and those derivative markets are often sources of interesting opportunities. Over the past decade, unprecedented growth and innovation in the private equity market has spurred the growth in the secondary private equity market. TGS has created a distinct position within the secondary market, with a strategy focused on investing in high quality assets by partnering with experienced GPs executed by a purpose-built team comprised by experienced secondaries and direct investors.": "在我們超過30年的投資經歷中，我們看到任何市場的強勁增長都會導致衍生市場的興起——這些衍生市場往往是有趣機會的來源。過去十年，私募股權市場的空前增長和創新推動了二手私募股權市場的增長。TGS在二手市場中創造了一個獨特的位置，其策略重點是與有經驗的GP合作，投資高質量資產，由一個由有經驗的二手市場和直接投資者組成的專門團隊執行。",
    "TGS brings a private equity approach to the GP-led secondaries market, leveraging team’s deep investment expertise and the full TPG ecosystem’s sector insights, operating capabilities and geographical reach. TGS partners with GPs to invest in high-quality private equity assets that are primarily based in North America and Europe.": "TGS將私募股權方法應用於GP主導的二手市場，利用團隊深厚的投資專業知識以及完整的TPG生態系統的行業洞察、營運能力和地理覆蓋範圍。TGS與GP合作，投資主要基於北美和歐洲的高質量私募股權資產。",
    "Specialist manager providing strategic solutions to PE owners across Asia Pacific": "為亞太地區的PE所有者提供策略性解決方案的專家管理員",
    "Established in 2011, TPG NewQuest is one of Asia’s leading secondary private equity platforms with the largest, most experienced secondary team in Asia across five offices. Since its founding, TPG NewQuest has focused on working with GPs to create bespoke, tailored solutions to meet liquidity and other strategic needs of private asset owners and their stakeholders. Starting from a strategic partnership forged in 2018, TPG NewQuest became wholly owned by TPG in January 2022. Being part of TPG has further strengthened TPG NewQuest’s homegrown team of 30+ investment professionals’ insight into sector-specific, bottoms-up fundamental underwriting of secondary assets, while bringing the experience of navigating complex secondary transactions to the platform.": "TPG NewQuest成立於2011年，是亞洲領先的二手私募股權平台之一，在亞洲擁有最大、最富經驗的二手團隊，並在五個辦事處運營。自創立以來，TPG NewQuest一直專注於與GP合作，創造定制的、量身定製的解決方案，以滿足私人資產所有者及其利益相關者的流動性和其他策略需求。從2018年建立的戰略合作開始，TPG NewQuest在2022年1月成為TPG的全資擁有。作為TPG的一部分，進一步加強了TPG NewQuest本土團隊30多名投資專業人士對行業特定、自下而上的基礎保險二手資產的洞察力，同時將複雜二手交易的經驗帶到平台。",
    "Direct and sector-centric investment approach": "直接且以行業為中心的投資方法",
    "TPG NewQuest specializes in providing alternative liquidity solutions to private asset owners through transformational GP-led transactions as well as direct secondary transactions. Our direct and sector-centric fundamental investment approach allows us to confidently lead complex bespoke transactions for general partners across the region. Over 12 years, we have established ourselves as a well-capitalized, Asia-focused, built-for-purpose strategic solutions provider for repeat transactions.": "TPG NewQuest專門通過轉型GP主導的交易以及直接二手交易為私人資產所有者提供替代性流動性解決方案。我們直接且以行業為中心的基本投資方法使我們能夠自信地為該地區的普通合夥人引領複雜的定制交易。在過去的12年中，我們已經確立了自己作為資金充足、專注於亞洲、為重複交易而建的策略解決方案提供者的地位。",
    "TPG NewQuest manages a diversified portfolio of private equity investments across the Asia-Pacific Region. These include both direct investments that are held and managed by the TPG NewQuest team as well as indirect exposures to companies through fund investments. Portfolio companies operate in a wide range of sectors, with a focus on five core sectors: business services, consumer, financial services, healthcare, and TMT. Focus sectors align with industry sectors where TPG has expertise.": "TPG NewQuest管理著一個多樣化的亞太地區私募股權投資組合。這些包括由TPG NewQuest團隊持有和管理的直接投資，以及通過基金投資間接接觸公司的投資。組合公司在廣泛的行業運營，重點是五個核心行業：商業服務、消費者、金融服務、醫療保健和TMT。重點行業與TPG擁有專長的行業部門一致。",
    "Buyouts Keynote Interview: The Future Belongs to the Specialists": "收購主題演講訪談：未來屬於專家",

    "28F, IFC Block 3, 10 International Geummyeong-ro, Yeongdeungpo-gu, Seoul (Yoido-dong International Financial Center)": '首爾市永登浦區國際金明路10號IFC 3座28樓（汝矣島洞國際金融中心）',
    "New thinking creates new heights of revenue": "新思維創造收益新高度",
    "After the TPG capture was officially operational, it connected short-term profit realization with long-term value, creating an East Asian investment miracle and providing a path for one million investors to profit. Our latest layout is the TPG Strategic Capital Fund (TSCF), which organically combines short-term revenue with global value to generate new revenue every year. We are widely disseminating this plan.": "TPG captial正式運營後,將短期差價實現和長期價值聯繫起來,創造了東亞投資奇蹟,爲百萬投資者提供了獲利途徑。 我們最新的佈局是TPG戰略資本基金(TSCF),將短期收益和全球價值有機地結合起來,每年創造新的收益。我們正在推廣這個方案。",
    "Innovative investment projects": "創新投資項目入口",
    "No more projects": "暫無更多項目",
    "GPG Capital": "GPG 資本",

    "TPG, where Sanghoon Lee currently works, was one of the first asset managers to establish an Asian franchise.Now that Asia Pacific is a global growth engine, TPG Capital is leveraging our deep expertise and strong regional presence to help nurture emerging leaders in this dynamic market, with a particular focus on areas where technology can drive disruptive growth.": "目前Sanghoon Lee所任職的TPG是最早建立亞洲專營權的資產管理公司之一。如今，亞太地區已成爲全球增長引擎，TPG Capital正在利用我們深厚的專業知識和強大的區域影響力幫助在這個充滿活力的市場中培養新興領導者，特別關注技術可以推動顛覆性增長的領域。",
    "TPG Capital was the first company established within TPG when TPG was founded in 1992.Today, with our capital platform focused on large-scale, control-oriented private equity investments and leveraging our own resource strengths to drive industry growth, we seek cross-regional opportunities and focus on areas where we develop unique expertise over time.": "TPG Capital是TPG於1992年成立時在TPG內部成立的第一家企業。如今，我們的資本平臺主要進行大規模、以控制爲導向的私募股權投資，並利用自身資源優勢帶動行業發展，我們尋求跨地域的機會，並專注於我們隨着時間的推移開發出獨特專業知識的領域。",

}

export {
    Chinese_Taiwan
}