import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import { reactive, ref, Ref } from 'vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import("@/view/home.vue"),
  },
  {
    path: '/approach',
    name: 'approach',
    component: () => import("@/view/approach/index.vue"),
    children: [
      {
        path: '',
        name: 'approach-home',
        component: () => import("@/view/approach/home.vue"),
      },
      {
        path: 'healthcare',
        name: 'approach-healthcare',
        component: () => import("@/view/approach/healthcare.vue"),
      },
      {
        path: 'consumer',
        name: 'approach-consumer',
        component: () => import("@/view/approach/consumer.vue"),
      },
      {
        path: 'businessServices',
        name: 'approach-businessServices',
        component: () => import("@/view/approach/businessServices.vue"),
      },
      {
        path: 'idmc',
        name: 'approach-idmc',
        component: () => import("@/view/approach/idmc.vue"),
      },
      {
        path: 'set',
        name: 'approach-set',
        component: () => import("@/view/approach/set.vue"),
      }
    ]
  },
  {
    path: '/platforms',
    name: 'platforms',
    component: () => import("@/view/platforms/index.vue"),
    redirect: '/platforms/capital',
    children: [
      {
        path: '/platforms/capital',
        name: 'platforms-capital',
        component: () => import("@/view/platforms/capital/index.vue"),
        children: [
          {
            path: '',
            name: 'platforms-capital-home',
            component: () => import("@/view/platforms/capital/home.vue"),
          },
          {
            path: 'tpg-capital',
            name: 'platforms-capital-TPGCapital',
            component: () => import("@/view/platforms/capital/TPGCapital.vue"),
          },
          {
            path: 'tpg-asia',
            name: 'platforms-capital-TPGCapitalAsia',
            component: () => import("@/view/platforms/capital/TPGCapitalAsia.vue"),
          },
          {
            path: 'tpg-healthcare-partners',
            name: 'platforms-capital-TPGHealthcarePartners',
            component: () => import("@/view/platforms/capital/TPGHealthcarePartners.vue"),
          },
        ]
      },

      {
        path: '/platforms/real-estate',
        name: 'platforms-realEstate',
        component: () => import("@/view/platforms/realEstate/index.vue"),
        children: [
          {
            path: '',
            name: 'platforms-realEstate-home',
            component: () => import("@/view/platforms/realEstate/home.vue"),
          },
          {
            path: 'tpg-real-estate-partners',
            name: 'platforms-realEstate-TPGRealEstateFinanceTrust',
            component: () => import("@/view/platforms/realEstate/TPGRealEstateFinanceTrust.vue"),
          },
          {
            path: 'tpg-re-finance-trust',
            name: 'platforms-realEstate-TPGRealEstatePartners',
            component: () => import("@/view/platforms/realEstate/TPGRealEstatePartners.vue"),
          },
          {
            path: 'tpg-real-estate-thematic-advantage-core-plus',
            alias: 'tpg-real-estate-thematic-advantage-core-plus',
            name: 'platforms-realEstate-TPGThematicAdvantageCore-Plus',
            component: () => import("@/view/platforms/realEstate/TPGThematicAdvantageCore-Plus.vue"),
          },
        ]
      },

      {
        path: '/platforms/impact',
        name: 'platforms-impact',
        component: () => import("@/view/platforms/impact/index.vue"),
        children: [
          {
            path: '',
            name: 'platforms-impact-home',
            component: () => import("@/view/platforms/impact/home.vue"),
          }
        ]
      },

      {
        path: '/platforms/tpg-angelo-gordon',
        name: 'platforms-TPGAngeloGordon',
        component: () => import("@/view/platforms/TPGAngeloGordon/index.vue"),
        children: [
          {
            path: '',
            name: 'platforms-TPGAngeloGordon-home',
            component: () => import("@/view/platforms/TPGAngeloGordon/home.vue"),
          },
          {
            path: 'tpg-angelo-gordon-credit-solutions',
            name: 'platforms-TPGAngeloGordon-tpg-angelo-gordon-credit-solutions',
            component: () => import("@/view/platforms/TPGAngeloGordon/TPGAGCreditSolutions.vue"),
          },
          {
            path: 'tpg-angelo-gordon-clos',
            name: 'platforms-TPGAngeloGordon-tpg-angelo-gordon-clos',
            component: () => import("@/view/platforms/TPGAngeloGordon/TPGAGCLOs.vue"),
          },
          {
            path: 'tpg-angelo-gordon-structured-credit-specialty-finance',
            name: 'platforms-TPGAngeloGordon-tpg-angelo-gordon-structured-credit-specialty-finance',
            component: () => import("@/view/platforms/TPGAngeloGordon/TPGAGStructuredCreditSpecialtyFinance.vue"),
          },
          {
            path: 'tpg-angelo-gordon-middle-market-direct-lending',
            name: 'platforms-TPGAngeloGordon-tpg-angelo-gordon-middle-market-direct-lending',
            component: () => import("@/view/platforms/TPGAngeloGordon/TPGAGMiddleMarketDirectLending.vue"),
          },
          {
            path: 'tpg-angelo-gordon-multi-strategy',
            name: 'platforms-TPGAngeloGordon-tpg-angelo-gordon-multi-strategy',
            component: () => import("@/view/platforms/TPGAngeloGordon/TPGAGMulti-Strategy.vue"),
          },
          {
            path: 'tpg-angelo-gordon-global-real-estate',
            name: 'platforms-TPGAngeloGordon-tpg-angelo-gordon-global-real-estate',
            component: () => import("@/view/platforms/TPGAngeloGordon/TPGAGGlobalRealEstate.vue"),
          },
          {
            path: 'tpg-angelo-gordon-net-lease-real-estate',
            name: 'platforms-TPGAngeloGordon-tpg-angelo-gordon-net-lease-real-estate',
            component: () => import("@/view/platforms/TPGAngeloGordon/TPGAGNetLeaseRealEstate.vue"),
          },
        ]
      },


      {
        path: '/platforms/growth',
        name: 'platforms-growth',
        component: () => import("@/view/platforms/growth/index.vue"),
        children: [
          {
            path: '',
            name: 'platforms-growth-home',
            component: () => import("@/view/platforms/growth/home.vue"),
          },
          {
            path: 'TPGGrowth',
            name: 'platforms-growth-TPGGrowth',
            component: () => import("@/view/platforms/growth/TPGGrowth.vue"),
          },
          {
            path: 'TPGDigitalMedia',
            name: 'platforms-growth-TPGDigitalMedia',
            component: () => import("@/view/platforms/growth/TPGDigitalMedia.vue"),
          },
          {
            path: 'TPGTechAdjacencies',
            name: 'platforms-growth-TPGTechAdjacencies',
            component: () => import("@/view/platforms/growth/TPGTechAdjacencies.vue"),
          },
          {
            path: 'TPGLifeSciencesInnovations',
            name: 'platforms-growth-TPGLifeSciencesInnovations',
            component: () => import("@/view/platforms/growth/TPGLifeSciencesInnovations.vue"),
          },
        ]
      },

      {
        path: '/platforms/market-solutions',
        name: 'platforms-market-solutions',
        component: () => import("@/view/platforms/growth/index.vue"),
        children: [
          {
            path: '',
            name: 'platforms-market-solutions-home',
            component: () => import("@/view/platforms/marketSolutions/home.vue"),
          },
          {
            path: 'TPGPublicEquityPartners',
            name: 'platforms-market-solutions-TPGPublicEquityPartners',
            component: () => import("@/view/platforms/marketSolutions/TPGPublicEquityPartners.vue"),
          },
          // {
          //   path: 'TPGPaceGroup',
          //   name: 'platforms-market-solutions-TPGPaceGroup',
          //   component: () => import("@/view/platforms/marketSolutions/TPGPaceGroup.vue"),
          // },
          {
            path: 'TPGStrategicCapitalFund',
            name: 'platforms-market-solutions-TPGStrategicCapitalFund',
            component: () => import("@/view/platforms/marketSolutions/TPGStrategicCapitalFund.vue"),
          },
          {
            path: 'TPGNewQuest',
            name: 'platforms-market-solutions-TPGNewQuest',
            component: () => import("@/view/platforms/marketSolutions/TPGNewQuest.vue"),
          },
          {
            path: 'TPGGPSolutions',
            name: 'platforms-market-solutions-TPGGPSolutions',
            component: () => import("@/view/platforms/marketSolutions/TPGGPSolutions.vue"),
          },
        ]
      }
    ]
  },
  {
    path: '/portfolio',
    name: 'portfolio',
    component: () => import("@/view/portfolio/index.vue"),
  },
  {
    path: '/engagement',
    name: 'engagement',
    component: () => import("@/view/engagement/index.vue"),
    redirect: '/engagement/esg',
    children: [
      {
        path: 'esg',
        name: 'engagement-esg',
        component: () => import("@/view/engagement/esg.vue"),
      },
      {
        path: 'diversity-equity-inclusion',
        name: 'engagement-diversity-equity-inclusion',
        component: () => import("@/view/engagement/diversity-equity-inclusion.vue"),
      }
    ]
  },
  {
    path: '/news-and-insights',
    name: 'news-and-insights',
    component: () => import("@/view/news-and-insights/index.vue"),
    children: [
      {
        path: '',
        name: 'news-and-insights',
        component: () => import("@/view/news-and-insights/home.vue"),
      },
      {
        path: 'insights',
        name: 'news-and-insights-insights',
        component: () => import("@/view/news-and-insights/insights.vue"),
      },
      {
        path: 'news',
        name: 'news-and-insights-news',
        component: () => import("@/view/news-and-insights/news.vue"),
      },
      {
        path: 'newsletter-archive',
        name: 'news-and-insights-newsletter-archive',
        component: () => import("@/view/news-and-insights/newsletter-archive.vue"),
      },

      {
        path: 'attractive-deal-dynamics-in-the-gp-led-secondaries-market',
        name: 'news-and-insights-attractive-deal-dynamics-in-the-gp-led-secondaries-market',
        component: () => import("@/view/news-and-insights/detail/attractive-deal-dynamics-in-the-gp-led-secondaries-market.vue"),
      },
      {
        path: 'tpg-angelo-gordons-q2-2024-capital-markets-perspectives',
        name: 'news-and-insights-tpg-angelo-gordons-q2-2024-capital-markets-perspectives',
        component: () => import("@/view/news-and-insights/detail/tpg-angelo-gordons-q2-2024-capital-markets-perspectives.vue"),
      },
      {
        path: 'increasing-opportunity-in-the-net-lease-space',
        name: 'news-and-insights-increasing-opportunity-in-the-net-lease-space',
        component: () => import("@/view/news-and-insights/detail/increasing-opportunity-in-the-net-lease-space.vue"),
      },
      {
        path: 'capital-and-the-climate-revolution-jim-coulter-on-the-scale-scope-and-complexity-of-climate-investing',
        name: 'news-and-insights-capital-and-the-climate-revolution-jim-coulter-on-the-scale-scope-and-complexity-of-climate-investing',
        component: () => import("@/view/news-and-insights/detail/capital-and-the-climate-revolution-jim-coulter-on-the-scale-scope-and-complexity-of-climate-investing.vue"),
      },
      {
        path: '2024-in-focus-with-ceo-jon-winkelried-macro-credit-and-more',
        name: 'news-and-insights-2024-in-focus-with-ceo-jon-winkelried-macro-credit-and-more',
        component: () => import("@/view/news-and-insights/detail/2024-in-focus-with-ceo-jon-winkelried-macro-credit-and-more.vue"),
      },

      {
        path: 'tpg-rise-climate-to-acquire-olympus-terminals-leading-renewable-fuels-logistics-provider',
        name: 'news-and-insights-tpg-rise-climate-to-acquire-olympus-terminals-leading-renewable-fuels-logistics-provider',
        component: () => import("@/view/news-and-insights/detail/tpg-rise-climate-to-acquire-olympus-terminals-leading-renewable-fuels-logistics-provider.vue"),
      },
      {
        path: 'tpg-reports-first-quarter-2024-results',
        name: 'news-and-insights-tpg-reports-first-quarter-2024-results',
        component: () => import("@/view/news-and-insights/detail/tpg-reports-first-quarter-2024-results.vue"),
      },
      {
        path: 'tpg-raises-nearly-us8-billion-for-asian-private-equity-and-real-estate',
        name: 'news-and-insights-tpg-raises-nearly-us8-billion-for-asian-private-equity-and-real-estate',
        component: () => import("@/view/news-and-insights/detail/tpg-raises-nearly-us8-billion-for-asian-private-equity-and-real-estate.vue"),
      },
      {
        path: 'ik-partners-divests-yellow-hive-to-a-newly-established-continuation-fund',
        name: 'news-and-insights-ik-partners-divests-yellow-hive-to-a-newly-established-continuation-fund',
        component: () => import("@/view/news-and-insights/detail/ik-partners-divests-yellow-hive-to-a-newly-established-continuation-fund.vue"),
      },
      {
        path: 'sayari-closes-235-million-strategic-investment-from-tpg-inclusive-of-additional-capital-for-organic-growth-and-ma',
        name: 'news-and-insights-sayari-closes-235-million-strategic-investment-from-tpg-inclusive-of-additional-capital-for-organic-growth-and-ma',
        component: () => import("@/view/news-and-insights/detail/sayari-closes-235-million-strategic-investment-from-tpg-inclusive-of-additional-capital-for-organic-growth-and-ma.vue"),
      }
    ]
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: () => import("@/view/about/index.vue"),
    children: [
      {
        path: '',
        name: 'about-us-home',
        component: () => import("@/view/about/home.vue"),
      },
      {
        path: 'who-we-are',
        name: 'about-us-who-we-are',
        component: () => import("@/view/about/who-we-are.vue"),
      }
    ]
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import("@/view/contactUs/index.vue"),
  }
]

const router = createRouter({
  history: createWebHistory(),
  // history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // let token = window.localStorage.getItem('token')
  // if(to.meta.needLogin){
  //   if(!token){
  //     next('/')
  //   }
  // }
  return next()
})

export default router
