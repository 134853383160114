const Korean = {
    'Approach': '접근',
    'Our Approach': '우리의 접근법',
    'Healthcare': '헬스케어',
    'SET (Software & Enterprise Tech)': 'SET (소프트웨어 및 엔터프라이즈 테크)',
    'IDMC (Internet, Digital Media & Communications)': 'IDMC (인터넷, 디지털 미디어 & 커뮤니케이션)',
    'Consumer': '소비자',
    'Business Services': '비즈니스 서비스',

    'Platforms': '플랫폼',
    'Capital': '캐피탈', 
'Capital Overview': '캐피탈 개요',
    'TPG Capital': 'TPG캐피탈',
    'TPG Capital Asia': 'TPG캐피탈 아시아',
    'TPG Healthcare Partners': 'TPG 의료 파트너',

    'Growth': '성장',
    'Growth Overview': '성장 개요',
    'TPG Growth': 'TPG 성장',
    'TPG Digital Media': 'TPG 디지털 미디어',
    'TPG Tech Adjacencies': 'TPG 테크 인접',
    'TPG Life Sciences Innovations': 'TPG 생명과학 혁신',

'Impact': '임팩트',
'Impact Overview': '임팩트 개요',
    'The Rise Funds': '더 라이즈 펀드',
    'TPG Rise Climate': 'TPG 상승 기후',
    'Evercare': '에버케어',

    'TPG Angelo Gordon': 'TPG 안젤로 고든',
    'TPG Angelo Gordon Overview': 'TPG 안젤로 고든 개요',
    'TPG AG Credit Solutions': 'TPGAG 크레디트 솔루션',
    'TPG AG CLOs': 'TPG AG  CLOs',
    'TPG AG Structured Credit & Specialty Finance': 'TPG AG 구조화 여신전문금융',
    'TPG AG Middle Market Direct Lending': 'TPG AG중간시장 직접대출',
    'TPG AG Multi-Strategy': 'TPG AG 멀티 스트래티지',
    'TPG AG Global Real Estate': 'TPG AG 글로벌 부동산',
    'TPG AG Net Lease Real Estate': 'TPG AG 순임대 부동산',

    'Real Estate': '부동산',
    'Real Estate Overview': '부동산개요',
    'TPG Real Estate Partners': 'TPG 부동산 파트너',
    'TPG Thematic Advantage Core-Plus': 'TPG 테마 어드밴티지 코어-플러스',
    'TPG Real Estate Finance Trust, Inc.': 'TPG부동산 금융신탁 주식회사',
    'TPG Real Estate Finance Trust': 'TPG부동산 금융신탁',

    
    'Market Solutions': '시장 솔루션',
    'Market Solutions Overview': '시장 솔루션 개요',
    'Capital Markets': '자본시장',
    'TPG Public Equity Partners': 'TPG Public Equity Partners',
    'TPG Pace Group': 'TPG 페이스 그룹',

    'Portfolio': '포트폴리오',

    'Engagement': '참여',
    'ESG': 'ESG',
    'Diversity, Equity, & Inclusion': '다양성, 지분, 포용성',
    
    'News & Insights': '뉴스 & 인사이트',
    'Insights': '인사이트',
    'Press Releases': '보도자료',
    'Newsletters': '뉴스레터',
    'Media Contacts': '미디어 연락처',
    
    'About': '정보',
    'About Us ': '우리에 대하여',
    'Who We Are ': '우리는 누구인가',
    'Contact Us': '연락처',

    'LP Login': 'LP 로그인',
    'Shareholders': '주주',

 '© 2024 Tarrant Capital IP, LLC, All Rights Reserved. TPG, the Half Star logo, and related marks and logos are service marks or registered marks owned by Tarrant Capital IP, LLC.': '© 2024 Tarrant Capital IP, LLC, All Rights Reserved. TPG, Half Star 로고 및 관련 마크 는 Tarrant Capital IP, LLC가 소유하는 서비스 마크 또는 등록 마크입니다.',
   

       'Legal Notices & Terms Of Use': '법적 고지 및 이용약관',
    'Privacy & Cybersecurity': '개인 정보 보호 및 사이버 보안',
    'CCPA Privacy Statement': 'CCPA 개인정보 취급방침',
    'UK/EU Disclosures': '영국/EU 공시',
    'Web Fraud & Phishing': '웹 사기 및 피싱',
    'Transparency in Coverage': '적용범위의 투명성',

    'Meet TPG': 'Meet TPG',
    'Focused on Innovation': '혁신에 중점을 두고',
    'A leader in the alternative asset space, TPG manages $224 billion in assets through a principled focus on innovation.': '대체 자산 분야의 선두주자인 TPG는 혁신에 대한 원칙적인 초점을 통해 2,240억 달러의 자산을 관리합니다.',

    'Overview': '개요',
    'Built for a distinctive approach': '독특한 접근 방식을 위해 구축됨',
    'Through TPG’s family office roots,entrepreneurial heritage, and West Coast base, we have developed a differentiated approach to alternative investments based on innovation, organic growth, and a culture of openness and collaboration. Since our founding in 1992, we have developed a distinct position in the industry, with a broadly diversified set of investment strategies, including private equity, impact, credit, real estate, and market solutions.': 'TPG의 가족 사무실 뿌리, 기업가 정신 유산, 서해안 기반을 통해 혁신, 유기적 성장, 개방과 협업 문화를 바탕으로 한 차별화된 대체 투자 접근 방식을 개발했습니다. 1992년 설립 이후 사모펀드, 임팩트, 신용, 부동산, 시장 솔루션 등 광범위하게 다양한 투자 전략을 통해 업계에서 뚜렷한 입지를 구축했습니다',
    'Our Portfolio Companies': '우리의 포트폴리오 회사',

    'TPG at a Glance': 'TPG를 한눈에',
    '$224B': '2,240억 달러',
    'AUM': 'AUM',
    '1800+': '1800+',
    'Employees around the world': '전 세계 직원',
    '400': '400',
    'Diverse Directors appointed by our portfolio companies since 2017': '2017년 이후 당사 포트폴리오 회사에서 선임한 다양한 이사들',
    '300+': '300+',
    'Active Portfolio Companies Headquartered in more than 30 countries': '30개국 이상에 본사를 둔 액티브 포트폴리오 회사',
    '52': '52',
    'Operations professionals with specialized sector knowledge and experience': '전문 분야의 지식과 경험을 갖춘 운영 전문가',
    '75%': '75%',
    'of TPG’s investors are invested across three or more of our products': 'TPG의 투자자 중 3개 이상의 제품에 투자',

    'What We Do': '우리가 하는 일',
    'Our Platforms': '우리의 플랫폼',
    'TPG puts capital to work through six platforms. Throughout our history, we have grown organically into opportunities we identified through deep thematic work in key sectors and geographies, and through strategic acquisitions to diversify our offerings.': 'TPG는 6개의 플랫폼을 통해 자본을 투입합니다. 역사를 통해 우리는 주요 부문과 지역에 대한 심층적인 주제별 작업과 다양화를 위한 전략적 인수를 통해 확인한 기회로 유기적으로 성장했습니다.',

    '$71B AUM': '710억 AUM',
    'Large-scale control-oriented private equity investing': '대규모 지배구조 중심 사모투자',
    '$27B AUM': '270억 AUM',
    'Growth equity and middle market private equity investing': '성장형 지분 및 중간시장 사모투자',
    '$19B AUM': '190억 AUM',
    'Private equity investing focused on both societal and financial outcomes': '사모투자는 사회적 성과와 재무적 성과에 초점',
    '$80B AUM': '800억 AUM',
    'Global credit and real estate investing platform': '글로벌 신용 및 부동산 투자 플랫폼',
    '$18B AUM': '180억 AUM',
    'Diversified, thematic Real Estate investing platform': '다양한 테마의 부동산 투자 플랫폼',
    '$8B AUM': '80억 AUM',
    'Differentiated strategies built to address specific market opportunities': '특정 시장 기회에 대응하기 위해 구축된 차별화된 전략',

    'TPG News': 'TPG 뉴스',
    'All News': '모든 뉴스',
    'Sector Insights': '섹터 인사이트',
    'Attractive Deal Dynamics in the GP-Led Secondaries Market': 'GP 주도 세컨더리 시장에서의 매력적인 거래 역동성',
    '5月.13日.2024': '5월.13일.2024',
    'Read More': '더 읽기',
    
    'Press Release': '보도자료',
    'TPG Raises Nearly US$8 Billion for Asian Private Equity and Real Estate': 'TPG, 아시아 사모펀드 및 부동산을 위해 거의 80억 달러 조달',
    '5月.08日.2024': '5월.08일.2024',

    'TPG Reports First Quarter 2024 Results': 'TPG 2024년 1분기 실적 발표',

    'A Distinct Style Of Investing': '독특한 투자 스타일',

    'TPG was built with a unique perspective and approach': 'TPG는 독특한 관점과 접근 방식으로 구축되었습니다.',
    'At TPG, we are transformational investors. We aim to drive meaningful long-term value and leave our companies healthier, more strategic, and more growth-oriented than they were when we invested.': 'TPG에서 우리는 혁신적인 투자자입니다. 우리는 의미 있는 장기적 가치를 창출하고 우리 회사를 투자할 때보다 건강하고 전략적이며 성장 지향적으로 만드는 것을 목표로 합니다.',
    'Sector-focused insight shared across platforms': '플랫폼 전반에 걸쳐 공유되는 부문별 통찰력',
    'Our focus on sectors is at the core of our investment strategy. Our investment teams are principally organized around sectors—which means that investors who focus on diverse products and strategies across the firm work together to build deep insight and relationship networks in our key sectors. Sharing ideas and intellectual capital across the firm gives us distinctive insight, and this cross-platform leverage enables us to bring the right capital solutions to compelling opportunities.': '부문에 초점을 맞추는 것이 투자 전략의 핵심입니다. 당사의 투자 팀은 주로 부문을 중심으로 구성되어 있습니다. 즉 회사 전반에 걸쳐 다양한 제품과 전략에 초점을 맞춘 투자자들이 협력하여 주요 부문에 대한 깊은 통찰력과 관계 네트워크를 구축하는 것입니다. 회사 전반에 걸쳐 아이디어와 지적 자본을 공유하면 독특한 통찰력을 얻을 수 있으며 이러한 크로스 플랫폼 활용을 통해 매력적인 기회에 적합한 자본 솔루션을 제공할 수 있습니다.',
    'We call this approach “shared teams and shared themes,” and we build it into our culture by having open investment committees that can be attended by professionals from all platforms, products, and levels of seniority.': '우리는 이 접근 방식을 "공유된 팀과 공유된 주제"라고 부르며 모든 플랫폼, 제품 및 연공서열 수준의 전문가들이 참석할 수 있는 개방형 투자 위원회를 가짐으로써 우리의 문화로 발전시킵니다.',
    'TPG Insights: The AI Revolution': 'TPG 인사이트: AI 혁명',

    'Sector': '섹터',
    'A market-leading, cross-platform approach to complex and impactful sector': '복잡하고 영향력 있는 부문에 대한 시장 선도적인 크로스 플랫폼 접근 방식',
    'The global healthcare market is highly complex, but it is one of the most compelling and impactful sectors we invest in. The healthcare market is constantly evolving in response to the challenges of the global disease burden as well as market forces such as increasing consumerization of healthcare services. At the same time, the discovery and adoption of new technologies are introducing innovation and opportunity into a system that has historically struggled with inefficiency and opacity.': '글로벌 의료 시장은 매우 복잡하지만, 우리가 투자하는 가장 강력하고 영향력 있는 부문 중 하나입니다. 의료 시장은 의료 서비스의 소비자화 증가와 같은 시장의 힘뿐만 아니라 글로벌 질병 부담의 도전에 대응하여 끊임없이 진화하고 있습니다. 동시에 새로운 기술의 발견과 채택은 역사적으로 비효율성과 불투명성으로 어려움을 겪었던 시스템에 혁신과 기회를 도입하고 있습니다.',
    'Longstanding leaders in healthcare investing': '의료 투자 분야의 오랜 리더',
    'In this complex environment, TPG’s focus remains simple—to build and grow companies that are adding value to the healthcare system and enabling better outcomes for patients. Over our 20-year history, we have partnered with founders and management teams to drive enduring growth and create products and services that have delivered breakthrough innovation, reduced costs, and expanded access to high-quality care.': '이러한 복잡한 환경에서 TPG의 초점은 의료 시스템에 가치를 더하고 환자에게 더 나은 결과를 가능하게 하는 회사를 건설하고 성장시키는 것으로 단순하게 남아 있습니다. 20년의 역사 동안, 우리는 지속적인 성장을 주도하고 획기적인 혁신, 비용 절감 및 고품질 의료 서비스에 대한 접근성을 제공하는 제품과 서비스를 만들기 위해 창업자 및 경영진과 파트너 관계를 맺었습니다.',
    'TPG’s global healthcare franchise is led by one of the most experienced teams in private equity. The team invests thematically across strategies:': 'TPG의 글로벌 의료 프랜차이즈는 사모펀드 분야에서 가장 경험이 많은 팀 중 하나가 이끌고 있습니다. 이 팀은 전략 전반에 걸쳐 주제별로 투자합니다.',
    
    'Next-Generation and Value-Centric Care Delivery: Leading care delivery models that are expanding access to underserved populations, reducing healthcare costs through shifts in site of care and introducing patient-centric and risk-bearing solutions. Representative investments include Evolent Health, LifeStance Health, Kelsey-Seybold Clinic, Monogram Health, GoHealth, Zipline, and United Family Healthcare.': '차세대 및 가치 중심 케어 딜리버리: 서비스가 부족한 인구에 대한 접근성을 확대하고 진료 현장의 변화를 통해 의료 비용을 절감하고 환자 중심적이고 위험 부담이 큰 솔루션을 도입하는 선도적인 케어 딜리버리 모델입니다. 대표적인 투자처로는 에볼런트 헬스(Evolent Health), 라이프스탠스 헬스(LifeStance Health), 켈시-시볼드 클리닉(Kelsey-Seybold Clinic), 모노그램 헬스(Monogram Health), 고헬스(GoHealth), 짚라인(Zipline), 유나이티드 패밀리 헬스(United Family Health) 등이 있습니다.',
    'Investing Behind Innovation: Pharmaceuticals, diagnostics and devices that represent step function innovations that transform patient outcomes, along with the essential enablers of such innovation. Representative investments include Allogene, AskBio, BVI, Novotech, and Precision for Medicine.': '혁신 뒤에 투자하기:환자의 성과를 변화시키는 단계 기능 혁신을 대표하는 의약품, 진단 및 장치와 그러한 혁신의 필수적인 요소. 대표적인 투자처로는 Allogene, AskBio, BVI, Novotech, Precision for Medicine 등이 있습니다.',
    'Technology Enablement: Healthcare technology and related solutions that leverage the power of automation, data & analytics, and interoperability to improve care and patient choice in their own health. Representative investments include Dingdang Health, EnvisionRx, IQVIA, Q-Centrix, and WellSky.': '기술 활성화: 자동화, 데이터 및 분석, 상호 운용성의 힘을 활용하여 자신의 건강에 대한 진료와 환자 선택을 개선하는 의료 기술 및 관련 솔루션. 대표적인 투자처로는 딩당헬스, 엔비전알엑스, 아이큐비아, 큐센트릭스, 웰스카이 등이 있습니다.',
    'Investing for Transformation: Proprietary angles created by the TPG Healthcare team’s robust set of strategic and operational capabilities, including our deep industry knowledge and relationships, experience in building out executive teams and strategic roadmaps, and supporting operational resources and capabilities. Representative investments include Adare, Kindred at Home, Kindred Healthcare, Par Pharmaceutical, and Fenwal.': '혁신을 위한 투자:TPG 의료 투자 팀의 강력한 전략적 및 운영 역량(우리의 깊은 산업 지식과 관계, 경영진과 전략적 로드맵 구축 경험, 운영 리소스와 역량 지원 등)에 의해 만들어진 독점적인 각도. 대표적인 투자로는 Adare, Kindred at Home, Kindred Healthcare, Par Pharmaceutical, Fenwal 등이 있습니다.',
    
    'Leaders in Healthcare Investing': '의료 투자 분야의 리더',
    'Learn more about our market-leading healthcare business': '시장을 선도하는 당사의 헬스케어 사업에 대해 자세히 알아보기',
    'Signup for TPG’s LinkedIn Newsletter': 'TPG의 링크드인 뉴스레터 가입',
    
    'Flexible growth capital for next-generation market leaders': '차세대 시장 선도 기업을 위한 유연한 성장 자본',
    'Technology is the backbone of our economy. Tech-enabled disruptors continue to change the way we live and work, while businesses across sectors are facing the need for digital transformation in order to compete and serve the evolving needs of their customers. TPG has been investing in Software & Enterprise Technology for over 20 years, building and supporting companies that transform the way we think about productivity and innovation within organizations.': '기술은 우리 경제의 중추입니다. 기술을 지원하는 장애물은 우리가 살고 일하는 방식을 계속 바꾸고 있으며 여러 부문에 걸친 기업들은 경쟁하고 고객의 진화하는 요구에 부응하기 위해 디지털 전환의 필요성에 직면해 있습니다. TPG는 20년 넘게 소프트웨어 & 엔터프라이즈 테크놀로지에 투자해 왔으며 조직 내 생산성과 혁신에 대한 우리의 사고 방식을 변화시키는 기업을 만들고 지원해 왔습니다.',
    
    'Investing across the technology ecosystem': '기술 생태계 전반에 걸친 투자',
    'TPG’s Software and Enterprise Technology team brings deep sector expertise and operating capabilities to companies across all stages of growth. We have worked with disruptors to build their playbooks, and we know how to transform today’s platforms into next-generation leaders. Our approach prioritizes active and engaged partnership, working with management teams to make their companies stronger and more strategic than they were before our investment.': 'TPG의 소프트웨어 및 엔터프라이즈 기술 팀은 성장의 모든 단계에 걸쳐 기업들에게 깊은 분야의 전문 지식과 운영 능력을 제공합니다. 우리는 방해 요소들과 협력하여 플레이북을 구축했으며 오늘날의 플랫폼을 차세대 리더로 변화시키는 방법을 알고 있습니다. 우리의 접근 방식은 적극적이고 참여적인 파트너십을 우선시하며 경영진 팀과 협력하여 기업을 투자 이전보다 더 강력하고 전략적으로 변화시킵니다.',
    'TPG has partnered with dozens of game-changing companies that are creating new solutions and driving efficiencies in their markets. We invest in Software & Enterprise Technology through multiple strategies across the firm:': 'TPG는 새로운 솔루션을 창출하고 시장의 효율성을 주도하는 수십 개의 판도를 바꾸는 회사들과 파트너 관계를 맺었습니다. 우리는 회사 전반에 걸쳐 다양한 전략을 통해 소프트웨어 & 엔터프라이즈 테크놀로지에 투자합니다.',
    'TPG Capital has made transformative investments to build market leaders including Boomi, CCC Information Services, McAfee, Nintex, Planview, ThycoticCentrify, and Transporeon': 'TPG Capital은 Boomi, CCC Information Services, McAfee, Nintex, Planview, Thycotic Centrify, Transporeon 등 시장 선도 기업을 구축하기 위해 혁신적인 투자를 진행했습니다.',
    'TPG Growth has invested in next-generation businesses including Box, C3.ai, Greenhouse, MX, Onfido,Tanium, and Zscaler': 'TPG Growth는 Box, C3.ai , Greenhouse, MX, Onfido, Tanium, Zscaler 등 차세대 사업에 투자하였습니다.',
    'TPG Technology Adjacencies (TTAD) has invested in innovative software platforms including EIS, FreedomPay, project44, Sauce Labs, Toast, and Zenoti': 'TPG Technology Adjacancies(TTAD)는 EIS, FreedomPay, project44, Sauce Labs, Tast, Zenoti 등 혁신적인 소프트웨어 플랫폼에 투자했습니다.',
    'TPG Digital Media (TDM) partners with businesses including Goal.com and Stack Commerce': 'TPG 디지털 미디어(TDM)와 Goal.com 및 Stack Commerce를 포함한 기업의 제휴',
    'The Rise Fund partners with businesses that use software to enable and amplify social impact, including DreamBox, EverFi, Greenhouse and Renaissance Learning': '라이즈 펀드는 DreamBox, EverFi, Greenhouse 및 Renaissance Learning을 포함하여 소프트웨어를 사용하여 사회적 영향을 활성화하고 증폭하는 기업과 협력합니다.',
    
    'Read TPG’s Software & Enterprise Technology Year-End Recap': 'TPG의 소프트웨어 및 엔터프라이즈 기술 연말 요약 읽기',
    
    'Building companies that are transforming the global economy': '세계 경제를 변화시키고 있는 기업 건설',
    'TPG was founded in 1992, with our first investment operations centered in the San Francisco Bay area. Our firm developed alongside innovative and disruptive companies that are transforming the world’s economy. This history gives us a unique insight and access into the rapidly changing internet, digital media & communications (IDMC) space, and our deep experience in this sector enables us to identify promising companies, entrepreneurs, and ideas and help them grow.': 'TPG는 1992년에 설립되었으며 첫 번째 투자 사업은 샌프란시스코 베이 지역에 집중되어 있습니다. 우리 회사는 세계 경제를 변화시키고 있는 혁신적이고 파괴적인 기업들과 함께 발전했습니다. 이 역사는 우리에게 빠르게 변화하는 인터넷, 디지털 미디어 및 커뮤니케이션 (IDMC) 공간에 대한 독특한 통찰력과 접근성을 제공하며 이 분야에 대한 우리의 깊은 경험은 우리가 유망한 기업, 기업가 및 아이디어를 식별하고 성장하도록 도와줍니다. ',
    
    'Unlocking potential': '잠금 해제 가능성',
    'We invest in market-leading brands with differentiated business models that reflect evolving consumer preferences and have the ability to create network effects. By offering institutional support and global resources, we enable disruptive companies to reach their full potential and unlock greater possibilities.': '우리는 진화하는 소비자 선호도를 반영하고 네트워크 효과를 창출할 수 있는 차별화된 비즈니스 모델을 가진 시장을 선도하는 브랜드에 투자합니다. 우리는 제도적 지원과 글로벌 리소스를 제공하여 파괴적인 기업이 잠재력을 최대한 발휘하고 더 큰 가능성을 열 수 있도록 합니다.',
    'TPG has invested in some of the most influential companies in the IDMC space:': 'TPG는 IDMC 분야에서 가장 영향력 있는 기업 중 일부에 투자했습니다.',
    
    'TPG Capital has partnered with established leaders including Astound Broadband, CAA, and Entertainment Partners': 'TPG 캐피탈은 Astound Broadband, CAA 및 Entertainment Partners를 포함한 기존 리더들과 파트너 관계를 맺었습니다.',
    'TPG Capital Asia has invested in regional leaders including Jio, PropertyGuru, Kakao Mobility, and Wharf T&T': 'TPG 캐피탈 아시아는 Jio, Property Guru, Kakao Mobility, Warf T&T 등 지역 리더들에게 투자하였습니다.',
    'TPG Growth was an early investor in global disruptors including Airbnb, Calm, Spotify, and Uber': 'TPG Growth는 Airbnb, Calm, Spotify 및 Uber를 포함한 글로벌 혼란의 초기 투자자였습니다.',
    'TPG Digital Media has invested in growing platforms including Fandom, Goal.com, and StackCommerce': 'TPG 디지털 미디어는 Fandom, Goal.com 및 StackCommerce를 포함한 성장하는 플랫폼에 투자했습니다.',
    'TTAD has invested in companies enabling the flexible economy and online learning, including Kajabi and Age of Learning': 'TTAD는 Kajabi, Age of Learning 등 유연한 경제 및 온라인 학습을 가능하게 하는 기업에 투자했습니다.',
    'The Rise Fund has invested in growth-stage, mission-driven companies including Acorns, Benevity, and Human Interest': '라이즈 펀드는 에이콘스(Acorns), 베네티(Benetity), 휴먼 인터레스트(Human Interest)를 포함한 성장 단계의 미션 중심 기업에 투자했습니다.',
    
    'Read more about TPG’s investment in DirecTV': 'TPG의 DirectTV 투자에 대한 자세한 내용 보기',
    
    'Growth-focused partnerships with unique consumer companies': '독특한 소비자 기업과의 성장 중심 파트너십',
    'Over our 30-year history, TPG has helped build some of the world’s most iconic consumer brands. Today, TPG’s approach to the consumer sector is focused on finding companies that stand out, with a differentiated position within their category, exceptional consumer loyalty, and significant potential for long-term growth.': '30년의 역사를 통해 TPG는 세계에서 가장 상징적인 소비자 브랜드를 구축하는 데 도움을 주었습니다. 오늘날 TPG의 소비자 부문 접근 방식은 카테고리 내에서 차별화된 위치, 탁월한 소비자 충성도 및 장기적인 성장 가능성이 있는 눈에 띄는 기업을 찾는 데 중점을 두고 있습니다.',
    'A targeted portfolio in high-growth consumer categories': '고성장 소비자 카테고리의 타깃 포트폴리오',
    'We look for distinctive companies in secularly well-positioned categories with ongoing growth opportunities that our capital, network, and resources can help unlock. Our portfolio spans beauty and personal care, food and beverage, services, travel and leisure, e-commerce, dining, education, and fitness and wellness.': '우리는 자본, 네트워크 및 자원이 잠금 해제하는 데 도움이 될 수 있는 지속적인 성장 기회를 가진 특히 입지가 좋은 카테고리의 독특한 회사를 찾습니다. 우리의 포트폴리오는 미용 및 개인 관리, 식음료, 서비스, 여행 및 여가, 전자 상거래, 외식, 교육 및 피트니스 및 웰빙에 걸쳐 있습니다.',
    'TPG has a long history of investing in the consumer sector across multiple strategies:': 'TPG는 여러 전략에 걸쳐 소비자 부문에 투자한 오랜 역사를 가지고 있습니다.',
    'TPG Capital has invested in leading global consumer companies including Chobani, Gelson’s, Life Time, Norwegian Cruise Lines, Petco, Troon, and Viking Cruises': 'TPG캐피탈은 초바니, 겔슨스, 라이프타임, 노르웨이크루즈라인, 펫코, 트룬, 바이킹크루즈 등 유수의 글로벌 소비기업에 투자하였습니다.',
    'TPG Capital Asia has invested in Hong Kong-based jewelry brand APM Monaco and Australian pet care company Greencross': 'TPG 캐피탈 아시아가 홍콩 소재 주얼리 브랜드 APM 모나코와 호주 반려동물 케어 기업 그린크로스에 투자했습니다.',
    'TPG Growth has invested to help scale well-known brands including Beautycounter, Crunch Fitness, e.l.f Cosmetics, ipsy, and SmartSweets': 'TPG Growth는 Beautycounter, Crunch Fitness, e.l.f Cosmetics, ipsy, Smart Sweets 등 유명 브랜드의 확장을 돕기 위해 투자했습니다.',
    
    'Learn more about TPG’s recent investment in Troon': 'TPG의 최근 트루온 투자에 대해 자세히 알아보기',

    'Backing critical service providers in growing industries': '성장하는 산업에서 중요한 서비스 제공업체 지원',
    'The business services sector provides a broad opportunity to deploy TPG’s operational capabilities and growth strategies to service providers across a range of industries. As companies seek to reduce fixed costs and outsource non-core services, we see growing opportunities to support and build companies that provide those services, leveraging TPG’s cross-industry expertise and deep sector insights.': '비즈니스 서비스 부문은 TPG의 운영 능력과 성장 전략을 다양한 산업 분야에 걸쳐 서비스 공급자에게 배포할 수 있는 광범위한 기회를 제공합니다. 기업들이 고정 비용을 줄이고 비핵심 서비스를 아웃소싱하려고 노력함에 따라 TPG의 산업 전반에 걸친 전문 지식과 깊은 부문의 통찰력을 활용하여 이러한 서비스를 제공하는 기업을 지원하고 구축할 수 있는 기회가 늘어나고 있습니다.',
    'Creating competitive advantage through scale': '규모를 통한 경쟁 우위 창출',
    'Our investment experience has demonstrated the importance of scale in the businesses services sector. We focus on companies that provide mission-critical services in growing end-markets in which we can create a stronger competitive advantage through increased scale, driven by both augmented organic growth and market consolidation. Within business services, we focus on key subsectors including professional services, services to the financial sector, data and information services, supply chain and logistics, and industrial services, among others. We look for companies in these subsectors with competitive, resilient business models that serve growing end-markets.': '우리의 투자 경험은 비즈니스 서비스 부문에서 규모의 중요성을 보여주었습니다. 우리는 성장하는 최종 시장에서 미션 크리티컬 서비스를 제공하는 회사에 초점을 맞추고 있으며 이를 통해 유기적 성장과 시장 통합이 강화됨에 따라 확장된 규모를 통해 더 강력한 경쟁 우위를 창출할 수 있습니다. 비즈니스 서비스 내에서 우리는 전문 서비스, 금융 부문에 대한 서비스, 데이터 및 정보 서비스, 공급망 및 물류, 산업 서비스 등을 포함한 주요 하위 부문에 초점을 맞추고 있습니다. 우리는 성장하는 최종 시장에 서비스를 제공하는 경쟁력 있고 탄력적인 비즈니스 모델을 가진 이러한 하위 부문의 회사를 찾습니다.',
    'TPG’s approach to the business services sector spans several strategies:': '비즈니스 서비스 부문에 대한 TPG의 접근 방식은 다음과 같은 몇 가지 전략에 걸쳐 있습니다.',
    'TPG Capital has partnered with services companies that are category-defining and leaders in their respective industries, including Transplace, Cushman & Wakefield, Troon, CAA, Entertainment Partners, CCC, The Warranty Group, LPL Financial, and Fidelity National Information Services': 'TPG Capital은 Transplace, Cushman & Wakefield, Troon, CAA, Entertainment Partners, CCC, The Warranty Group, LPL Financial, Fidelity National Information Services 등 카테고리를 정의하고 각 산업 분야의 선두주자인 서비스 회사들과 파트너십을 체결했습니다.',
    'TPG Growth has invested in innovative growth-stage companies including CLEAResult, People 2.0, Resource Label Group, Keter Environmental Services, Denali Water Solutions, and Tenth Revolution Group': 'TPG Growth는 CLEASult, People 2.0, Resource Label Group, Keter Environmental Services, Denali Water Solutions, Thenth Revolution Group 등 혁신성장 단계 기업에 투자하였습니다.',
    
    'Learn more about TPG’s investment in Transplace': 'TPG의 Transplace 투자에 대해 자세히 알아보기',

    'Platform': '플랫폼',
    'Large-scale global private equity investing platform with a sector-focused, growth-oriented strategy': '부문 중심의 성장 중심 전략을 갖춘 대규모 글로벌 사모펀드 투자 플랫폼',
    
    'Long-term, transformational investing': '장기적, 변혁적 투자',
    'TPG Capital was the first business formed within TPG when the firm was founded in 1992. Today, our Capital platform primarily makes large-scale, control-oriented private equity investments. We pursue opportunities across geographies and specialize in sectors where we have developed unique expertise over time.': 'TPG Capital은 1992년 TPG가 설립되었을 때 TPG 내에서 형성된 첫 번째 사업이었습니다. 오늘날 우리의 Capital 플랫폼은 주로 통제 중심의 대규모 사모 투자를 합니다. 우리는 여러 지역에 걸쳐 기회를 추구하고 시간이 지남에 따라 고유한 전문 지식을 개발한 부문을 전문으로 합니다.',
    '$71B': '710억 달러',
    'In assets under management': '관리대상자산',
    '1992': '1992',
    'Year Founded': '설립연도',
    '$79B': '790억 달러',
    'Invested Since Inception': '인베스트먼트 인셉션 이후',

    'TPG Capital has nearly three decades of experience partnering with leading companies around the world. We bring a disciplined, highly thematic approach to investing that allows us to uncover compelling investment opportunities and drive transformational growth at scale.': 'TPG 캐피탈은 전 세계 유수의 기업들과 거의 30년 동안 파트너 관계를 맺어 왔습니다. 우리는 투자에 대해 규율 있고 매우 주제적인 접근 방식을 취함으로써 매력적인 투자 기회를 발굴하고 대규모의 혁신적인 성장을 이끌어 낼 수 있습니다.',
    '$42.2B': '422억 달러',
    'TPG Asia': 'TPG 아시아',
    'TPG was one of the first alternative asset management firms to establish a dedicated Asia franchise, opening an office in Shanghai in 1994. Today, the Asia Pacific region is the world’s growth engine, and TPG Capital Asia is leveraging our deep expertise and strong regional presence to help build the emerging leaders in this dynamic market, with a particular focus on areas where technology can drive disruptive growth.': 'TPG는 아시아 전용 프랜차이즈를 설립한 최초의 대체 자산운용사 중 하나로 1994년 상하이에 사무실을 열었습니다. 오늘날, 아시아 태평양 지역은 세계의 성장 동력이며 TPG 캐피탈은 당사의 깊은 전문 지식과 강력한 지역적 입지를 활용하여 역동적인 이 시장에서 떠오르는 리더들을 구축하는 데 도움을 주고 있으며 특히 기술이 파괴적인 성장을 주도할 수 있는 분야에 초점을 맞추고 있습니다.',
    '$21.8B': '218억 달러',
    'We launched TPG Healthcare Partners (THP) in 2018 as an extension of our market-leading healthcare franchise in response to significant growth across the US and European healthcare market. We focus on driving growth and expanding capabilities in companies that offer innovative, value-based solutions to current challenges in the healthcare system.': '우리는 2018년 미국과 유럽 헬스케어 시장 전반에 걸쳐 큰 성장에 대응하기 위해 시장을 선도하는 헬스케어 프랜차이즈의 연장선상에서 TPG Healthcare Partners(THP)를 시작했습니다. 우리는 현재 의료 시스템의 과제에 혁신적이고 가치에 기반한 솔루션을 제공하는 회사의 성장을 주도하고 역량을 확장하는 데 중점을 둡니다.',
    '$7.0B': '7억 달러',
    
    'Our other platforms': '기타 플랫폼',

    'Growth-oriented, thematic investing at scale': '성장 지향적, 대규모 테마 투자',
    'Deep sector expertise, differentiated deal types, and value creation through operational improvement': '심층부문 전문성, 차별화된 딜유형, 운영개선을 통한 가치창출',
    'We spend significant time researching and building relationships in our core sectors in order to develop conviction and differentiated insight into the themes and opportunities we pursue. Our patient, long-term orientation and flexible approach enables us to establish creative partnerships that result in stronger and more growth-oriented companies.': '우리는 우리가 추구하는 주제와 기회에 대한 확신과 차별화된 통찰력을 개발하기 위해 핵심 부문의 관계를 조사하고 구축하는 데 상당한 시간을 할애합니다. 인내심 있고 장기적인 지향과 유연한 접근 방식을 통해 창의적인 파트너십을 구축하고 더 강력하고 성장 지향적인 기업을 만들 수 있습니다.',
    'TPG Capital has invested more than $67 billion since 1993 across the economy’s most important sectors: Healthcare, with businesses including Monogram Health, LifeStance Health, and WellSky; Software & Enterprise Technology, with investments including McAfee, Boomi and Wind River; IDMC, with companies such as CAA and DirecTV; Consumer, with companies such as Troon; and Business Services, with investments such as Transplace.': 'TPG Capital은 1993년부터 경제의 가장 중요한 부문에 걸쳐 670억 달러 이상을 투자했습니다. Monogram Health, LifeStance Health, WellSky 등의 기업이 투자한 Healthcare; McAfee, Boomi, Wind River 등의 기업이 투자한 Software & Enterprise Technology; CAA, DirectTV 등의 기업이 투자한 IDMC; Troon 등의 기업이 투자한 Consumer; 그리고 Transplace 등의 기업이 투자한 비즈니스 서비스.',
   
    'TPG Capital Leadership: Why Focus is Key to Effective Investing': 'TPG Capital Leadership: Focus가 효과적인 투자의 핵심인 이유',

    'Select Investments': '투자 선택',

    'Leveraging broad and deep regional expertise to build market leaders in an epicenter of global growth': '글로벌 성장의 진원지에서 시장 리더를 구축하기 위해 광범위하고 깊은 지역 전문 지식을 활용',

    'Geographically distributed, thematically focused teams that drive value through partnership': '지리적으로 분산되어 있고, 주제 중심적으로 파트너십을 통해 가치를 창출하는 팀',
    'TPG’s strong track record in Asia has established us as a value-creating partner with a strong culture of integrity. Our team is distributed across Australia, China, India, Korea, and Southeast Asia, where we build regional platforms by investing through a variety of transaction structures, including partnerships with governments, families, and corporations.': '아시아에서 TPG의 강력한 실적은 우리를 강력한 청렴 문화를 가진 가치 창출 파트너로 자리매김하게 했습니다. 우리 팀은 호주, 중국, 인도, 한국 및 동남아시아에 분포되어 있으며, 정부, 가족 및 기업과의 파트너십을 포함한 다양한 거래 구조를 통해 투자하여 지역 플랫폼을 구축합니다. ',
    'Connectivity with TPG’s global investment ecosystem drives our thematic growth-oriented investment approach in our core sectors, including Consumer, Healthcare, Financial Services, and TMT/New Economy. Since its inception, TPG Asia has invested more than $13 billion and realized more than $16 billion across our core regions and sectors.': 'TPG의 글로벌 투자 생태계와의 연계는 소비자, 의료, 금융 서비스, TMT/New Economy를 포함한 핵심 부문에서 우리의 주제별 성장 지향적 투자 접근 방식을 주도합니다. TPG 아시아는 설립 초기부터 130억 달러 이상을 투자했으며 핵심 지역과 부문에 걸쳐 160억 달러 이상을 실현했습니다.',
    
    'Read more about TPG Capital Asia’s investment in Fractal AI': 'TPG 캐피탈 아시아의 프랙탈 AI 투자에 대해 자세히 알아보기',
    
    'Leaders in dedicated, large-scale healthcare private equity investing': '대규모 의료 사모펀드 투자 전담 리더',
    'TPG Healthcare Partners (THP) was launched in 2018 as an extension of our market-leading healthcare franchise, based on our strong conviction in the opportunity for outsized investment returns in large-scale healthcare buyouts, combined with the lack of scaled private equity funds dedicated to healthcare. We believe the TPG Capital Healthcare team is uniquely positioned to deploy capital in interesting healthcare companies that will build upon the team’s successful track record of investing in healthcare over the last few decades.': 'TPG Healthcare Partners(THP)는 2018년, 대규모 헬스케어 바이아웃에 대한 대규모 투자 수익을 창출할 수 있는 기회에 대한 당사의 강력한 확신과 헬스케어 전용 사모펀드의 부족을 바탕으로 시장을 선도하는 의료 프랜차이즈의 연장선상에서 출범했습니다. 저희는 TPG Capital Healthcare 팀이 지난 수십 년간 팀의 성공적인 의료 투자 실적을 바탕으로 구축할 흥미로운 의료 기업에 자본을 투입할 수 있는 독보적인 위치에 있다고 믿습니다.',
    
    'At $4.5 billion, THP is one of the largest pools of capital dedicated to large-cap healthcare investing': 'THP는 45억 달러 규모로, 대형 의료 투자에 전념하는 가장 큰 자본 풀 중 하나입니다.',
    'THP strives to invest in companies that offer innovative solutions to current challenges in the healthcare system, with a playbook that emphasizes heavy organic investment in R&D, sales and marketing, and accretive business development that can drive substantial, outsized growth.': 'THP는 R&D, 영업 및 마케팅에 대한 막대한 유기적인 투자와 실질적인 대규모 성장을 견인할 수 있는 부가적인 비즈니스 개발을 강조하는 플레이북을 통해 의료 시스템의 현재 과제에 혁신적인 솔루션을 제공하는 기업에 투자하기 위해 노력합니다.',
    'THP investments include next-generation care delivery platforms including LifeStance, Kelsey-Seybold, and Monogram Health; innovative companies transforming patient outcomes such as AskBio; and other market-leading healthcare technology and services companies including WellSky, Convey, and BGB Group.': 'THP 투자에는 LifeStance, Kelsey-Seybold 및 Monogram Health를 포함한 차세대 진료 전달 플랫폼, AskBio와 같은 환자 결과를 혁신하는 혁신적인 기업, WellSky, Convey 및 BGB Group을 포함한 기타 시장을 선도하는 의료 기술 및 서비스 기업이 포함됩니다.',
    'Learn more about TPG Capital’s healthcare investing practice': 'TPG Capital의 의료 투자 실무에 대해 자세히 알아보기',

    'Growth investing with a West Coast perspective and a focus on high-growth sectors': '서해안 관점과 고성장 부문에 중점을 둔 성장 투자',
    'Flexible capital to help growing business scale': '성장하는 비즈니스 규모에 도움이 되는 유연한 자본',
    'TPG’s Growth platform provides us with the ability to invest in companies that are earlier in their lifecycle, are smaller in size, or have profiles that are different from what we’d consider for our Capital platform. Our family of growth funds has developed organically as new areas of opportunity emerge, particularly in technology and media.': 'TPG의 Growth 플랫폼은 라이프사이클의 초기 단계에 있거나 규모가 작거나 Capital 플랫폼에서 고려했던 것과 다른 프로필을 가진 기업에 투자할 수 있는 능력을 제공합니다. 우리의 성장 펀드 제품군은 특히 기술과 미디어 분야에서 새로운 기회 영역이 등장함에 따라 유기적으로 발전했습니다.',
    '$27B': '270억 달러',
    '2007': '2007',
    '$14B': '140억 달러',
    
    'Growth equity is one of the fastest growing private equity strategies in the market today. TPG was early to recognize this opportunity, launching TPG Growth in 2007 to meet the unique needs of earlier-stage companies, from traditional minority growth investing to growth buyouts and specialty capital. TPG’s West Coast roots, value-added operating approach, global reach, and sector depth give us a distinct advantage in growth investing and position us as a valued partner for entrepreneurs.': 'Growth equity은 오늘날 시장에서 가장 빠르게 성장하고 있는 사모펀드 전략 중 하나입니다. TPG는 전통적인 소수 성장 투자에서 성장 인수 및 특수 자본에 이르기까지 초기 단계 기업의 고유한 요구를 충족시키기 위해 2007년 TPG Growth를 출시했습니다. TPG의 서해안 뿌리, 부가 가치 운영 접근 방식, 글로벌 도달 범위 및 부문 깊이는 성장 투자에서 뚜렷한 이점을 제공하고 기업가에게 가치 있는 파트너로 자리매김합니다.',
    '$17.8B': '178억 달러',
    
    'Established in 2017, TPG Digital Media (TDM) is a flexible source of capital created specifically to pursue investment opportunities in digital media. TDM leverages TPG’s long history of studying and pursuing themes around the creation and distribution of content to build market-leading businesses in attractive verticals within the digital media landscape.': '2017년에 설립된 TPG 디지털 미디어(TDM)는 디지털 미디어에 대한 투자 기회를 추구하기 위해 특별히 만들어진 유연한 자본 공급원입니다. TDM은 TPG의 오랜 역사를 활용하여 콘텐츠 제작 및 배포에 관한 주제를 연구하고 추구하여 디지털 미디어 환경 내에서 매력적인 분야에서 시장을 선도하는 비즈니스를 구축합니다.',
    '$1.8B': '18억 달러',

    'TPG Technology Adjacencies': 'TPG 기술 인접',
    'TPG Technology Adjacencies (TTAD) developed organically out of our technology investing practice in 2018. We saw significant value being created in the private markets as tech companies increasingly opted to stay private through more advanced stages of growth. This is a new era in the technology market that requires flexible capital with a full-suite, full-cycle approach to investing, and we created TTAD specifically for this opportunity.': "TPG Technology Adjacancies (TTAD)'는 2018년 우리의 기술 투자 관행에서 벗어나 유기적으로 개발되었습니다. 우리는 기술 기업들이 점점 더 발전된 성장 단계를 통해 비공개를 선택함에 따라 민간 시장에서 상당한 가치가 창출되는 것을 보았습니다. 지금은 투자에 대한 완전한 맞춤형, 전체 주기 접근 방식을 갖춘 유연한 자본을 요구하는 기술 시장의 새로운 시대이며, 우리는 이 기회를 위해 특별히 TTAD를 만들었습니다.",
    '$6.9B': '69억 달러',
    'As the pace of research, development, and innovation in the life sciences sector accelerates, TPG is uniquely positioned to be a capital provider of choice to emerging leaders. TPG Life Sciences Innovations (LSI) is a new strategy that will invest in companies that are developing new therapeutic modalities, biologics, and small molecules, as well as later-stage opportunities in digital health, medical devices, diagnostics, and innovation services.': '생명과학 분야의 연구, 개발, 혁신 속도가 가속화됨에 따라 TPG는 신흥 리더에게 선택의 자본 공급자가 될 수 있는 독특한 위치에 있습니다. TPG 생명과학 혁신(LSI)은 새로운 치료 양식, 바이오로직스, 소분자를 개발하는 기업과 디지털 건강, 의료기기, 진단, 혁신 서비스 등의 후기 단계 기회에 투자할 새로운 전략입니다.',
    '$2.5B': '25억 달러',
    'in historical TPG life sciences investments': '역사적 TPG 생명과학 투자에서',
    
    'Established leaders in growth investing with a distinct position in the market': '시장에서 뚜렷한 위치를 차지하고 있는 성장 투자 분야의 확고한 리더',
    'Growth investing is one of the fastest growing private equity strategies in the market today. TPG was early to recognize this opportunity, launching TPG Growth in 2007 to meet the unique needs of earlier-stage companies, from traditional minority growth investing to growth buyouts and specialty capital. TPG’s West Coast roots, value-added operating approach, global reach, and sector depth give us a distinct advantage in growth investing and position us as a valued partner for entrepreneurs.': 'Growth investing은 오늘날 시장에서 가장 빠르게 성장하고 있는 사모펀드 전략 중 하나입니다. TPG는 전통적인 소수 성장 투자에서 성장 인수 및 특수 자본에 이르기까지 초기 단계 기업의 고유한 요구를 충족시키기 위해 2007년 TPG Growth를 출시하면서 이러한 기회를 일찍 인식했습니다. TPG의 서해안 뿌리, 부가 가치 운영 방식, 글로벌 도달 범위 및 부문 깊이는 성장 투자에서 뚜렷한 이점을 제공하고 기업가에게 가치 있는 파트너로 자리매김합니다.',
    'Leveraging the power of our ecosystem': '우리 생태계의 힘을 활용한다',
    'TPG Growth brings the strength of the firm’s worldwide ecosystem to growth-stage companies. Our global perspective, differentiated thematic insight, and deep sector specialization enables us to be highly opportunistic yet disciplined investors, transferring learnings from developed to developing markets. TPG’s dedicated operations team delivers business-building engagement, deep industry expertise, and creative problem-solving to help our companies grow and scale.': 'TPG Growth는 회사의 전 세계 생태계의 강점을 성장 단계의 기업들에게 제공합니다. 당사의 글로벌 관점, 차별화된 주제 통찰력 및 심층 부문 전문화를 통해 매우 기회주의적이면서도 훈련된 투자자가 될 수 있으며 선진국에서 개발도상국 시장으로 학습을 이전합니다. TPG의 전담 운영 팀은 비즈니스 구축 참여, 심층적인 산업 전문 지식 및 창의적인 문제 해결을 제공하여 회사의 성장과 확장을 돕습니다.',
    'Over 15 years, we’ve built a successful track record by pursuing sectors where we have a differentiated investment approach, including Healthcare investments such as Precision Medicine, Vaxcyte, Medical Solutions, and CTSI; Software & Enterprise Technology investments including MX, Tanium, and Zscaler; Business Services companies such as Denali Water Solutions, Halo Branded Solutions, and Tenth Revolution Group; Consumer companies including Beautycounter, Crunch Fitness, and Fender; and IDMC companies including Uber, Spotify, AirBnB, and Prodigy Education.': '15년간 Precision Medicine, Vaxcyte, Medical Solutions, CTSI 등 의료 투자, MX, Tanium, Zscaler 등 Software & Enterprise Technology 투자, Denali Water Solutions, Halo Branded Solutions, Thenth Revolution Group 등 비즈니스 서비스 기업, Beautycounter, Crunch Fitness, Fender 등 소비자 기업, Uber, Spotify, AirBnB, Prodigy Education 등 IDMC 기업 등 차별화된 투자 접근 방식을 갖춘 분야를 추구하며 성공적인 실적을 쌓아왔습니다.',

    
    "Learn more about TPG Growth's partnership with Beautycounter": "TPG Growth와 뷰티카운터의 파트너십에 대해 자세히 알아보기",
    
    'Specialty capital for opportunities in the rapidly expanding digital media sector': '급증하는 디지털 미디어 분야의 기회를 위한 특별자본',
    'Established in 2018, TPG Digital Media (TDM) is a flexible source of capital created specifically to pursue investment opportunities in digital media. TDM leverages TPG’s long history of studying and pursuing themes around the creation and distribution of content to build market-leading businesses in attractive verticals within the digital media landscape.': '2018년에 설립된 TPG Digital Media(TDM)는 디지털 미디어에 대한 투자 기회를 추구하기 위해 특별히 만들어진 유연한 자본 공급원입니다. TDM은 TPG의 오랜 역사를 활용하여 콘텐츠 제작 및 배포에 관한 주제를 연구하고 추구하여 디지털 미디어 환경 내에서 매력적인 분야에서 시장을 선도하는 비즈니스를 구축합니다.',
    'A comprehensive, transformative approach': '종합적이고 혁신적인 접근법',
    'TDM is focused on making control equity investments through flexible capital structures. We take a long-term approach to building digital media businesses through comprehensive support for operational and financial transformation.': 'TDM은 유연한 자본구조를 통한 지배지분 투자에 중점을 두고 있습니다. 운영 및 재무 전환을 위한 포괄적인 지원을 통해 디지털 미디어 사업을 구축하는 데 장기적인 접근을 취하고 있습니다.',

    'Since inception, TDM has deployed $377 million in companies including Fandom, Goal.com and Stack Commerce.': 'TDM은 시작된 이래 Fandom, Goal.com 및 Stack Commerce를 포함한 회사에 3억 7,700만 달러를 투자했습니다.',
    'Read more about TDM’s investment in Stack Commerce': '스택 커머스에 대한 TDM의 투자에 대해 자세히 알아보기',
    'Flexible, solutions-oriented capital for the evolving technology industry': '진화하는 기술 산업을 위한 유연하고 솔루션 중심의 자본',
    'Dedicated capital for the next generation of tech leaders': '차세대 기술 리더를 위한 전용 자본',
    'TTAD leverages TPG’s deep, longstanding experience investing in tech companies across all stages of growth to make minority investments focused on providing primary capital solutions for companies seeking additional capital for growth as well as creative capital for founders, employees, and early investors looking for liquidity.': 'TTAD는 TPG의 모든 성장 단계에 걸쳐 기술 기업에 투자한 깊고 오랜 경험을 활용하여 성장을 위한 추가 자본을 추구하는 기업에 1차 자본 솔루션을 제공하고 유동성을 추구하는 창업자, 직원 및 초기 투자자에게 창의적인 자본을 제공하는 데 중점을 둔 소수 투자를 합니다.',
    'Powered by TPG’s unique positioning in the tech marketplace, TTAD has deployed more than $1.8 billion across two funds in businesses focused internet, software, digital media, and other technology sectors. Our investments include Age of Learning, C3.ai, Course Hero, Dream 11, EIS Group, FreedomPay, Kajabi, Kaseya, Sauce Labs, Toast, and Zenoti.': 'TTAD는 기술 시장에서 TPG의 독특한 포지셔닝에 힘입어 인터넷, 소프트웨어, 디지털 미디어 및 기타 기술 분야에 중점을 둔 비즈니스 분야의 두 펀드에 18억 달러 이상을 투자했습니다. 우리의 투자에는 에이지 오브 러닝, C3.ai , 코스 히어로, 드림 11, EIS 그룹, 프리덤페이, 카자비, 카세야, 소스 랩스, 토스트 및 제노티가 포함됩니다.',
    
    'Learn more about TPG’s Software & Enterprise Technology investing practice': 'TPG의 소프트웨어 및 엔터프라이즈 기술 투자 실무에 대해 자세히 알아보기',
    
    'Bringing TPG’s scale and expertise to drive Innovation in life sciences': '생명과학의 혁신을 주도하기 위한 TPG의 규모와 전문성 확보',
    'TPG’s Life Sciences Innovations (LSI) fund will invest in novel therapeutics as well as digital health, medical devices, diagnostics, and tech-enabled services, leveraging our core team’s and TPG’s broad expertise in healthcare to focus on supporting companies that are meaningfully improving patients’ outcomes. LSI will invest across therapeutic areas and stages from company creation to IPO. We pride ourselves on being great partners, with a differentiated ability to scale our investment based on companies’ needs.': 'TPG의 LSI(생명과학 혁신) 펀드는 새로운 치료법뿐만 아니라 디지털 건강, 의료기기, 진단 및 기술 지원 서비스에도 투자할 예정이며, 핵심 팀과 TPG의 의료 분야에 대한 광범위한 전문 지식을 활용하여 환자의 결과를 의미 있게 개선하는 기업을 지원하는 데 중점을 둘 것입니다. LSI는 회사 설립부터 IPO에 이르기까지 치료 분야와 단계에 걸쳐 투자할 예정입니다. 우리는 기업의 요구에 따라 투자를 확장할 수 있는 차별화된 능력을 갖춘 훌륭한 파트너가 된 것을 자랑스럽게 생각합니다.',
    
    'Built on a track record of successfully investing in and supporting cutting-edge life science companies': '최첨단 생명과학 기업에 성공적으로 투자하고 지원한 실적을 바탕으로 구축',
    'LSI builds on TPG’s history of successful healthcare investing, including $750 million invested in life sciences in the last three years and $7.6 billion invested in healthcare over the last 10 years.': 'LSI는 지난 3년간 생명과학에 투자한 7억 5천만 달러, 지난 10년간 의료 분야에 투자한 76억 달러 등 TPG의 성공적인 의료 투자 역사를 바탕으로 합니다.',
    'LSI has deep scientific and clinical domain expertise embedded within a market-leading healthcare investment franchise. The team is able to provide customized expertise from an extended network of TPG advisors to support the growth of our portfolio companies. As the pace of research, development, and innovation in the life sciences sector accelerates, LSI is well positioned to support the growth of young companies, from company creation through pre-clinical and clinical development to commercial stages.': 'LSI는 시장을 선도하는 의료 투자 프랜차이즈 내에 깊은 과학 및 임상 영역의 전문 지식을 내장하고 있습니다. 이 팀은 확장된 TPG 어드바이저 네트워크에서 맞춤형 전문 지식을 제공하여 포트폴리오 회사의 성장을 지원할 수 있습니다. 생명과학 분야의 연구, 개발 및 혁신 속도가 가속화됨에 따라 LSI는 회사 생성부터 전임상 및 임상 개발을 거쳐 상업 단계에 이르기까지 젊은 회사의 성장을 지원할 수 있는 좋은 위치에 있습니다.',
    
    'One of the largest private impact investing platforms in the industry with a demonstrated ability to drive competitive returns and meaningful, quantifiable impact': '경쟁적인 수익을 창출할 수 있는 입증된 능력과 의미 있고 정량화 가능한 영향력을 갖춘 업계에서 가장 큰 민간 영향 투자 플랫폼 중 하나',
    'Impact solutions at scale': '규모에 맞는 솔루션 제공',
    'At TPG, we have a fundamental belief that private enterprise has a critical role to play in addressing global societal challenges. Our Impact platform is committed to driving both competitive financial returns and measurable societal benefits at scale. With the launch of The Rise Fund in 2016, we were the first global alternative asset manager to develop an impact investing business with more than $1 billion in assets under management, and today, we have built what we believe is the largest private equity impact investing platform.': 'TPG에서 우리는 민간 기업이 글로벌 사회 문제를 해결하는 데 중요한 역할을 수행해야 한다는 기본 신념을 가지고 있습니다. 당사의 Impact 플랫폼은 경쟁력 있는 재무적 수익과 측정 가능한 사회적 이익을 모두 창출하는 데 전념하고 있습니다. 2016년 The Rise Fund를 출범하면서 글로벌 대체 자산 관리자로는 최초로 10억 달러 이상의 자산을 운용하는 Impact 투자 사업을 개발했으며 오늘날 우리는 최대 규모의 사모펀드 Impact 투자 플랫폼을 구축했습니다.',
    
    '$19B': '190억 달러',
    '2016': '2016',
    '$3B+': '30억 달러+',
    'The Rise Fund': '더 라이즈 펀드',
    'The Rise Funds were founded in 2016 by TPG in partnership with Bono and Jeff Skoll with the goal of putting commercial capital to work toward helping build profitable businesses that deliver positive and sustainable impact. As part of TPG, The Rise Funds offer investment resources, business-building skills, and a global network to help our portfolio companies accelerate growth and impact. The Rise Funds’ core areas of focus include climate and conservation, education, financial inclusion, food and agriculture, healthcare, and impact services.': '더 라이즈 펀드는 긍정적이고 지속 가능한 영향을 제공하는 수익성 있는 비즈니스를 구축하는 데 상업적 자본을 투입하는 것을 목표로 2016년 TPG가 보노 및 제프 스콜과 협력하여 설립했습니다. 더 라이즈 펀드는 TPG의 일환으로 투자 리소스, 비즈니스 구축 기술 및 글로벌 네트워크를 제공하여 포트폴리오 회사가 성장과 영향을 가속화할 수 있도록 지원합니다. 더 라이즈 펀드의 핵심 중점 분야는 기후 및 보존, 교육, 금융 포용, 식품 및 농업, 의료 및 임팩트 서비스입니다.',
    '$8.8B': '88억 달러',
    'Established in 2021, TPG Rise Climate is our dedicated climate impact investing product. The fund was created to address the challenges of global climate change as well as the opportunities to scale innovative businesses that can enable quantifiable carbon aversion. TPG Rise Climate’s core areas of focus include clean energy, enabling solutions, decarbonized transport, greening industrials and agriculture and natural solutions.': '2021년에 설립된 TPG Rise Climate는 당사의 전용 기후 영향 투자 상품입니다. 이 펀드는 정량화 가능한 탄소 회피를 가능하게 하는 혁신적인 사업을 확장할 수 있는 기회뿐만 아니라 글로벌 기후 변화의 과제를 해결하기 위해 만들어졌습니다. TPG Rise Climate의 핵심 중점 분야는 청정 에너지, 솔루션 가능, 탈탄소화 운송, 산업 및 농업 및 자연 솔루션입니다.',
    '$9.5B': '95억 달러',
    'Evercare is an emerging markets healthcare fund dedicated to providing affordable, high-quality care. The fund’s portfolio of 30 hospitals, 16 clinics, 82 diagnostic centers, and two brownfield assets are run through a common operating platform, The Evercare Group, providing an integrated healthcare delivery platform in emerging markets across Africa and South Asia, including India, Pakistan, Bangladesh, Kenya and Nigeria.': "에버케어는 저렴하고 고품질의 케어를 제공하는 데 전념하는 신흥 시장 헬스케어 펀드입니다. 이 펀드의 포트폴리오는 30개 병원, 16개 클리닉, 82개 진단 센터 및 2개 브라운필드 자산으로 구성된 공통 운영 플랫폼인 '에버케어 그룹'을 통해 운영되며 인도, 파키스탄, 방글라데시, 케냐 및 나이지리아를 포함한 아프리카 및 남아시아 신흥 시장에서 통합 의료 제공 플랫폼을 제공합니다.",
    '$395M': '3억 9,500만 달러',
    'The Evercare Group': '에버케어 그룹',
    
    'Global credit and real estate investing platform backed by fundamental research and a commitment to capital protection': '기초연구와 자본보호에 대한 의지가 뒷받침된 글로벌 신용 및 부동산 투자 플랫폼',
    'Investing in inefficient markets to generate consistent returns': '일관성 있는 수익을 창출하기 위해 비효율적인 시장에 투자',
    'Angelo Gordon was acquired by TPG in 2023, becoming a new diversified credit and real estate investing platform within the firm. With nearly 35 years of experience investing across a broad range of credit and real estate strategies, TPG Angelo Gordon seeks alternative investment opportunities that will deliver consistent absolute returns for clients. Our investment approach relies on disciplined portfolio construction backed by rigorous research and a strong focus on capital preservation.': '안젤로 고든은 2023년 TPG에 인수되어 회사 내에서 새로운 다각화된 신용 및 부동산 투자 플랫폼이 되었습니다. 광범위한 신용 및 부동산 전략에 걸쳐 거의 35년 동안 투자한 경험이 있는 TPG 안젤로 고든은 고객에게 일관된 절대 수익을 제공할 대체 투자 기회를 모색합니다. 우리의 투자 접근 방식은 엄격한 연구와 자본 보존에 대한 강력한 초점을 바탕으로 한 엄격한 포트폴리오 구축에 의존합니다.',
    '$80B': '800억 달러',
    'In Assets Under Management*': '관리 중인 자산*',
    '1988': '1988',
    '* TPG Angelo Gordon’s currently stated AUM of approximately $80 billion as of March 31, 2024 reflects fund-level asset-related leverage. Prior to May 15, 2023, TPG Angelo Gordon calculated its AUM as net assets under management excluding leverage, which resulted in TPG Angelo Gordon AUM of approximately $53 billion last reported as of December 31, 2022. The difference reflects a change in TPG Angelo Gordon’s AUM calculation methodology and not any material change to TPG Angelo Gordon’s investment advisory business. For a description of the factors TPG Angelo Gordon considers when calculating AUM, please see the disclosure linked here.': '* 현재 TPG 안젤로 고든의 2024년 3월 31일 기준 약 800억 달러 규모의 AUM은 펀드 수준의 자산 관련 레버리지를 반영한 수치입니다. 2023년 5월 15일 이전 TPG 안젤로 고든은 레버리지를 제외한 운용자산으로 AUM을 산정하여 2022년 12월 31일 기준 TPG 안젤로 고든 AUM 약 530억 달러를 최종 보고하였습니다. 해당 차이는 TPG 안젤로 고든의 AUM 산정 방법론의 변화를 반영한 것이며 TPG 안젤로 고든의 투자자문 사업에 대한 중요한 변화는 없습니다. TPG 안젤로 고든이 AUM 산정 시 고려하는 요소에 대한 내용은 여기에 연결된 공시 내용을 참고하시기 바랍니다.',
    'TPG Angelo Gordon Credit Solutions': 'TPG 안젤로 고든 크레딧 솔루션',
    "TPG Angelo Gordon's Credit Solutions business has a differentiated, all-weather, solutions-based approach that can be executed in any market environment.": "TPG Angelo Gordon의 Credit Solutions 사업은 모든 시장 환경에서 실행할 수 있는 차별화된 전천후 솔루션 기반 접근 방식을 갖추고 있습니다.",
    '$13.4B': '134억 달러',

    'TPG Angelo Gordon CLOs': 'TPG 안젤로 고든 CLOs',
    'TPG Angelo Gordon’s CLO team invests in predominantly non-investment grade credit instruments.': 'TPG 안젤로 고든 CLOs 팀은 주로 비투자 등급 신용상품에 투자합니다.',
    '$8.5B': '85억 달러',
    'TPG Angelo Gordon Structured Credit & Specialty Finance': 'TPG 안젤로 고든 구조화 여신 전문금융',
    'Our global structured credit and specialty finance capabilities allow us to offer a broad-based, fully integrated platform covering public and private opportunities across a wide range of securitized and other asset-based credit.': '당사의 글로벌 구조화된 신용 및 전문 금융 기능을 통해 다양한 증권화된 신용 및 기타 자산 기반 신용에 걸쳐 공공 및 민간 기회를 포괄하는 광범위하고 완전한 통합 플랫폼을 제공할 수 있습니다.',
    '$16.0B': '160억 달러',
    'TPG Angelo Gordon Middle Market Direct Lending': 'TPG 안젤로 고든 중간시장 직접대출',
    'TPG Angelo Gordon’s middle market direct lending team builds on the firm’s long-term history in deep credit underwriting.': 'TPG 안젤로 고든의 중간 시장 직접 대출 팀은 회사의 오랜 역사를 깊은 신용 인수에 기반을 두고 있습니다.',
    '$21.3B': '213억 달러',
    'TPG Angelo Gordon Multi-Strategy': 'TPG 안젤로 고든 멀티 스트래티지',
    'TPG Angelo Gordon’s multi-strategy investment platform channels our extensive analytic capabilities to invest flexibly throughout market cycles.': 'TPG 안젤로 고든의 멀티 전략 투자 플랫폼은 시장 주기 전반에 걸쳐 유연하게 투자할 수 있도록 NAT의 광범위한 분석 기능을 제공합니다.',
    '$2.1B': '21억 달러',
    'TPG Angelo Gordon Global Real Estate': 'TPG 안젤로 고든 글로벌 부동산',
    'TPG Angelo Gordon Global Real Estate acquires underperforming assets in the U.S, Europe, and Asia, where we can employ our value-add skills to improve property performance.': 'TPG 안젤로 고든 글로벌 부동산은 미국, 유럽 및 아시아에서 실적이 저조한 자산을 인수하여 부가가치 기술을 활용하여 부동산 성능을 개선할 수 있습니다.',
    '$16.4B': '164억 달러',
    'TPG Angelo Gordon Net Lease Real Estate': 'TPG 안젤로 고든 넷리스 부동산',
    'TPG Angelo Gordon’s Net Lease business provides real estate sale-leaseback financing to less-than-investment grade owner-occupiers of corporate real estate.': 'TPG 안젤로 고든 순임대 사업은 기업 부동산의 투자 등급 미만 소유자에게 부동산 매각-리스백 금융을 제공합니다.',
    '$2.2B': '22억 달러',
    
    'A differentiated, all-weather, solutions-based approach that can be executed in any market environment': '어떤 시장 환경에서도 실행할 수 있는 차별화된 전천후 솔루션 기반 접근법',
    'We are not reliant on bankruptcies, defaults, restructuring processes, or deep distress to create investment opportunities. Instead, we proactively seek to align ourselves with company management teams, business owners, and private equity sponsors, and we use our structuring creativity and substantial capital base to design bespoke financing transactions. In doing this, we look to strategically drive win-win outcomes by partnering with companies to help resolve complex, idiosyncratic situations.': '우리는 투자 기회를 창출하기 위해 파산, 채무 불이행, 구조조정 과정, 또는 극심한 고통에 의존하지 않습니다. 대신에 우리는 적극적으로 회사 관리 팀, 사업주, 사모펀드 스폰서와 협력할 것을 찾고 우리의 구조화 창의성과 상당한 자본 기반을 맞춤형 자금 조달 거래를 설계하는 데 사용합니다. 이를 통해 우리는 복잡하고 특이한 상황을 해결하는 데 도움이 되는 기업과 협력함으로써 전략적으로 윈윈할 수 있는 결과를 이끌어 낼 수 있기를 기대합니다.',
    'We pursue transactions primarily in North America and Europe, and we have the flexibility to dynamically pivot between the public and private markets to capitalize on what we believe are the most attractive investment opportunities.': '우리는 주로 북미와 유럽에서 거래를 추구하고 있으며 공공 시장과 민간 시장 사이를 역동적으로 전환하여 가장 매력적인 투자 기회라고 생각되는 것을 활용할 수 있는 유연성을 갖추고 있습니다.',
    'With 20+ professionals located across New York and Europe, our investment team leverages their substantial industry expertise along with the full resources of Angelo Gordon’s approximately $61 billion credit platform and the firm’s 30+ years of credit investing experience.': '뉴욕과 유럽 전역에 20명 이상의 전문가를 보유한 당사의 투자팀은 안젤로 고든이 보유한 약 610억 달러 규모의 신용 플랫폼과 회사가 보유한 30년 이상의 신용 투자 경험의 모든 리소스와 함께 상당한 업계 전문 지식을 활용합니다.',
    'Investing predominantly in non-investment grade credit instruments': '비투자등급 신용상품 위주 투자',

    'The team aims to execute an absolute, total return investment strategy that seeks to generate returns from both current income and capital appreciation.': '이 팀은 현재의 수익과 자본의 절상으로부터 수익을 창출하고자 하는 절대적인 총 수익 투자 전략을 실행하는 것을 목표로 합니다. ',
    'With a primary focus on senior secured floating rate loans, the strategy may also opportunistically invest in mezzanine debt, high yield bonds, distressed securities, and other credit related instruments including indexes and/or derivatives. The team actively monitors and trades the portfolio with the goal of improving credit quality and generating incremental return.': '선순위 담보 변동금리 대출에 중점을 둔 전략은 메자닌 부채, 하이일드 채권, 부실 증권 및 인덱스 및/또는 파생상품을 포함한 기타 신용 관련 상품에도 기회주의적으로 투자할 수 있습니다. 팀은 신용 품질을 개선하고 점진적인 수익을 창출하기 위해 포트폴리오를 적극적으로 모니터링하고 거래합니다.',
    'The team has a twenty-year history of managing CLOs via its Northwoods Capital platform and invests through these structured vehicles, co-mingled funds and managed accounts. The strategy has a long bias and utilizes fundamental credit analysis to identify attractive investment opportunities, often finding value in overlooked or misunderstood securities. The team is currently focused on the North American market but also invests outside of North America.': '이 팀은 Northwoods Capital 플랫폼을 통해 CLO를 관리해온 20년의 역사를 가지고 있으며 이러한 구조화된 차량, 통합 펀드 및 관리 계정을 통해 투자합니다. 이 전략은 오랜 편견을 가지고 있으며 기본적인 신용 분석을 활용하여 매력적인 투자 기회를 파악하고 있으며 종종 간과되거나 오해 받는 증권에서 가치를 발견합니다. 이 팀은 현재 북미 시장에 집중하고 있지만 북미 이외의 지역에도 투자합니다.',
    
    'Broad-based, fully integrated strategy covering public and private opportunities across a wide range of securitized and other asset-based credit': '증권화 및 기타 자산 기반 신용에 걸친 광범위한 공공 및 민간 기회를 포괄하는 광범위하고 완전한 통합 전략',
    'Our fundamental research approach, combined with our long-standing presence across numerous sub-asset classes, has resulted in what we believe to be an all-weather business that can identify consistent and attractive risk-adjusted return opportunities through various economic scenarios and market cycles.': '우리의 기본적인 연구 접근 방식은 다양한 하위 자산 계층에 걸쳐 오랜 기간 존재해 온 우리의 경험과 결합하여 다양한 경제 시나리오와 시장 주기를 통해 일관되고 매력적인 위험 조정 수익 기회를 파악할 수 있는 전천후 비즈니스라고 생각하는 것을 도출했습니다.',
    'The strategy has expanded since its inception in 2006 and can take advantage of opportunities across the liquidity spectrum in numerous asset types spanning consumer, residential and commercial real estate and specialty lending markets. Our strategies invest in both securities as well as privately negotiated structured solutions, whole loan purchases, special situations, and direct origination. The platform also includes a residential whole loan business, which is consistently a market leader in the securitization space. This proprietary product provides access to loan level information and market insight that augments constant analysis of empirical market data. ': '이 전략은 2006년 시작된 이래로 확장되어 왔으며 소비자, 주거용 및 상업용 부동산 및 전문 대출 시장에 걸친 다양한 자산 유형에서 유동성 범위에 걸친 기회를 활용할 수 있습니다. 당사의 전략은 증권뿐만 아니라 개인적으로 협상된 구조화된 솔루션, 전체 대출 구매, 특수 상황 및 직접적인 출처에 투자합니다. 이 플랫폼에는 증권화 공간에서 지속적으로 시장을 선도하는 주거용 전체 대출 비즈니스도 포함되어 있습니다. 이 독점 제품은 대출 수준 정보에 대한 액세스와 경험적 시장 데이터의 지속적인 분석을 강화하는 시장 통찰력을 제공합니다.',
    'Our proprietary investment process is driven by three pillars, which have been developed and cultivated by the investment team: Market Access and Intelligence, Fundamental Credit Analysis, and Legal/Structural and Cash Flow Underwriting. Our investment professionals have navigated various market cycles and bring a wide range of experience across all major segments of the securitized and other asset-based credit markets. ': '우리의 독점적 투자 프로세스는 시장 접근 및 인텔리전스, 기본 신용 분석, 법률/구조 및 현금 흐름 인수라는 세 가지 축에 의해 주도됩니다. 우리의 투자 전문가들은 다양한 시장 사이클을 탐색하고 증권화 및 기타 자산 기반 신용 시장의 모든 주요 부문에 걸쳐 광범위한 경험을 제공합니다.',
    
    'Building on the team’s long history in deep credit underwriting': '팀의 오랜 역사를 바탕으로 깊은 신용을 바탕으로',
    'Through our middle market direct lending business, TPG Twin Brook Capital Partners, we provide cash-flow based financing solutions for the middle market private equity community in North America.': '당사의 중간 시장 직접 대출 사업인 TPG Twin Brook Capital Partners를 통해 북미 중간 시장 사모펀드 커뮤니티를 위한 현금 흐름 기반 자금 조달 솔루션을 제공하고 있습니다.',
    'TPG Angelo Gordon’s flexible product suite allows for tailored financing solutions for leveraged buyouts, recapitalizations, add-on acquisitions, growth capital, and other situations for companies that typically have EBITDA between $3 million and $50 million, with a focus on companies with less than $25 million of EBITDA. The group focuses on senior secured loans with a concentration in first liens with a loan-to-value target of 40% to 55%, although opportunistic investments may include second liens, asset-backed securities, unitranche loans, and mezzanine loans, likely in conjunction with providing more senior financing in the same transaction.': '안젤로 고든의 유연한 제품 제품군은 일반적으로 EBITDA가 300만 달러에서 5,000만 달러 사이인 기업에 대해 레버리지 매수, 자본 재조달, 애드온 인수, 성장 자본 및 기타 상황에 대한 맞춤형 금융 솔루션을 제공하며 EBITDA가 2,500만 달러 미만인 기업에 중점을 둡니다. 이 그룹은 기회주의적 투자에는 세컨드 리엔, 자산담보부증권, 유닛란치 대출 및 메자닌 대출이 포함될 수 있지만 동일한 거래에서 더 많은 시니어 금융을 제공하는 것과 관련하여 제2 리엔, 자산담보부증권, 유닛란치 대출 및 메자닌 대출이 포함될 수 있습니다.',
    
    'Channeling our extensive analytic capabilities to invest flexibly throughout market cycles': '시장 주기 전반에 걸쳐 유연하게 투자할 수 있도록 NAT의 광범위한 분석 기능 채널 제공',
    "The platform utilizes TPG Angelo Gordon's significant scale and reach across multiple asset classes and jurisdictions and draws on the highly specialized expertise of our single-strategy credit and arbitrage investment teams to deliver broad sourcing and execution capabilities.  ": "이 플랫폼은 TPG 안젤로 고든(Angelo Gordon)의 상당한 규모와 다양한 자산 계층 및 관할 지역에 걸친 도달 범위를 활용하며 광범위한 소싱 및 실행 기능을 제공하기 위해 단일 전략 신용 및 차익 거래 투자 팀의 고도로 전문화된 전문 지식을 활용합니다. ",
    "Our multi-strategy commingled funds and separate accounts are overseen by a dedicated team and are fundamentally driven by TPG Angelo Gordon's powerful research engine and deeply collaborative investment team culture.": "당사의 다중 전략 통합 펀드와 별도의 계정은 전담 팀이 감독하며 기본적으로 TPG 안젤로 고든의 강력한 리서치 엔진과 매우 협력적인 투자 팀 문화에 의해 주도됩니다.",
    'Drawing on a range of opportunities across the liquidity spectrum, the multi-strategy team dynamically crafts solutions that evolve to match market complexities and act rapidly on emerging themes. Importantly, the multi-strategy portfolios reflect a series of carefully orchestrated bottom-up analyses which combine fundamental valuation, extensive credit work, and process analysis.': '다중 전략 팀은 유동성 스펙트럼에 걸친 다양한 기회를 바탕으로 시장의 복잡성에 맞게 진화하고 새롭게 등장하는 주제에 빠르게 대처하는 솔루션을 역동적으로 만들어냅니다. 중요한 것은 다중 전략 포트폴리오는 기본적인 가치 평가, 광범위한 신용 작업 및 프로세스 분석을 결합한 일련의 신중하게 조정된 상향식 분석을 반영한다는 것입니다.',
    
    'Aiming to create value in everything we buy, and everywhere we buy it': '우리가 구매하는 모든 것과 구매하는 모든 곳에서 가치를 창출하는 것을 목표로 합니다.',
    'TPG Angelo Gordon has been investing in commercial real estate since 1993 and has acquired over $45 billion of properties globally. ': 'TPG 안젤로 고든은 1993년부터 상업용 부동산에 투자해 왔으며 전 세계적으로 450억 달러가 넘는 부동산을 취득했습니다.',
    "We take a value-added approach through the acquisition of sub-performing real estate properties across a broad range of geographic markets and product types. These assets are often attractively priced due to a lack of operating income, asset complexity, and the inefficiency of the sales process.": "우리는 광범위한 지리적 시장과 상품 유형에 걸쳐 하위 성능의 부동산을 취득하는 부가 가치 접근 방식을 취합니다. 이러한 자산은 종종 영업 수익 부족, 자산 복잡성 및 판매 프로세스의 비효율성으로 인해 매력적으로 가격이 책정됩니다.",
    'Our approach to value creation ranges from the less intensive – improving existing operations, leasing, and renovations – to more intensive activity such as major repositioning, change of use, and, occasionally, ground-up development.': '가치 창출에 대한 NAT의 접근 방식은 기존 운영, 임대 및 개조 작업을 개선하는 것과 같은 덜 집중적인 작업에서부터 주요 재배치, 용도 변경, 그리고 때로는 기반 개발과 같은 더 집중적인 작업에 이르기까지 다양합니다.',
    'Our global real estate team’s experience encompasses all aspects of real estate investment and management, including acquisition, financing, leasing, construction management, and disposition. Our hands-on operational real estate expertise has proven critical to our success in acquiring and repositioning assets.': '우리 글로벌 부동산 팀의 경험은 부동산 투자와 관리에 있어서 취득, 자금 조달, 임대, 건설 관리, 처분 등 모든 측면을 아우릅니다. 자산을 취득하고 재배치하는 데 있어 우리의 실무적인 부동산 전문 지식은 매우 중요한 것으로 입증되었습니다.',
    'U.S. Real Estate': '미국 부동산',
    'TPG Angelo Gordon began investing in real estate in the U.S. in 1993 and has acquired over $27 billion of properties in this region.': 'TPG 안젤로 고든은 1993년부터 미국 부동산에 투자하기 시작했으며 이 지역에서 270억 달러가 넘는 부동산을 취득했습니다.',
    'Learn More': '자세히 알아보기',
    'Asia Real Estate': '아시아 부동산',
    'TPG Angelo Gordon has acquired over $12 billion of properties in Asia since it began investing in the region in 2005.': 'TPG 안젤로 고든은 2005년 아시아 지역에 투자를 시작한 이후 120억 달러가 넘는 부동산을 취득했습니다.',
    'Europe Real Estate': '유럽 부동산',
    'TPG Angelo Gordon has been investing in real estate in Europe since 2009 and has acquired over $6 billion of properties in this region.': 'TPG 젤로 고든은 2009년부터 유럽의 부동산에 투자해 왔으며 이 지역에서 60억 달러가 넘는 부동산을 취득했습니다.',
    
    'Providing real estate sale-leaseback financing to less-than-investment grade owner-occupiers of corporate real estate': '법인부동산의 투자등급 미만 소유자에 대한 부동산 매매-임대차 금융 제공',
    'Companies can access 100% of their real estate value while maintaining long-term operational control. The use of proceeds can include:': '기업은 장기적인 운영 통제를 유지하면서 부동산 가치의 100%에 접근할 수 있습니다. 수익금의 용도는 다음과 같습니다. ',
    'Facility expansions': '시설확장',
    'Debt reduction': '채무감축',
    'M&A': 'M&A',
    'Growth/expansion financing': '성장/확장금융',
    'Dividend recapitalizations': '배당재편',
    'Leveraged/management buyouts': '레버리지/경영진 매수',
    'Corporate restructuring/exit financing': '기업구조조정 및 퇴출금융',
    'Synthetic lease conversions': '합성임대전환',
    'In addition to working with privately-held and public large and middle market companies, our team has extensive experience providing sale-leaseback financing to financial sponsors and their portfolio companies.': '우리 팀은 개인 소유 및 공공 대형 및 중간 시장 회사와 협력할 뿐만 아니라 금융 스폰서 및 그 포트폴리오 회사에 판매 임대 자금을 제공하는 광범위한 경험을 가지고 있습니다.',
    'Angelo Gordon, with its significant experience as a principal investor in credit and real estate assets, provides an underwriting platform in the net lease industry, enabling the Net Lease group to execute transactions with a high degree of certainty, timeliness, and sophistication.': '안젤로 고든은 신용 및 부동산 자산의 주요 투자자로서 상당한 경험을 가지고 있으며 넷리스 그룹이 높은 수준의 확실성, 적시성 및 정교함을 가지고 거래를 실행할 수 있도록 하는 인수 플랫폼을 제공합니다.',
    
    'Diversified Real Estate platform with multiple strategies including private equity, core plus, and residential and commercial debt': '사모펀드, 코어플러스, 주상복합 등 다양한 전략을 갖춘 다양한 부동산 플랫폼',
    'Systematic approach to real estate investing': '부동산 투자에 대한 체계적 접근',
    'We established our real estate investing practice to systematically pursue secular growth trends and opportunities borne from dislocation. We seek to utilize a thematic approach to generate attractive returns for our investors throughout cycles. Since inception, our real estate investing efforts have grown substantially, and today, we invest through several distinct strategies.': '우리는 세속적인 성장 추세와 탈구로 인한 기회를 체계적으로 추구하기 위해 부동산 투자 관행을 확립했습니다. 우리는 주제별 접근 방식을 활용하여 주기적으로 투자자들에게 매력적인 수익을 창출하고자 합니다. 창립 이래로 우리의 부동산 투자 노력은 상당히 성장해 왔으며 오늘날 우리는 몇 가지 다른 전략을 통해 투자합니다.',
    '$18B': '180억 달러',
    '2009': '',
    '$6B+': '60+억 달러',
    
    'Established in 2009, TPG Real Estate Partners (TREP) is TPG’s dedicated opportunistic real estate equity investment platform. TREP has built a differentiated investment portfolio comprised primarily of real estate-rich platforms and portfolios located in the United States and Europe.': '2009년에 설립된 TPG Real Estate Partners(TRP)는 TPG의 전용 기회주의적 부동산 지분 투자 플랫폼입니다. TEP는 주로 미국과 유럽에 위치한 부동산이 풍부한 플랫폼과 포트폴리오로 구성된 차별화된 투자 포트폴리오를 구축했습니다.',
    '$11.7B': '117억 달러',
    
    'TPG Real Estate Thematic Advantage Core-Plus': 'TPG 부동산 테마 어드밴티지 코어-플러스',
    'Established in 2021, TPG Real Estate created Thematic Advantage Core-Plus (TAC+) to pursue real estate investments in the US with stabilized tenancy and high potential for enduring cash flow. The TAC+ strategy invests in strategic real estate assets with inherent value and appreciation potential based on location, quality, and tenancy.': 'TPG부동산은 2021년 설립한 TAC+(Thematic Advantage Core-Plus)를 통해 안정적인 입주와 현금 흐름에 대한 지속 가능성이 높은 미국 부동산 투자를 추진하고 있습니다. TAC+ 전략은 입지, 품질, 입주를 기반으로 내재된 가치와 절상 가능성을 지닌 전략적 부동산 자산에 투자합니다.',
    '$1.7B': '17억 달러',
    'TPG established TPG Real Estate Finance Trust, Inc. (NYSE: TRTX) in late 2014 and took the business public in July 2017. TRTX directly originates, acquires, and manages commercial mortgage loans and other commercial real estate-related debt instruments in North America. The TRTX portfolio consists of first mortgage loans (or interests therein) and mezzanine loans, with total commitments of $5.2 billion.': 'TPG는 2014년 말 TPG Real Estate Finance Trust, Inc.(NYSE: TRTX)를 설립하고 2017년 7월에 사업을 공개했습니다. TRTX는 북미 지역에서 상업용 모기지론 및 기타 상업용 부동산 관련 부채 상품을 직접 조달, 인수 및 관리합니다. TRTX 포트폴리오는 퍼스트 모기지론(또는 이에 대한 이자)과 메자닌론으로 구성되며 총 52억 달러의 약정이 있습니다. ',
    '$3.8B': '38억 달러',
    
    'A differentiated approach to opportunistic real estate investing': '기회주의적 부동산 투자에 대한 차별화된 접근',

    'Value creation at the property, portfolio, and platform levels': '부동산, 포트폴리오 및 플랫폼 수준의 가치 창출',
    'The foundation of TREP’s investment strategy is a research and data-driven approach to theme generation, allowing us to be highly selective in identifying the most compelling opportunities in targeted sectors and geographies. During TREP’s ownership, our value creation strategies focus on driving returns across three critical dimensions: optimization of property-level performance, aggregation and curation of strategically aligned portfolios, and enhancement of platform capabilities. We generally pursue these strategies in partnership with dedicated management teams. TREP is committed to responsible investing practices and the deliberate consideration of environmental, social, and governance factors across all stages of the investment process.': 'TREP의 투자 전략의 기반은 연구 및 데이터 중심의 테마 생성 접근 방식이며 이를 통해 대상 부문과 지역에서 가장 매력적인 기회를 식별하는 데 매우 선택적으로 사용할 수 있습니다. TREP가 소유하는 동안 우리의 가치 창출 전략은 부동산 수준의 성능 최적화, 전략적으로 정렬된 포트폴리오의 집계 및 큐레이션, 플랫폼 기능 향상이라는 세 가지 중요한 차원에 걸쳐 수익을 창출하는 데 중점을 둡니다. 우리는 일반적으로 전담 관리 팀과 협력하여 이러한 전략을 추구합니다. TREP는 책임 있는 투자 관행과 투자 프로세스의 모든 단계에 걸쳐 환경, 사회 및 거버넌스 요소를 신중하게 고려하는 데 전념합니다.',
    'Recent investments reflect our current thematic focus areas, including Alloy Properties, a US-based life sciences and innovation portfolio aggregation strategy; Cinespace Studios, a content production studio platform based in the U.S. and Canada; B2R Partners, a US single-family build-to-rent venture; and multiple industrial strategies in both the US and Europe.': '최근 투자는 미국에 기반을 둔 생명과학 및 혁신 포트폴리오 집계 전략인 Alliance Properties, 미국과 캐나다에 기반을 둔 콘텐츠 제작 스튜디오 플랫폼인 Cinespace Studios, 미국의 단일 패밀리 빌드 투 렌트 벤처인 B2R Partners, 그리고 미국과 유럽의 다양한 산업 전략을 포함한 당사의 현재 주제별 초점 영역을 반영합니다.',
    
    'Learn more about TREP’s recent investment in Cinespace Studios': '시네스페이스 스튜디오에 대한 TREP의 최근 투자에 대해 자세히 알아보기',
    
    'Investing thematically in high-quality real estate to drive consistent long-term performance': '지속적인 장기 성과를 견인하기 위해 양질의 부동산에 주제별로 투자',
    'Established in 2021, TPG Real Estate created Thematic Advantage Core-Plus (TAC+) to pursue real estate investments in the US with a low risk profile and high potential for enduring cash flow. The TAC+ strategy invests in strategic real estate assets with inherent stability based on location, quality, and tenancy.': "TPG부동산은 '2021년 설립한 테마어드밴티지코어플러스(TAC+)'를 통해 리스크 프로파일이 낮고 현금흐름을 지속할 수 있는 잠재력이 높은 미국 부동산 투자를 추진하고 있습니다. TAC+ 전략은 입지, 품질, 임차권 등을 기반으로 안정성을 내재한 전략적 부동산 자산에 투자합니다.",
    'Built on a foundation of longstanding real estate investing expertise': '오랜 부동산 투자 전문성을 바탕으로 구축',
    'TAC+ leverages select established real estate investment themes where we have deep sector insights, distinctive experience, and a strong market presence.': "TAC+'는 업계의 깊은 통찰력과 독특한 경험, 그리고 강력한 시장 입지를 확보하고 있는 기존 부동산 투자 테마를 활용합니다.",
    
    'Experience and innovation in commercial real estate financing': '상업용 부동산 금융 경험 및 혁신',
    'TPG established TPG Real Estate Finance Trust, Inc. (NYSE: TRTX) in late 2014 and took the business public in July 2017. TRTX directly originates, acquires, and manages commercial mortgage loans and other commercial real estate-related debt instruments in primary and select secondary markets in North America. The TRTX portfolio consists primarily of first mortgage loans (or interests therein), with total commitments of $5.2 billion.': 'TPG는 2014년 말 TPG Real Estate Finance Trust, Inc.(NYSE: TRTX)를 설립하고 2017년 7월에 사업을 공개했습니다. TRTX는 북미의 1차 및 선별된 2차 시장에서 상업용 모기지론 및 기타 상업용 부동산 관련 부채 상품을 직접 조달, 인수 및 관리합니다. TRTX 포트폴리오는 주로 1차 모기지론(또는 그에 대한 이자)으로 구성되며 총 약속 금액은 52억 달러입니다.',
    
    'Flexible capital solutions for institutional owners of value-add real estate': '부가가치 부동산 기관소유자를 위한 유연한 자본 솔루션',
    'TRTX creates flexible, highly structured financing solutions for property owners with transitional capital needs across a wide spectrum of real estate asset types. Through connectivity with TPG’s global platform, TRTX benefits from the firm’s scope of knowledge, expertise, relationships, and intellectual capital.': 'TRTX는 광범위한 부동산 자산 유형에 걸쳐 과도기적 자본 요구를 가진 부동산 소유자를 위한 유연하고 고도로 구조화된 자금 조달 솔루션을 제공합니다. TPG의 글로벌 플랫폼과의 연결성을 통해 TRTX는 회사의 지식, 전문성, 관계 및 지적 자본 범위에서 이익을 얻습니다.',
    'With decades of experience as lenders, our team has unparalleled market insight and an extensive relationship network. Externally managed by an affiliate of TPG, TRTX aims to provide attractive risk-adjusted returns to its stockholders over time through cash distributions and stock price appreciation.': '수십 년 동안 대부업체로 일한 경험으로 우리 팀은 견줄 데 없는 시장 통찰력과 광범위한 관계망을 보유하고 있습니다. TPG의 계열사가 외부 관리하는 TRTX는 현금 분배와 주가 상승을 통해 주주들에게 시간이 지남에 따라 매력적인 위험 조정 수익을 제공하는 것을 목표로 합니다.',
    
    'To learn more, please visit the TPG RE Finance Trust Website': "자세한 내용은 TPGRE Finance Trust 홈페이지'를 참조하시기 바랍니다.",
    
    'Our Market Solutions platform leverages the full TPG ecosystem to create differentiated products that address specific market opportunities.': '마켓 솔루션 플랫폼은 전체 TPG 생태계를 활용하여 특정 시장 기회를 해결하는 차별화된 제품을 개발합니다. ',
    'Powered by the TPG Ecosystem': 'TPG 생태계에 의해 작동됨',
    'TPG’s Market Solutions platform provides a suite of differentiated products for specific market opportunities across capital markets, public markets investing, SPACs, and private market solutions.': 'TPG의 Market Solutions 플랫폼은 자본 시장, 공공 시장 투자, SPAC 및 민간 시장 솔루션 전반에 걸쳐 특정 시장 기회를 위한 차별화된 제품군을 제공합니다.',
    '$8B': '80억 달러',
    '2006': '2006',
    'Advising across the capital structure in all aspects of debt and equity financings, our capital markets group has been developing bespoke financing solutions while integrating seamlessly with our investment deal teams for over 15 years.': '당사 자본시장 그룹은 부채와 주식금융의 모든 측면에서 자본구조 전반에 걸쳐 조언을 해주며, 15년 넘게 투자 딜 팀과 원활하게 통합하면서 맞춤형 금융 솔루션을 개발해 왔습니다.',
    
    'Debt Capital Markets': '부채 자본시장',
    'TPG’s Debt Capital Markets team acts as an independent, fully functioning broker-dealer, with the ability to place a broad range of financing solutions, not only for our own portfolio, but also for capital providers outside of TPG.': 'TPG의 부채 자본 시장 팀은 자체 포트폴리오뿐만 아니라 TPG 외부의 자본 공급자에게도 광범위한 자금 조달 솔루션을 제공할 수 있는 독립적이고 완벽하게 작동하는 브로커 딜러 역할을 합니다.',
    '$160B': '1,600억 달러',
    'in corporate debt raised across market cap and sectors': '시가총액 및 부문에 걸쳐 조달된 기업부채',
    
    'Equity Capital Markets': '자기자본시장',
    'TPG’s Equity Capital Markets team acts as a lead advisor and underwriter on capital raises and monetization of our ownership stakes in the public equity markets, including initial public offerings, SPACs, follow-on offerings, equity-linked products, and subsequent realizations. We also provide dual-track and structured equity solutions advisory services.': 'TPG의 자기자본시장 팀은 기업공개, SPAC, 후속 공모, 주가연계상품 및 후속 실현을 포함한 공모주 시장에서 당사 소유 지분의 자본 조달 및 수익화에 대한 리드 어드바이저 및 언더라이터 역할을 합니다. 또한 이중 트랙 및 구조화된 자기 자본 솔루션 서비스를 제공합니다.',
    '170+': '170+',
    'Public equity realizations executed since 2014': '2014년 이후 시행한 공모주 현실화',
    
    'Public Markets Investing': '투자하는 공공시장',
    'Drawing on the full intellectual capital and sector-focused resources from our private investment business, TPG manages a number of strategies that invest in public companies—albeit each with a unique approach.': 'TPG는 민간 투자 사업의 전체 지적 자본과 부문에 중점을 둔 자원을 활용하여 공기업에 투자하는 다양한 전략을 관리합니다.',
    'TPG Public Equities (TPEP)': 'TPG 공공 자기 자본 (TPEP)',
    'Established in 2013, TPG Public Equity Partners (TPEP) seeks to generate superior risk-adjusted returns through deep, fundamental private equity-style research in the public markets. TPEP currently manages a long/short fund (approximately $2.6 billion) and a long-only fund (approximately $1.8 billion), both of which are managed with broad, opportunistic mandates that allow TPEP to invest globally across different sectors and market capitalizations.': '2013년에 설립된 TPG공공 자기 자본 파트너스(TPEP)는 공공 시장에서 깊고 근본적인 사모펀드 스타일의 연구를 통해 우수한 위험 조정 수익을 창출하고자 합니다. TPPP는 현재 롱/숏 펀드(약 26억 달러)와 롱 온리 펀드(약 18억 달러)를 운용하고 있으며 이 두 펀드는 TPPP가 다양한 부문과 시가총액에 걸쳐 전 세계적으로 투자할 수 있도록 하는 광범위하고 기회주의적인 의무로 운용되고 있습니다.',
    '$3.3B': '33억 달러',
    'TPG Public Equities': 'TPG공공 자기 자본',
    'TPG Strategic Capital Fund (TSCF)': 'TPG 전략자본펀드(TSCF)',
    'We launched TPG Strategic Capital Fund (TSCF) in 2020 in response to an opportunity we saw to combine the expertise of our private equity business with the firm’s public equity investing capabilities to build long-term equity value in public companies. TSCF makes concentrated investments in public companies where we can work constructively with management and boards to drive meaningful strategic and operational transitions.': '"우리는 2020년에 TPG Strategic Capital Fund (TSCF)를 출시했는데, 이는 사모펀드 사업의 전문성과 회사의 공모주 투자 역량을 결합하여 공기업의 장기적인 지분 가치를 구축할 수 있는 기회를 제공하기 위함입니다. TSCF는 의미 있는 전략적 및 운영적 전환을 추진하기 위해 경영진 및 이사회와 건설적으로 협력할 수 있는 공기업에 집중 투자합니다',
    '$625M': '6억 2,500만 달러',
    'TPG Strategic Capital Fund': 'TPG 전략자본 펀드',
    
    'Special Purpose Acquisition Companies (SPACs)': '특수목적인수회사(SPAC)',
    'Since launching our SPAC sponsorship platform, the Pace Group, in 2015, TPG has raised seven vehicles for over $3 billion in IPO proceeds, while also driving market-leading terms and structures.': 'TPG는 2015년 SPAC 후원 플랫폼인 Pace Group을 출시한 이후 7대의 차량을 IPO 수익금으로 30억 달러 이상 조달함과 동시에 시장을 선도하는 조건과 구조를 주도하고 있습니다.',
    
    'The Pace Group': '더 페이스 그룹',
    "TPG Pace Group is the firm's dedicated permanent capital platform, created in 2015 with the objective of sponsoring SPACs and other permanent capital solutions for companies. TPG Pace Group has a long-term, patient, and highly flexible capital base, allowing us to seek transactions across industries and geographies. The team leverages insights and skillsets from across the firm, including our Capital Markets group, our Operations group, and Y Analytics, to offer a differentiated value proposition to potential targets.": "TPG 페이스 그룹은 기업을 위한 SPAC 및 기타 영구 자본 솔루션을 후원하기 위한 목적으로 2015년에 만들어진 회사의 전용 영구 자본 플랫폼입니다. TPG Pace Group은 장기적이고 인내심이 있으며 매우 유연한 자본 기반을 가지고 있어 산업 및 지역 전반에서 거래를 모색할 수 있습니다. 이 팀은 Capital Markets 그룹, Operations 그룹 및 Y Analytics를 포함한 회사 전반의 통찰력과 기술을 활용하여 잠재적인 목표에 대한 차별화된 가치 제안을 제공합니다.",
    '7': '7',
    'Pace vehicles raised over the last six years': '지난 6년간 페이스 차량',
   
    'Private Market Solutions': '프라이빗 마켓 솔루션',
    'TPG’s private markets solutions business invests in high-quality, stable private equity assets alongside third-party sponsors, typically through continuation vehicles, funds, or third-party investment managers who retain control of the assets. Our private markets solutions business is highly complementary to our existing investment capabilities.': 'TPG의 프라이빗 마켓 솔루션 비즈니스는 일반적으로 자산에 대한 통제권을 유지하는 지속적인 차량, 펀드 또는 서드 파티 투자 관리자를 통해 타사 스폰서와 함께 고품질의 안정적인 사모 자산에 투자합니다. 우리의 프라이빗 마켓 솔루션 비즈니스는 기존의 투자 역량과 매우 보완적입니다.',
    
    'TPG NewQuest': 'TPG NewQuest',
    'Established in 2011, TPG NewQuest is one of Asia’s leading secondary private equity managers, which specializes in providing strategic liquidity solutions to private equity asset owners and has a focused strategy of acquiring secondary private equity positions in companies across the Asia Pacific region. TPG NewQuest is principally focused on bespoke general partner-led secondary transactions, distinguishing it from the majority of secondary firms in the market today by delivering attractive hybrid returns, targeting secondary-type DPIs and primary-style multiples.': '2011년에 설립된 TPG NewQuest는 아시아를 대표하는 세컨더리 사모펀드 매니저 중 하나로 사모펀드 자산 소유자에게 전략적 유동성 솔루션을 전문적으로 제공하고 있으며 아시아 태평양 지역 전역의 기업에서 세컨더리 사모펀드 포지션을 획득하는 데 중점을 두고 있습니다. TPG NewQuest는 주로 맞춤형 파트너 주도의 세컨더리 거래에 중점을 두고 있으며 세컨더리 타입의 DPI와 프라이머리 스타일의 배수를 대상으로 매력적인 하이브리드 수익을 제공함으로써 오늘날 시장의 대다수 세컨더리 기업과 차별화됩니다.',
    '30+': '30+',
    'Investment Professionals across five offices in Asia': '아시아 5개 지사에 걸친 투자 전문가',
    
    'TPG GP Solutions': 'TPG GP 솔루션',
    'Established in 2021, TPG GP Solutions (TGS) invests in high-quality private equity assets that are primarily based in North America and Europe, in partnership with third-party general partners. TGS brings a private equity approach to the general partner-led secondaries market and leverages the insights of the broader TPG ecosystem in addition to the TGS team’s deep investing experience.': '2021년 설립된 TPG GP Solutions(TGS)는 제3자 일반 파트너와 협력하여 북미와 유럽에 주로 기반을 둔 우량 사모펀드 자산에 투자합니다.',
    '9': '9',
    'Experienced direct and secondaries investors across North America and Europe': '북미 및 유럽 전역의 경험이 풍부한 직·간접 투자자',
    
    'A private equity approach to public equity investing': '공모투자에 대한 사모투자방식',
    'Established in 2013, TPG Public Equity Partners (TPEP) seeks to generate superior risk-adjusted returns through deep, fundamental private equity-style research in the public markets. TPEP currently manages a long/short fund (approximately $2.2 billion) and a long-only fund (approximately $1.7 billion), both of which are managed with broad, opportunistic mandates that allow TPEP to invest globally across different sectors and market capitalizations.': '2013년에 설립된 TPG Public Equity Partners(TPEP)는 공공 시장에서 깊고 근본적인 사모펀드 스타일의 연구를 통해 우수한 위험 조정 수익을 창출하고자 합니다. TPPP는 현재 롱/숏 펀드(약 22억 달러)와 롱 온리 펀드(약 17억 달러)를 운용하고 있으며, 이 두 펀드는 TPPP가 다양한 부문과 시가총액에 걸쳐 전 세계적으로 투자할 수 있도록 하는 광범위하고 기회주의적인 의무로 운용되고 있습니다.',
    
    'Fully integrated within TPG': 'TPG 내 완전 통합',
    'A key aspect of TPEP’s approach is that it is integrated with our private investment business, allowing TPEP to collaborate with sector-focused teams across TPG and leverage the intellectual capital and resources of the entire firm.': 'TPEP 접근 방식의 핵심은 민간 투자 비즈니스와 통합되어 TPPP가 TPG 전반의 부문 중심 팀과 협력하고 기업 전체의 지적 자본과 리소스를 활용할 수 있다는 점입니다.',
   
    'Select Investments.': '투자 선택',
    
    'Diversity, Equity, and Inclusion': '다양성, 형평성, 포용성',
    'We believe that the quality of our investments and our ability to build great companies depend on the originality of our insights': '우리는 투자의 질과 훌륭한 회사를 구축하는 능력이 통찰력의 독창성에 달려 있다고 믿습니다.',
    'Promoting diversity, equity and inclusion is a core value at TPG, embedded into the highest levels of our firm and guided by our Diversity, Equity & Inclusion Council. Created in 2015, the DE&I Council is a 14-member partner steering committee led by Firm Partner Maryanne Hancock and President Todd Sisitsky. It is supported by three advisory groups focused on recruiting and engagement with our external ecosystem and internal teams.': '다양성, 형평성 및 포용성 증진은 당사의 다양성, 형평성 및 포용성 위원회(Diversity, Equity & Inclusion Council)가 이끄는 TPG의 핵심 가치입니다. 2015년에 설립된 DE&I 위원회는 Firm Partner Maryanne Hancock과 Todd Sisitsky 사장이 이끄는 14명의 파트너 운영 위원회입니다. 외부 생태계 및 내부 팀을 모집하고 참여하는 데 중점을 둔 세 개의 자문 그룹이 지원합니다.',
    'Board Diversity Initiative': '이사회 다양성 이니셔티브',
    'We have advanced board diversity on our portfolio companies’ boards of directors in terms of gender, race, ethnicity, and sexual orientation. As of June 2023, more than 80% of our portfolio companies’ US-based boards have both gender and racial/ethnic diversity, and our companies have appointed more than 400 diverse directors since 2017. To broaden our executive network and influence our larger ecosystem, we have worked with leading diverse director organizations—such as the Thirty Percent Coalition, Women Corporate Directors, Him for Her, Ascend-Pinnacle, the Latino Corporate Directors Association, The Alumni Society, Board Diversity Action Alliance, Black Women on Boards, The Boule Foundation, LGBTQ+ Corporate Directors, and Stanford Women on Boards—as collaborators, speakers, and often sponsors.': '우리는 성별, 인종, 민족성 및 성적 지향성 측면에서 포트폴리오 회사의 이사회에서 다양성을 발전시켰습니다. 2023년 6월 현재, 포트폴리오 회사의 미국 기반 이사회 중 80% 이상이 성별과 인종/민족 다양성을 모두 가지고 있으며 우리 회사는 2017년부터 400명 이상의 다양한 이사를 선임했습니다. 임원 네트워크를 넓히고 더 큰 생태계에 영향을 미치기 위해 우리는 30퍼센트 연합, 여성 기업 이사회, Him for Her, Ascend-Pinnacle, 라틴 기업 이사회, 동문회, 이사회 다양성 행동 연합, 이사회 흑인 여성, Boule 재단, LGBTQ+ 기업 이사회 및 스탠포드 여성 이사회와 같은 다양한 이사 조직과 협력하고 있습니다.',
    
    'Investing in Diverse-led Investment Managers': '다양한 주도의 투자 담당자에 대한 투자',
    'In 2019, TPG launched the TPG NEXT initiative, which develops emerging and diverse alternative asset managers. TPG NEXT launched with an investment in Harlem Capital Partners, a venture capital firm focused on investing in companies with diverse founders. TPG NEXT has since made investments in LandSpire Group, a real estate investment fund aiming to provide under-resourced communities with the necessary infrastructure to create equitable growth and a sustainable ecosystem, and VamosVentures, an impact venture capital fund working to deliver meaningful impact through wealth creation, social mobility, unique tech solutions, and taking a visible stand as diverse investors.': '2019년 TPG는 신흥 및 다양한 대체 자산 관리자를 개발하는 TPG NEXT 이니셔티브를 시작했습니다. TPG NEXT는 다양한 설립자를 가진 회사에 투자하는 것에 중점을 둔 벤처 캐피털 회사인 할렘 캐피털 파트너스(Harlem Capital Partners)에 대한 투자로 시작되었습니다. TPG NEXT는 이후 자원이 부족한 지역 사회에 공평한 성장과 지속 가능한 생태계를 조성하는 데 필요한 인프라를 제공하는 것을 목표로 하는 부동산 투자 펀드인 랜드스파이어 그룹(LandSpire Group)과 부의 창출, 사회 이동성, 독특한 기술 솔루션 및 다양한 투자자로서의 가시적인 지위를 제공하기 위해 일하는 임팩트 벤처 캐피털 펀드인 바모스 벤처스(VamosVentures)에 투자했습니다. ',
    
    'TPG engages on public issues to advance equality, such as immigration, gender and racial discrimination, women’s issues and access to education. For example, in 2017, TPG authored guidance for our portfolio companies encouraging preservation of Deferred Action for Childhood Arrivals (DACA) and providing support and financial resources to those impacted. In 2018, TPG joined the Business Coalition for the Equality Act. In 2019, TPG was the only private equity firm to sign an amicus brief to the U.S. Supreme Court in support of non-discrimination protections for LGBTQ+ people in federal civil rights law.': 'TPG는 이민, 성 및 인종 차별, 여성 문제 및 교육 접근과 같은 평등을 증진하기 위한 공공 문제에 관여합니다. 예를 들어 2017년 TPG는 포트폴리오 회사를 위한 지침을 작성하여 아동 도착 유예 조치(DACA)를 유지하고 영향을 받는 사람들에게 지원 및 재정 자원을 제공하도록 장려했습니다. 2018년 TPG는 평등법을 위한 비즈니스 연합에 가입했습니다. 2019년 TPG는 연방 민권법에서 LGBTQ+ 사람들에 대한 비차별적 보호를 지원하기 위해 미국 대법원에 아미쿠스 브리핑을 체결한 유일한 사모펀드 회사였습니다. ',
    
    'TPG has a longstanding commitment to fostering strong ESG performance as a firm and in our investment practices': 'TPG는 기업으로서 그리고 우리의 투자 관행에서 강력한 ESG 성과를 육성하기 위한 오랜 헌신을 가지고 있습니다.',
    'TPG first adopted a Global ESG Performance Policy in 2012 and became a signatory to the UN Principles of Responsible Investment in 2013. Each year, we continue to strengthen and deepen the integration of ESG performance throughout the firm.': 'TPG는 2012년 처음으로 글로벌 ESG 성과 정책을 채택하였으며 2013년 UN 책임 투자 원칙의 서명국이 되었습니다. 매년 전사적으로 ESG 성과의 통합을 지속적으로 강화하고 심화하고 있습니다.',
    '2023 Annual ESG Review': '2023년 ESG 연차검토',
    "TPG Inc.'s ESG Policy": "TPG Inc. ESG 정책",
    
    'Y Analytics': 'Y Analytics',
    'Y Analytics, a public benefit organization that is wholly owned by TPG, operates the firm’s ESG performance arm. Y Analytics’ mission is to increase not only the amount of capital that is allocated toward impact, but also to improve the effectiveness of capital invested toward the greater good. TPG’s ESG Strategy Council unites the leadership of Y Analytics with the leaders of multiple functions that touch ESG issues, including Legal, Compliance, Human Resources & Human Capital and Operations. Together, the ESG Strategy Council and Y Analytics provide leadership and support to our investment professionals on ESG topics throughout the lifecycle of our investments.': "TPG가 100% 지분을 보유한 공익 단체인 'Y Analytics'는 회사의 ESG 성과 부문을 운영합니다. Y Analytics의 사명은 영향을 위해 할당되는 자본의 양을 늘릴 뿐만 아니라 더 큰 선을 향해 투자되는 자본의 효율성을 향상시키는 것입니다. TPG의 ESG 전략 위원회는 법률, 규정 준수, 인적 자본 및 인적 자본 및 운영 등 ESG 문제를 다루는 다양한 기능의 리더들과 Y Analytics의 리더십을 결합합니다. ESG 전략 위원회와 Y Analytics는 함께 투자 라이프사이클 전반에 걸쳐 ESG 주제에 대한 리더십과 지원을 투자 전문가에게 제공합니다.",
    
    'Environmental Impact': '환경영향',
    "TPG is also focused on the firm's own environmental impact, including our firm-wide operational and financed emissions as well as risks in our portfolio. We have analyzed and verified our firm-wide operational emissions since 2019, and regularly engage with our invested companies on financially material climate and environmental priorities.": "TPG는 또한 포트폴리오의 위험뿐만 아니라 전사적인 운영 및 자금 배출을 포함하여 회사 자체의 환경 영향에 초점을 맞추고 있습니다. 저희는 2019년부터 전사적인 운영 배출을 분석하고 검증했으며 재정적으로 중요한 기후 및 환경 우선 순위에 대해 투자한 회사와 정기적으로 협력하고 있습니다.",
    
    "TPG's Partnership with Ownership Works": "TPG의 소유권 사업과의 파트너십",
    'TPG is proud to be a founding partner of Ownership Works, a nonprofit that launched in April 2022 with a mission to increase prosperity through shared ownership at work. With the support of over 60 partners across the private, public and nonprofit sectors, Ownership Works will develop and help implement broad-based employee ownership programs to create better work environments and financial opportunities for employees, and to help businesses improve their performance by attracting and retaining engaged employees who are invested in their company’s success.': 'TPG는 2022년 4월 직장에서의 공유 소유를 통해 번영을 늘리겠다는 사명으로 시작한 비영리 단체인 Ownership Works의 창립 파트너가 된 것을 자랑스럽게 생각합니다. Ownership Works는 민간, 공공 및 비영리 부문에 걸쳐 60개 이상의 파트너의 지원을 받아 광범위한 직원 소유 프로그램을 개발하고 실행하여 직원들에게 더 나은 업무 환경과 재정적 기회를 제공하고 회사의 성공에 투자한 참여 직원을 유치하고 유지함으로써 기업이 성과를 향상시키도록 도울 것입니다.',
    
    'Read our 2023 Annual ESG Review': '2023년 연간 ESG 리뷰 보기',
   
    'Diversity, Equity, And Inclusion': '다양성, 형평성, 포용성',
    'Promoting diversity, equity and inclusion is a core value at TPG': '다양성, 형평성 및 포용성 증진은 TPG의 핵심 가치',
    
    'At TPG, we believe that the quality of our investments and our ability to build great companies depend on the originality of our insights. Promoting diversity, equity and inclusion is a core value at TPG, embedded into the highest levels of our firm and guided by our Diversity, Equity & Inclusion Council. Created in 2015, the DE&I Council is a 14-member partner steering committee led by Firm Partner Maryanne Hancock and President Todd Sisitsky. It is supported by three advisory groups focused on recruiting and engagement with our external ecosystem and internal teams.': 'TPG에서 우리는 우리의 투자의 질과 훌륭한 회사를 만드는 우리의 능력은 우리의 통찰력의 독창성에 달려 있다고 믿습니다. 다양성, 형평성 및 포용성을 촉진하는 것은 우리 회사의 최고 수준에 내장되고 우리의 다양성, 형평성 및 포용성 위원회의 지도를 받는 TPG의 핵심 가치입니다. 2015년에 만들어진 DE&I 위원회는 Firm Partner Maryanne Hancock과 Todd Sisitsky 사장이 이끄는 14명으로 구성된 파트너 운영 위원회입니다. 이 위원회는 외부 생태계 및 내부 팀을 모집하고 참여하는 데 중점을 둔 세 개의 자문 그룹에 의해 지원됩니다.',
    
    'TPG is committed to providing equal employment opportunities to all employees and applicants without regard to race, religion, color, sex, gender identity and expression, sexual orientation, pregnancy, national origin, ancestry, citizenship status, uniform service member and veteran status, marital status, age, disability, or any other category protected by applicable Federal, State, and local laws. This policy applies to all terms and conditions of employment, including, but not limited to, recruiting, hiring, placement, discipline, promotions, termination, layoff, transfer, leaves of absence, benefits, compensation, and training. All TPG employees are required to adhere to this policy.': 'TPG는 인종, 종교, 피부색, 성별, 성 정체성 및 표현, 성적 지향, 임신, 국가 기원, 혈통, 시민권 지위, 유니폼 서비스 회원 및 베테랑 지위, 결혼 상태, 나이, 장애 또는 해당 연방, 주 및 지방 법률에 의해 보호되는 기타 범주를 포함한 모든 고용 조건에 적용됩니다. 이 정책은 모집, 고용, 배치, 배치, 징계, 승진, 해고, 이직, 휴직, 혜택, 보상 및 훈련을 포함한 모든 고용 조건에 적용됩니다. 모든 TPG 직원은 이 정책을 준수해야 합니다.',
    'TPG is committed to promoting a culture of ethical conduct and complying with applicable laws, regulations, and policies, and expects all employees to follow this commitment in all aspects of their work. The Firm encourages and expects all employees to raise concerns about any conduct that the employee believes, in good faith, could create legal, regulatory, or reputational harm to the Firm.': 'TPG는 윤리적 행동 문화를 촉진하고 관련 법, 규정 및 정책을 준수하기 위해 최선을 다하고 있으며 모든 직원이 업무의 모든 측면에서 이 약속을 따르기를 기대합니다. 회사는 모든 직원이 선의로 회사에 법적, 규제적 또는 평판상의 해를 끼칠 수 있다고 믿는 모든 행동에 대해 우려를 제기할 것을 권장하고 기대합니다.',
    'Vendor Anti-Discrimination Policy': '벤더 차별 방지 정책',
    
    'Ecosystem, Engaged.': '에코시스템, 참여중',
    
    'TPG’s strategy is anchored by our deep sector knowledge and experience building healthy, successful businesses. Explore the partnerships and the insights behind our investments.': 'TPG의 전략은 건강하고 성공적인 비즈니스를 구축하는 데 있어 당사의 깊은 부문 지식과 경험에 의해 닻을 내리고 있습니다. 파트너십과 투자 배경에 대한 통찰력을 살펴보세요.',
    
    'TPG Insights': 'TPG 통찰력',
    
    'All Insights': '모든 통찰력',
    
    'NEWSLETTER': '뉴스레터',
    
    'Capital and the Climate Revolution: Jim Coulter on the Scale, Scope, and Complexity of Climate Investing': '자본과 기후혁명: 기후투자의 규모, 범위, 복잡성에 관한 짐 콜터',
    
    'In our latest edition of The TPG Take, we sit down with Jim Coulter, TPG Co-Founder, Executive Chairman, and Managing Partner of TPG Rise Climate, to discuss his perspectives on the climate investing market, the challenges and opportunities that the growing investing landscape presents, and the important': 'TPG Take 최신판에서는 TPG Rise Climate의 공동 창립자, 집행위원장 및 관리 파트너인 Jim Coolter와 함께 기후 투자 시장에 대한 그의 관점, 성장하는 투자 환경이 제시하는 도전과 기회, 그리고 중요한점에 대해 논의합니다.',
    
    '4月.22日.2024': '4월.22일.2024',
    
    'SECTOR INSIGHTS': '섹터 인사이트',
    
    'The Consumer Landscape in Focus': '소비자가 주목하는 환경',
    
    'Our recent investment in Classic Collision, one of the fastest growing collision repair platforms, reflects our longstanding focus on consumer services. Partner Paul Hackwell shared what excites him about the broader consumer landscape and the opportunities we see for scaled, customer-first services platforms like': '가장 빠르게 성장하고 있는 충돌 수리 플랫폼 중 하나인 Classic Collision에 대한 최근의 투자는 소비자 서비스에 대한 우리의 오랜 초점을 반영합니다. 파트너인 Paul Hackwell은 광범위한 소비자 환경과 우리가 볼 수 있는 확장된 고객 최우선 서비스 플랫폼에 대한 기회에 대해 그가 흥분하는 점을 공유했습니다.',
    
    '4月.08日.2024': '4월.08일.2024',
    'Asset-Based Credit: The Post-Bank Era': '자산 기반 신용: 포스트 뱅크 시대',
    'In this new series, TPG Angelo Gordon’s Structured Credit & Specialty Finance team offers a deep dive on an emerging, but core, private credit asset class: specialty or asset-based private credit.': '이 새로운 시리즈에서 TPG 안젤로 고든의 Structured Credit & Specialty Finance 팀은 새로 등장하고 있지만 핵심적인 개인 신용 자산 등급(전문 또는 자산 기반 개인 신용)에 대해 자세히 설명합니다.',
    
    '3月.20日.2024': '3월.20일.2024',
    
    'Filter': '필터',
    
    'TPG Angelo Gordon’s Q2 2024 Capital Markets Perspectives': 'TPG 안젤로 고든의 2024년 2분기 자본시장 전망',

    'Increasing Opportunity in the Net Lease Space': '순 임대 공간에서의 기회 증대',
    
    'TPG Rise Climate Conversations': 'TPG 상승 기후 대화',
    
    'TPG Insights: Private Markets: Tectonic Shifts': 'TPG Insights: 프라이빗 마켓: 텍토닉 시프트',
    
    'Insights on the Mid-Market Lending Outlook with TPG Twin Brook’s Pete Notter': 'TPG 트윈브룩의 피트 노터와의 중간 시장 대출 전망에 대한 통찰',
    'ALL PRESS RELEASES': '모든 보도 자료',
    
    '4月.26日.2024': '4월.26일.2024',
    'IK Partners divests Yellow Hive to a newly established continuation fund': '아이케이파트너스, 옐로하이브 신규 설립 지속펀드에 출자',
    '4月.17日.2024': '4월.17일.2024',
    'Sayari Closes $235 Million Strategic Investment from TPG, Inclusive of Additional Capital for Organic Growth and M&A': '사야리, 유기적 성장 및 M&A를 위한 추가 자본 포함 TPG로부터 2억 3,500만 달러 전략적 투자 체결',
    '4月.04日.2024': '4월.04일.2024',
    'TPG Announces Results of Subsequent Acceptance Period following Voluntary Reopening of its Takeover Offer of Intervest; Announces Reopening of its Offer as a Simplified Squeeze-Out and Reduction of the Bid Price with the Dividend to be Approved by Intervest’s AGM': 'TPG 인터베스트 인수 제안 자발적 재개에 따른 후속 인수 기간 결과 발표; 인터베스트 AGM 승인 배당금으로 단순화된 스퀴즈아웃 및 입찰가 인하로 제안 재개 발표',
    '4月.03日.2024': '4월.03일.2024',
    'TPG Agrees to Acquire Classic Collision': 'TPG Classic Collision 인수 동의',
    '3月.06日.2024': '3월.06일.2024',
    'New impact venture Syre launches with a mission to decarbonize textile industry': '섬유 산업의 탈탄소화를 사명으로 하는 새로운 임팩트 벤처 Syre 출시',
    '2月.29日.2024': '2월.29일.2024',
    'TPG Announces Pricing of Senior Notes': 'TPG선순위 채권 가격 발표',
    '2月.28日.2024': '2월.28일.2024',
    'TPG Announces Results of Initial Acceptance Period for its Takeover Offer of Intervest': 'TPG인터베스트 인수 제안에 대한 최초 수락 기간 결과 발표',
    '2月.27日.2024': '2월.27일.2024',
    'TPG Announces Public Offering of Senior Notes and Fixed-Rate Junior Subordinated Notes': 'TPG선순위 채권 및 정률 후순위 채권 공모 발표',
    '2月.26日.2024': '2월.26일.2024',
    'TPG Announces Pricing of Secondary Public Offering': 'TPG 2차 공모 가격 발표',
    'TPG Announces Launch of Secondary Public Offering': 'TPG 2차 공모 발표',
    '2月.13日.2024': '2월.13일.2024',
    'TPG Reports Fourth Quarter and Full Year 2023 Results': 'TPG 2023년 4분기 및 연간 실적 발표',
    '2月.05日.2024': '2월.05일.2024',
    'Scott Lebovitz Joins TPG as Partner to Lead TPG Rise Climate Infrastructure Investing': 'Scott Lebovitz TPG 상승 기후 인프라 투자를 이끌 파트너로 TPG 합류',

    'Newsletter Archive': '뉴스레터 아카이브',
    'Date': '날짜',
    'Title': '제목',
    '4月/22日/2024': '4월/22일/2024',
    '3月/18日/2024': '3월/18일/2024',
    '2024 in Focus with CEO Jon Winkelried: Macro, Credit, and More': '2024년 존 윙클리드 CEO와 포커스: 매크로, 크레딧 등',
    
    'General Contact': '일반 연락처',

    'TPG is a leading global alternative asset manager with $224 billion in assets under management': 'TPG는 2,240억 달러의 자산을 운용하고 있는 세계적인 대체 자산운용사입니다.',
    "Jim Coulter and David Bonderman, former colleagues at the Bass Family Office, created TPG in 1992 and opened the firm's first offices in San Francisco. Today, TPG is led by CEO Jon Winkelried, who became sole CEO in 2021 after serving as Co-CEO since 2015.": "베이스 패밀리 오피스(Bass Family Office)의 전 동료였던 짐 콜터(Jim Coolter)와 데이비드 본더먼(David Bonderman)은 1992년 TPG를 설립하여 샌프란시스코(San Francisco)에 회사의 첫 사무실을 열었습니다. 오늘날 TPG는 2015년부터 공동 CEO를 역임한 후 2021년 단독 CEO가 된 존 윙클리드(Jon Winkelried) CEO가 이끌고 있습니다.",
    'A Unique Perspective': '독특한 관점',
    'With our family office roots, entrepreneurial heritage, and West Coast base, TPG has developed a distinctive approach to alternative investments based on innovation-led growth, an affinity for disruption and technology, and a distinctive culture of openness and collaboration.': 'TPG는 우리의 가족 사무실 뿌리, 기업가적 유산, 서해안 기반을 바탕으로 혁신 주도 성장, 중단과 기술에 대한 친화력, 개방과 협업이라는 독특한 문화를 바탕으로 대안 투자에 대한 독특한 접근 방식을 개발했습니다.',
    'Innovation and Organic Growth': '혁신과 유기적 성장',
    'Our principled focus on innovation has resulted in a disciplined, organic evolution of our business. Incubating, launching, and scaling new platforms and products organically—often early in the development of important industry trends—is embedded in our DNA. Over 30 years, we have developed an ecosystem of insight, engagement, and collaboration across our platforms and products, which currently include more than 300 active portfolio companies headquartered in more than 30 countries. With an extensive track record, a diversified set of investment strategies, and a strategic orientation towards areas of high growth, such as technology, healthcare, and impact, we are helping shape the future of alternative asset management.': '혁신에 대한 우리의 원칙적인 초점은 우리 비즈니스의 유기적인 진화를 가져왔습니다. 새로운 플랫폼과 제품을 유기적으로 인큐베이션, 런칭 및 확장하는 것은 우리의 DNA에 내재되어 있습니다. 30년 동안 우리는 플랫폼과 제품 전반에 걸쳐 통찰력, 참여 및 협업 생태계를 발전시켜 왔으며, 현재 30개 이상의 국가에 본사를 둔 300개 이상의 활성 포트폴리오 회사를 포함하고 있습니다. 광범위한 실적, 다양한 투자 전략 및 기술, 의료 및 영향력과 같은 고성장 분야에 대한 전략적 지향점을 통해 우리는 대체 자산 관리의 미래를 형성하는 데 도움을 주고 있습니다.',
    'Strategic Acquisition': '전략적 획득',
    'In 2023, TPG acquired Angelo Gordon, marking a significant expansion into credit investing and offering real estate capabilities that are complementary to our current strategies. This strategic transaction meaningfully expanded our investing capabilities and broadens our product offering, underscoring our continued focus on growing and scaling through diversification.': '2023년 TPG는 안젤로 고든을 인수하여 신용 투자 및 현재 전략을 보완하는 부동산 기능을 제공하는 데 상당한 확장을 이루었습니다. 이 전략적 거래는 의미 있게 투자 역량을 확장하고 제품 제공을 확장하여 다각화를 통한 성장 및 확장에 지속적인 집중을 강조합니다.',
    
    'Leadership': '리더십',
    'TPG’s leadership team is made up of world-class executives and business leaders who have an average of 27 years of professional experience, including an average tenure at TPG of more than 12 years, and who are supported by a deep bench of talented professionals. We have a substantial global footprint and network for sourcing transactions, raising capital and driving value in our investments.': 'TPG의 리더십 팀은 TPG에서 평균 12년 이상 근무한 것을 포함하여 평균 27년의 전문 경력을 가진 세계적인 경영진과 비즈니스 리더들로 구성되며 이들은 우수한 전문가들로 구성된 깊은 벤치의 지원을 받습니다. 당사는 거래를 소싱하고 자본을 조달하며 투자 가치를 높이는 데 상당한 글로벌 입지와 네트워크를 보유하고 있습니다.',
    'Arun Agarwal': '아룬 아가왈',
    'TPG Capital, TPG Growth, TPG Technology Adjacencies': 'TPG Capital, TPG Growth, TPG Technology Adjacency',
    'Americas': '아메리카',
    'Bharati Agarwal': '바라티 아가왈',
    'Asia': '아시아',
    'Nitin Agarwal': '니틴 아가왈',
    'Dan Allen': '댄 앨런',
    'Matt Allessio': '매트 알레시오',
    'Operations, The Rise Funds, TPG Growth': '영업, 더 라이징 펀드, TPG 성장',
    'Michael Antilety': '마이클 앤틸리티',
    'Jeff Arvin': '제프 아르빈',
    'Mayank Bajpai': 'Mayank Bajpai',
    'The Rise Funds, TPG Growth': '라이징 펀드, TPG 성장',
    'Karthik Balu': '카르티크 발루',
    'Operations, TPG Capital': 'TPG캐피탈 영업',
    'Stephen Bamford': '스티븐 뱀포드',
    'Client & Capital Formation': '고객 및 자본형성',
    'Europe': '유럽',
    'Avi Banyasz': 'Avi Banyasz',
    'Luke Barrett': '루크 배럿',
    'TPG Holdings': 'TPG홀딩스',
    'Simit Batra': '시밋 바트라',
    'Celeste Bauby': '셀레스테 바우비',
    'Josh Baumgarten': '조쉬 바움가르텐',

    'Investor Relations': '투자자관계',
    'Primary Media Contact': '기본 미디어 연락처',
    'Africa': '아프리카',
    'Bridget Nurre Jennions': '브리짓 누레 제니언스',
    'Elaine Li': '일레인 리',
    'Australia': '호주',
    'Jim Kelly': '짐 켈리',
    'India': '인도',
    'Snigdha Nair': 'Snigdha Nair',
    'Investor Relations Team': '투자자관계팀',
    'Investor Portal Login': '투자자 포털 로그인',

    'Our Offices': '우리 사무실',
    'New York': '뉴욕',
    'TPG': 'TPG',
    '888 7th Avenue': '888 7번가',
    '35th Floor': '35층',
    'New York, NY 10106': '뉴욕, NY 10106',
    '245 Park Avenue': '245 파크 애비뉴',
    'New York, NY 10167': '뉴욕, NY 10167',
    'View Map': '지도 보기',
    'San Francisco': '샌프란시스코',
    '345 California Street': '345 California Street',
    'Suite 3300': 'Suite 3300',
    'San Francisco, CA 94104': 'San Francisco, CA 94104',
    '101 California St, Suite 2820': '101 California St, Suite 2820',
    'San Francisco, CA 94111': 'San Francisco, CA 94111',
    'Chicago': '시카고',
    '111 South Wacker Drive': '111 South Wacker Drive',
    '36th Flr.': '36th Flr.',
    'Chicago, IL 60606': 'Chicago, IL 60606',
    'Fort Worth': '포트워스',
    '301 Commerce Street': '301 Commerce Street',
    'Fort Worth, TX 76102': 'Fort Worth, TX 76102',
    'Los Angeles': '로스앤젤레스',
    '2000 Avenue of the Stars,': '2000 Avenue of the Stars',
    'Suite 1020': 'Suite 1020',
    'Los Angeles, CA 90067': 'Los Angeles, CA 90067',
    'Washington, D.C.': '워싱턴 D.C.',
    '900 16th Street, N.W.': '900 16th Street, N.W.',
    'Suite 200': 'Suite 200',
    'Washington, D.C. 20006': 'Washington, D.C. 20006',
    'Beijing': '베이징',
    'Suite 3801, China World Tower 3': 'Suite 3801, China World Tower 3',
    'No.1, Jianguomenwai Avenue,': 'No.1, Jianguomenwai Avenue,',
    'Chaoyang District, Beijing 100004': 'Chaoyang District, Beijing 100004',
    'Room 906-907A, 9th Floor,': 'Room 906-907A, 9th Floor,',
    'Building H': 'Building H',
    'Phoenix Place, No. A5, Shuguang Xili': 'Phoenix Place, No. A5, Shuguang Xili',
    'Beijing, China 100028': 'Beijing, China 100028',
    'Hong Kong': '홍콩',
    'Suite 2807-2808': 'Suite 2807-2808',
    'Two Exchange Square': 'Two Exchange Square',
    '8 Connaught Place': '8 Connaught Place',
    'Unit 2902, 29/F, Prosperity Tower': 'Unit 2902, 29/F, Prosperity Tower',
    '39 Queen’s Road Central': '39 Queen’s Road Central',
    'Central, Hong Kong': 'Central, Hong Kong',
    'Suite 2806': 'Suite 2806',
    'Mumbai': '멈바이',
    '1004, The Capital': '1004, The Capital',
    'Plot No. C-70, G-Block': 'Plot No. C-70, G-Block',
    'Bandra Kurla Complex, Bandra (E)': 'Bandra Kurla Complex, Bandra (E)',
    'Mumbai 400 051, India': 'Mumbai 400 051, India',
    '904 and 905, C Wing, One BKC, G-Block': '904 and 905, C Wing, One BKC, G-Block',
    'Bandra Kurla Complex, Bandra (East)': 'Bandra Kurla Complex, Bandra (East)',
    'Mumbai 400051, India': 'Mumbai 400051, India',
    'Seoul': '서울',
    '20th Floor, Parnas Tower': '파르나스 타워 20층',
    '521 Teheran-ro, Gangnam-gu': '강남구 테헤란로 521',
    '06164 Seoul, Korea': '06164 서울,한국',
    '9F, A Tower, K Twin Towers': '9층 A타워, K트윈타워',
    '50 Jongno 1-Gil, Jongno-Gu': '종로구 종로1길 50',
    'Seoul, Korea 03142': '서울, 한국 03142',
    'Singapore': '싱가포르',
    '83 Clemenceau Avenue': '83 Clemenceau Avenue',
    '11-01 UE Square': '11-01 UE Square',
    'Singapore 239920': 'Singapore 239920',
    '1 Raffles Quay, #34-01': '1 Raffles Quay, #34-01',
    'One Raffles Quay, North Tower': 'One Raffles Quay, North Tower',
    'Singapore 048583': 'Singapore 048583',
    '8 Marina Boulevard': '8 Marina Boulevard',
    'Marina Bay Financial Centre Tower 1': 'Marina Bay Financial Centre Tower 1',
    'Level 11': 'Level 11',
    'Singapore 018981': 'Singapore 018981',
    'Dubai': '듀바이',
    'Gate Building Floor 15 Unit 7': 'Gate Building Floor 15 Unit 7',
    'P.O. Box 121208': 'P.O. Box 121208',
    'DIFC, Dubai': 'DIFC, Dubai',
    'UAE': 'UAE',
    'Shenzhen': '선전',
    'Unit 601-02, Shenzhen International Qianhai E-metro Tower': 'Unit 601-02, Shenzhen International Qianhai E-metro Tower',
    'No. 99 Gang Cheng Street, Nanshan District, Shenzhen,': 'No. 99 Gang Cheng Street, Nanshan District, Shenzhen',
    'Guangdong, China 518066': 'Guangdong, China 518066',
    'Tokyo': '도쿄',
    'Roppongi Hills Mori Tower 17th Fl.': 'Roppongi Hills Mori Tower 17th Fl.',
    '6-10-1, Roppongi': '6-10-1, Roppongi',
    'Minato-ku, Tokyo, 106-6117': 'Minato-ku, Tokyo, 106-6117',
    'Shanghai': '상하이',
    'Suite 2032-2035, 20/F': 'Suite 2032-2035, 20/F',
    'HKRI Centre Two': 'HKRI Centre Two',
    '288 Shimen First Road': '288 Shimen First Road',
    'Shanghai 200040, China': 'Shanghai 200040, China',
    'London': '런던',
    '5th Floor, Park House': '5th Floor, Park House',
    '116 Park Street': '116 Park Street',
    'London, W1K 6AF': 'London, W1K 6AF',
    'United Kingdom': 'United Kingdom',
    '23 Savile Row': 'United Kingdom',
    'London W1S 2ET': 'London W1S 2ET',
    'Amsterdam': '암스테르담',
    'Vijzelstraat 72 – Floor 7b': 'Vijzelstraat 72 – Floor 7b',
    '1017 HL Amsterdam': '1017 HL Amsterdam',
    'The Netherlands': 'The Netherlands',
    'Copenhagen': '코펜하겐',
    'B.V. Danish Branch': 'B.V. Danish Branch',
    'Zieglers Gaard, Nybrogade 12, 1203': 'Zieglers Gaard, Nybrogade 12, 1203',
    'København, Denmark': 'København, Denmark',
    'Luxembourg': '룩셈부르크',
    'The Goldbell Centre': 'The Goldbell Centre',
    '5 rue Eugene Ruppert': '5 rue Eugene Ruppert',
    'L-2453 Luxembourg': 'L-2453 Luxembourg',
    'Frankfurt': '프랑크푸르트',
    'Taunustor 1, Office 18.14': 'Taunustor 1, Office 18.14',
    '60310 Frankfurt am Main': '60310 Frankfurt am Main',
    'Germany': '독일',
    'Milan': 'Milan',
    'Via San Raffaele 1': 'Via San Raffaele 1',
    'Milan 20121': 'Milan 20121',
    'Italy': '이태리',
    'Melbourne': 'Melbourne',
    'Level 38, South Tower': 'Level 38, South Tower',
    '80 Collins Street': '80 Collins Street',
    'Melbourne, Victoria 3000': 'Melbourne, Victoria 3000',

    'TPG Rise Climate to Acquire Olympus Terminals, Leading Renewable Fuels Logistics Provider': 'TPG 기후 상승, 올림푸스 터미널 인수, 선도적인 재생 연료 물류 제공업체',
    '5月.07日.2024': '5월.07일.2024',
    '5月.02日.2024': '5월.07일.2024',
    '5月.01日.2024': '5월.01일.2024',

    'Products': '제품',
    'Explore Capital': '자본 탐색',
    'Explore Growth': '성장 탐색',
    'Explore Impact': '영향력 탐색',
    'Explore Market Solutions': '마켓 솔루션 탐색',
    'Explore Real Estate': '부동산 탐색',
    'TPG 안젤로 고든 탐색': '',
    'TPEP': '',
    'Private Markets Solutions': '프라이빗 마켓 솔루션',

    'About Us': 'About Us',
    'Who We Are': '우리는 누구인가',

    "INSIGHTS": "인사이트",
    "Share": "공유",
    "Matt Jones, Co-Managing Partner of TPG GP Solutions, shares his thoughts on how the dynamics in the GP-led secondaries market are driving attractive deal flow.": "TPG GP Solutions의 공동 관리 파트너인 Matt Jones는 GP가 주도하는 세컨더리 시장의 역동성이 어떻게 매력적인 거래 흐름을 이끌어내고 있는지에 대한 자신의 생각을 공유합니다.",
    "Learn more about the sectors we’re pursuing firmwide:": "우리가 전사적으로 추구하는 부문에 대해 자세히 알아보기",
    "SET (Software & Enterprise Technology)": "SET (소프트웨어 및 엔터프라이즈 기술)",
    "We are pleased to announce that the latest edition of TPG Angelo Gordon’s Capital Markets Perspectives (“CMP”) is now available. This quarterly publication provides information and TPG Angelo Gordon portfolio managers’ views on a range of credit and real estate markets.": "TPG 안젤로 고든의 자본 시장 관점(\"CMP\")의 최신판이 출시되었음을 알려드리게 되어 기쁩니다. 이 분기별 간행물은 다양한 신용 및 부동산 시장에 대한 정보와 TPG 안젤로 고든 포트폴리오 매니저의 견해를 제공합니다.",
    "Visit our dedicated CMP microsite to view the most recent edition of the CMP and access an archive of past quarterly publications.": "전용 CMP 마이크로 사이트를 방문하여 CMP의 최신판을 보고 지난 분기별 출판물의 아카이브에 액세스하십시오.",
    "CMP microsite": "CMP 마이크로사이트",
    "When founding TPG Angelo Gordon’s Net Lease Real Estate strategy almost 20 years ago, explaining the niche product to unfamiliar business leaders was a common occurrence for Gordon Whiting. Fast forward to 2024, and sale-leasebacks have become a ubiquitous financing tool for companies, with the associated net lease investment strategy presenting an equally attractive opportunity for investors. Against that backdrop, Whiting recently sat down with PERE to share his views on the increasing opportunity in the net lease space today.": "거의 20년 전 TPG 안젤로 고든의 Net Lease Real Estate 전략을 설립할 때 낯선 비즈니스 리더들에게 틈새 제품을 설명하는 것은 Gordon Whiting에게 흔한 일이었습니다. 2024년으로 빠르게 넘어가고 있으며, 판매-리스백은 기업들에게 유비쿼터스 자금 조달 수단이 되었고 관련된 Net Lease 투자 전략은 투자자들에게 똑같이 매력적인 기회를 제공했습니다. 이러한 배경에서 Whiting은 최근 PERE와 함께 오늘날 Net Lease 공간에서 증가하는 기회에 대한 자신의 견해를 공유했습니다.",

    "Watch the case study": "사례 연구를 보세요",
    "Creating constructive partnerships with public companies": "공개 회사와 건설적인 파트너십을 구축합니다",
    "TSCF is currently investing out of a $1.1 billion fund.": "TSCF은 현재 11억 달러 규모의 펀드로 투자하고 있습니다.",
    "TSCF leverages the deep capabilities of the broader TPG platform to help strengthen governance and provide resources for strategic and operational improvement in our portfolio companies.": "TSCF은 더 넓은 TPG 플랫폼의 심도 있는 능력을 활용하여 거버넌스 강화와 포트폴리오 기업의 전략 및 운영 개선을 위한 자원을 제공합니다.",
    "Strategic secondary investment solutions for private market investors": "개인 시장 투자자를 위한 전략적 중간 투자 솔루션",
    "We established TPG GP Solutions (TGS) in 2021 to address a growing demand for liquidity and financing solutions for general partners that manage private market assets.": "2021년 TPG GP Solutions(TGS)를 설립하여 개인 시장 자산을 관리하는 일반 파트너들의 유동성과 금융 솔루션에 대한 증가하는 수요를 해결하기 위해",
    "A built-for-purpose specialist focused exclusively on the GP-led market": "GP 주도 시장에 전적으로 집중하는 목적을 위한 전문가",
    "During our more than 30 years of investing, we’ve seen that strong growth in any market leads to the rise of derivative markets—and those derivative markets are often sources of interesting opportunities. Over the past decade, unprecedented growth and innovation in the private equity market has spurred the growth in the secondary private equity market. TGS has created a distinct position within the secondary market, with a strategy focused on investing in high quality assets by partnering with experienced GPs executed by a purpose-built team comprised by experienced secondaries and direct investors.": "30년 넘게 투자한 경험을 통해 우리는 모든 시장의 강력한 성장이 파생 시장의 상승으로 이어진다는 것을 알게 되었고, 이러한 파생 시장은 종종 흥미로운 기회의 원천입니다. 지난 10년 동안, 민간 주식 시장의 전례 없는 성장과 혁신은 중간 민간 주식 시장의 성장을 촉진했습니다. TGS는 중간 시장에서 경험이 풍부한 GP들과 파트너를 맺고, 경험이 풍부한 중간 시장 및 직접 투자자들로 구성된 목적을 위한 팀이 실행하는 고품질 자산에 투자하는 전략에 집중하여 독특한 위치를 창출했습니다.",
    "TGS brings a private equity approach to the GP-led secondaries market, leveraging team’s deep investment expertise and the full TPG ecosystem’s sector insights, operating capabilities and geographical reach. TGS partners with GPs to invest in high-quality private equity assets that are primarily based in North America and Europe.": "TGS는 팀의 깊은 투자 전문성과 TPG 생태계의 부문 통찰력, 운영 능력 및 지리적 범위를 활용하여 GP 주도 중간 시장에 개인 자본 접근 방식을 가져옵니다. TGS는 주로 북미와 유럽에 본사를 둔 고품질 개인 자본 자산에 투자하기 위해 GP와 파트너를 맺습니다.",
    "Specialist manager providing strategic solutions to PE owners across Asia Pacific": "아시아 태평양 지역의 PE 소유자에게 전략적 솔루션을 제공하는 전문 관리자",
    "Established in 2011, TPG NewQuest is one of Asia’s leading secondary private equity platforms with the largest, most experienced secondary team in Asia across five offices. Since its founding, TPG NewQuest has focused on working with GPs to create bespoke, tailored solutions to meet liquidity and other strategic needs of private asset owners and their stakeholders. Starting from a strategic partnership forged in 2018, TPG NewQuest became wholly owned by TPG in January 2022. Being part of TPG has further strengthened TPG NewQuest’s homegrown team of 30+ investment professionals’ insight into sector-specific, bottoms-up fundamental underwriting of secondary assets, while bringing the experience of navigating complex secondary transactions to the platform.": "2011년 설립된 TPG NewQuest는 아시아 5개 사무소에서 가장 큰 규모와 경험이 풍부한 중간 팀을 보유한 아시아 선두 중간 개인 자본 플랫폼 중 하나입니다. 설립 이래 TPG NewQuest는 개인 자산 소유자와 이해관계자의 유동성 및 기타 전략적 요구를 충족하기 위해 GP와 협력하여 맞춤형 솔루션을 만들기 위해 노력해 왔습니다. 2018년에 형성된 전략적 파트너십을 시작으로 TPG NewQuest는 2022년 1월 TPG의 전액 소유가 되었습니다. TPG의 일부임으로 TPG NewQuest는 30명 이상의 투자 전문가 팀의 부문별, 바텀업 기본 보증 중간 자산에 대한 통찰력을 더욱 강화하고 복잡한 중간 거래를 탐색한 경험을 플랫폼에 가져왔습니다.",
    "Direct and sector-centric investment approach": "직접적이고 부문 중심의 투자 접근 방식",
    "TPG NewQuest specializes in providing alternative liquidity solutions to private asset owners through transformational GP-led transactions as well as direct secondary transactions. Our direct and sector-centric fundamental investment approach allows us to confidently lead complex bespoke transactions for general partners across the region. Over 12 years, we have established ourselves as a well-capitalized, Asia-focused, built-for-purpose strategic solutions provider for repeat transactions.": "TPG NewQuest는 변혁적인 GP 주도 거래와 직접 중간 거래를 통해 개인 자산 소유자에게 대체 유동성 솔루션을 제공하는 데 특화되어 있습니다. 우리의 직접적이고 부문 중심의 기본 투자 접근 방식을 통해 우리는 지역의 일반 파트너를 위해 복잡한 맞춤형 거래를 확신 있게 주도할 수 있습니다. 12년 넘게 우리는 반복 거래를 위한 목적을 위해 구축된 잘 자본화된, 아시아 중심의 전략적 솔루션 제공자로 자리 잡았습니다.",
    "TPG NewQuest manages a diversified portfolio of private equity investments across the Asia-Pacific Region. These include both direct investments that are held and managed by the TPG NewQuest team as well as indirect exposures to companies through fund investments. Portfolio companies operate in a wide range of sectors, with a focus on five core sectors: business services, consumer, financial services, healthcare, and TMT. Focus sectors align with industry sectors where TPG has expertise.": "TPG NewQuest는 아시아 태평양 지역에 걸쳐 다양한 개인 자본 투자 포트폴리오를 관리합니다. 여기에는 TPG NewQuest 팀이 보유하고 관리하는 직접 투자와 펀드 투자를 통해 기업에 대한 간접 노출이 포함됩니다. 포트폴리오 회사는 비즈니스 서비스, 소비자, 금융 서비스, 의료 서비스 및 TMT를 포함하여 광범위한 부문에서 운영되며, TPG가 전문성을 가진 산업 부문과 일치하는 5개의 핵심 부문에 중점을 둡니다.",
    "Buyouts Keynote Interview: The Future Belongs to the Specialists": "매각 주요 인터뷰: 미래는 전문가에게 속한다",

    "28F, IFC Block 3, 10 International Geummyeong-ro, Yeongdeungpo-gu, Seoul (Yoido-dong International Financial Center)": '서울특별시 영등포구 국제금융로 10 Three IFC 28층(여의도동, 국제금융센터)',
    "New thinking creates new heights of revenue": "새로운 사고는 새로운 수익을 창출한다",
    "After the TPG capture was officially operational, it connected short-term profit realization with long-term value, creating an East Asian investment miracle and providing a path for one million investors to profit. Our latest layout is the TPG Strategic Capital Fund (TSCF), which organically combines short-term revenue with global value to generate new revenue every year. We are widely disseminating this plan.": "TPG captial이 공식적으로 운영된 후 단기 차익 실현과 장기 가치를 연결하여 동아시아 투자 기적을 창출하고 백만 명의 투자자들이 이익을 얻을 수 있는 길을 제공했습니다. 우리의 최신 레이아웃은 TPG 전략 자본 기금(TSCF)으로 단기 수익과 글로벌 가치를 유기적으로 결합하여 매년 새로운 수익을 창출합니다.우리는 이 방안을 널리 보급하고 있습니다.",
    "Innovative investment projects": "혁신투자사업입구",
    "No more projects": "다른 프로젝트는 없음",
    "GPG Capital": "GPG 자본",
    "TPG, where Sanghoon Lee currently works, was one of the first asset managers to establish an Asian franchise.Now that Asia Pacific is a global growth engine, TPG Capital is leveraging our deep expertise and strong regional presence to help nurture emerging leaders in this dynamic market, with a particular focus on areas where technology can drive disruptive growth.": "현재 교수님께서 몸담고 계시는TPG 아시아 최초로 프랜차이즈를 구축한 자산운용사 중 하나입니다.오늘날 아시아 태평양 지역은 세계적인 성장 엔진이 되었으며, TPG 캐피탈은 기술이 파괴적인 성장을 견인할 수 있는 분야에 특히 관심을 기울이고 있습니다. 이런 활발한 시장에서 훌륭한 리더를 배양하기 위하여 특별히 깊은 전문노하우를 공유해드리고 있습니다.",
    "TPG Capital was the first company established within TPG when TPG was founded in 1992.Today, with our capital platform focused on large-scale, control-oriented private equity investments and leveraging our own resource strengths to drive industry growth, we seek cross-regional opportunities and focus on areas where we develop unique expertise over time.": "TPG캐피탈은 TPG가 1992년 설립될 당시 TPG 내부에 설립된 첫 기업입니다.오늘날 우리의 자본 플랫폼은 주로 대규모 통제 지향적인 사모 주식 투자를 수행하고 자체 자원을 활용하여 산업 발전을 주도하며 기회를 모색하고 시간이 지남에 따라 고유한 전문 지식을 개발하는 데 중점을 둡니다.",

}

export {
    Korean
}