import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import router from './router'
import CoverTop from '@/components/CoverTop/index.vue'
import SelectInvestments from '@/components/SelectInvestments/index.vue'
import {i18n} from '@/i18/index'

const app = createApp(App)


app.use(router)
app.use(i18n)
console.log(i18n.global.locale);
app.use(ElementPlus,{
    locale: zhCn
})
app.component('CoverTop', CoverTop)
app.component('SelectInvestments', SelectInvestments)
app.mount('#app')
