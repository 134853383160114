import { English } from "./message/en";
import { Korean } from "./message/korean";
import { Chinese_Taiwan } from "./message/Chinese_Taiwan";
import { empty } from "./message/empty";

const message = {
    en: empty,
    zh_tw: Chinese_Taiwan,
    korean: Korean,
    // empty: empty
};

export default message