import { createI18n, useI18n } from 'vue-i18n';
import messages from './message';

const i18n = createI18n({
  locale: 'korean', // 设置默认语言环境
  messages,     // 定义语言环境信息
});

export {
  i18n, 
  useI18n
}