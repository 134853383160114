<script setup lang="ts">
import Footer from '@/components/Footer/index.vue'
import Header from '@/components/Header/index.vue'
import { nextTick, onMounted, onUnmounted, watch } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute()

const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            // 当元素进入视窗时触发
            observer.unobserve(entry.target)
            entry.target.classList.add('sal-animate')
        }
    });
}, {
    // 配置选项
    root: null, // 视窗的根元素
    rootMargin: '0px', // 视窗边缘的外边距
    threshold: 0, // 可见性阈值
});

onUnmounted(() => {
    // 组件销毁时，移除观察者
    document.querySelectorAll('[data-sal]').forEach(el => {
        observer.unobserve(el)
    })
})

watch(() => route.path, (newPath) => {
  // 路由路径变化时执行的逻辑
  setTimeout(() => {
      document.querySelectorAll('[data-sal]').forEach(el => {
        observer.observe(el)
    })
  },300)
}, {immediate: true});

</script>

<template>
  <div class="page">
    <Header></Header>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<style lang="less">
@import url("@/assets/css/index.css");
@import url('@/assets/css/common.less');
</style>
