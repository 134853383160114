<template>
  <div class="fixed left-0 top-0 z-[999] w-full" @mouseleave.stop="mouseleave()">
    <header class="site-header transition-all duration-300" :class="{ 'active group': !isTop || Object.values(navbarSub).some((value) => value) || open || topPage }">
      <div class="wrapper !max-w-[98%] xl:!max-w-[1296px]">
        <div class="logo-wrapper">
          <a class="flex" href="/"><span class="sr-only">{{ $t("TPG") }}</span><img class="logo" src="/public/logo.png" role="presentation" alt="" /></a>
        </div>
        <ul class="navbar">
          <li v-for="item in menu" class="navbar-item" :id="item.name" @mouseenter.stop="mouseleave(item.name)">
            <a :href="`${item.target}`" v-if="item.target">{{ $t(item.label) }}</a>
            <a v-else style="cursor: pointer">{{ $t(item.label) }}</a>
          </li>
          <li :class="{ 'white': !isTop || Object.values(navbarSub).some((value) => value) || open || topPage }">
            <div class="h-[34px] w-[1px] bg-white/30"></div>
          </li>
          <li :class="{ 'white': !isTop || Object.values(navbarSub).some((value) => value) || open || topPage }" class="navbar-item text-white/80" style="font-size: 0.8rem;">
            <span style="cursor: pointer;margin-right: 2rem;" @click.stop="changeLanguge('en')">Eng</span>
            <span style="cursor: pointer;margin-right: 2rem;" @click.stop="changeLanguge('zh_tw')">繁中</span>
            <span style="cursor: pointer;" @click.stop="changeLanguge('korean')">한국어</span>
          </li>
        </ul>
        <div class="hamburger-container lg:hidden">
          <button @click.stop="open ? mouseleave() : (open = true)">
            <svg class="burger stroke-black" width="20" v-if="!open" height="20" viewBox="0 0 20 20" fill="none" stroke-width="2" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 6H20" class="top"></path>
              <path d="M0 14H20" class="bot"></path>
            </svg>
            <svg v-else class="burger stroke-black is-open" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke-width="2" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 6H20" class="top"></path>
              <path d="M0 14H20" class="bot"></path>
            </svg>
          </button>
        </div>
      </div>
    </header>

    <div class="relative top-[108px] z-[11] transform scale-100 opacity-100" v-if="innerWidth > 768">
      <div>
        <ul v-for="item in menuList" class="header-dropdown" :class="item.list.length >= 3 ? 'is-big grid-cols-4 gap-6 !py-8 lg:grid lg:w-screen lg:px-14 xl:!w-[1440px] xl:gap-x-12 xl:!px-24' : 'flex flex-col justify-start'" :id="item.id + '-menu'" v-show="navbarSub[item.id]" :style="{ transform: `translateX(${x}px)` }">
          <li class="header-dropdown-item !px-0" v-for="nav in item.list">
            <small class="group relative border-white py-2 text-base !font-medium text-black">{{  $t(nav.label) }}</small>
            <ul>
              <li class="" v-for="child in nav.children">
                <a class="group relative border-white py-2 !font-book text-base font-normal text-black/70 hover:text-black" :href="child.target">{{  $t(child.label) }}<span class="absolute bottom-0 left-0 w-0 border-b border-black transition-all duration-300 group-hover:w-full"></span></a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>

    <div v-if="open" class="fixed left-0 top-0 z-[99] h-screen w-full origin-top overflow-auto bg-white pt-[92px] transform scale-100 opacity-100">
      <div style="display: flex;align-items: center;justify-content: space-around;font-size: 1.2rem;padding: 1rem 0;">
        <span style="cursor: pointer;" @click.stop="changeLanguge('en')">Eng</span>
        <span style="cursor: pointer;" @click.stop="changeLanguge('zh_tw')">繁中</span>
        <span style="cursor: pointer;" @click.stop="changeLanguge('korean')">한국어</span>
      </div>
      <ul class="navbar border-t border-black border-opacity-10 py-6">
        <li class="navbar-item" v-for="item in menu">
          <button @click.stop="mouseleave(item.name)" v-if="item.name !== 'Portfolio'" class="block px-9 py-2 text-[20px] leading-[30px] text-black">{{ item.name }}</button>
          <button v-else class="block px-9 py-2 text-[20px] leading-[30px] text-black">
            <a :href="item.target">{{ item.name }}</a>
          </button>

          <div class="my-2 flex w-full flex-col bg-gray-1 px-9 py-8" v-if="menuList.find((el) => el.id == item.name)?.list && navbarSub[item.name]">
            <template v-for="nav in menuList.find((el) => el.id == item.name)?.list">
              <button class="block text-[15px] leading-22 text-black mt-10 font-medium first:mt-0">{{  $t(nav.label) }}</button>
              <div class="mt-4 flex w-full flex-col space-y-4">
                <a v-for="child in nav.children" class="block text-[15px] leading-22 text-black text-opacity-70" :href="child.target">{{  $t(child.label) }}</a>
              </div>
            </template>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { i18n } from "@/i18/index";
import { watch } from "vue";
import { Ref, nextTick, onMounted, reactive, ref } from "vue";
import { useRoute } from "vue-router";

const open = ref(false);
const menu = reactive([
  { name: "Approach", label: "Approach", target: "/approach" },
  { name: "Platforms", label: "Platforms", target: "" },
  { name: "Portfolio", label: "Portfolio", target: "/portfolio" },
  { name: "Engagement", label: "Engagement", target: "/engagement" },
  { name: "NewsInsights", label: "News & Insights", target: "/news-and-insights" },
  { name: "About", label: "About", target: "/about-us" },
]);

const menuList = reactive([
  {
    id: "Approach",
    list: [
      {
        label: "Approach",
        children: [
          { target: "/approach", label: "Our Approach" },
          { target: "/approach/healthcare", label: "Healthcare" },
          { target: "/approach/set", label: "SET (Software & Enterprise Tech)" },
          { target: "/approach/idmc", label: "IDMC (Internet, Digital Media & Communications)" },
          { target: "/approach/consumer", label: "Consumer" },
          { target: "/approach/businessServices", label: "Business Services" },
        ],
      },
    ],
  },
  {
    id: "Platforms",
    list: [
      {
        label: "Capital",
        children: [
          { target: "/platforms/capital", label: "Capital Overview" },
          { target: "/platforms/capital/tpg-capital", label: "TPG Capital" },
          { target: "/platforms/capital/tpg-asia", label: "TPG Capital Asia" },
          { target: "/platforms/capital/tpg-healthcare-partners", label: "TPG Healthcare Partners" },
        ],
      },

      {
        label: "Growth",
        children: [
          { target: "/platforms/growth", label: "Growth Overview" },
          { target: "/platforms/growth/TPGGrowth", label: "TPG Growth" },
          { target: "/platforms/growth/TPGDigitalMedia", label: "TPG Digital Media" },
          { target: "/platforms/growth/TPGTechAdjacencies", label: "TPG Tech Adjacencies" },
          { target: "/platforms/growth/TPGLifeSciencesInnovations", label: "TPG Life Sciences Innovations" },
        ],
      },
      {
        label: "Impact",
        children: [
          { target: "/platforms/impact", label: "Impact Overview" },
          { target: "https://therisefund.com/", label: "The Rise Funds" },
          { target: "https://therisefund.com/tpgriseclimate", label: "TPG Rise Climate" },
          { target: "https://evercaregroup.com/", label: "Evercare" },
        ],
      },
      {
        label: "TPG Angelo Gordon",
        children: [
          { target: "/platforms/tpg-angelo-gordon", label: "TPG Angelo Gordon Overview" },
          { target: "/platforms/tpg-angelo-gordon/tpg-angelo-gordon-credit-solutions", label: "TPG AG Credit Solutions" },
          { target: "/platforms/tpg-angelo-gordon/tpg-angelo-gordon-clos", label: "TPG AG CLOs" },
          { target: "/platforms/tpg-angelo-gordon/tpg-angelo-gordon-structured-credit-specialty-finance", label: "TPG AG Structured Credit & Specialty Finance" },
          { target: "/platforms/tpg-angelo-gordon/tpg-angelo-gordon-middle-market-direct-lending", label: "TPG AG Middle Market Direct Lending" },
          { target: "/platforms/tpg-angelo-gordon/tpg-angelo-gordon-multi-strategy", label: "TPG AG Multi-Strategy" },
          { target: "/platforms/tpg-angelo-gordon/tpg-angelo-gordon-global-real-estate", label: "TPG AG Global Real Estate" },
          { target: "/platforms/tpg-angelo-gordon/tpg-angelo-gordon-net-lease-real-estate", label: "TPG AG Net Lease Real Estate" },
        ],
      },
      {
        label: "Real Estate",
        children: [
          { target: "/platforms/real-estate", label: "Real Estate Overview" },
          { target: "/platforms/real-estate/tpg-re-finance-trust", label: "TPG Real Estate Partners" },
          { target: "/platforms/real-estate/tpg-real-estate-thematic-advantage-core-plus", label: "TPG Thematic Advantage Core-Plus" },
          { target: "/platforms/real-estate/tpg-real-estate-partners", label: "TPG Real Estate Finance Trust" },
        ],
      },
      {
        label: "Market Solutions",
        children: [
          { target: "/platforms/market-solutions", label: "Market Solutions Overview" },
          { target: "/platforms/market-solutions/#capital-markets", label: "Capital Markets" },
          { target: "/platforms/market-solutions/TPGPublicEquityPartners", label: "TPG Public Equity Partners" },
          { target: "https://pace.tpg.com/", label: "TPG Pace Group" },
          { target: "/platforms/market-solutions/TPGStrategicCapitalFund", label: "TPG Strategic Capital Fund" },
          { target: "/platforms/market-solutions/TPGNewQuest", label: "TPG NewQuest" },
          { target: "/platforms/market-solutions/TPGGPSolutions", label: "TPG GP Solutions" },
        ],
      },
    ],
  },
  {
    id: "Engagement",
    list: [
      {
        label: "Engagement",
        children: [
          { target: "/engagement/esg", label: "ESG" },
          { target: "/engagement/diversity-equity-inclusion", label: "Diversity, Equity, & Inclusion" },
        ],
      },
    ],
  },
  {
    id: "NewsInsights",
    list: [
      {
        label: "News & Insights",
        children: [
          { target: "/news-and-insights/insights", label: "Insights" },
          { target: "/news-and-insights/news", label: "Press Releases" },
          { target: "/news-and-insights/newsletter-archive/", label: "Newsletters" },
          { target: "/contact-us/#media-contacts", label: "Media Contacts" },
        ],
      },
    ],
  },
  {
    id: "About",
    list: [
      {
        label: "About",
        children: [
          { target: "/about-us", label: "About Us" },
          { target: "/about-us/who-we-are", label: "Who We Are" },
          { target: "/contact-us", label: "Contact Us" },
        ],
      },
    ],
  },
]);
const x = ref(0);
const innerWidth = ref(0);
const navbarSub = reactive({
  Approach: false,
  Platforms: false,
  Engagement: false,
  NewsInsinghts: false,
  About: false,
});
const route = useRoute();
const isTop: Ref<boolean> = ref(true);
const topPage: Ref<boolean> = ref(false);
onMounted(() => {
  window.addEventListener("scroll", handleScrollY);
  innerWidth.value = window.innerWidth;
});

const handleScrollY = () => {
  if (window.pageYOffset) {
    isTop.value = false;
  } else {
    isTop.value = true;
  }
};

const changeLanguge = (languge: string) => {  
  i18n.global.locale = languge
  open.value = false
}

const mouseleave = (name?: string) => {
  for (let key in navbarSub) {
    navbarSub[key] = false;
  }
  if (name) {
    x.value = 0;
    let dom = document.getElementById(name);
    let _dom = document.getElementById(name + "-menu");
    navbarSub[name] = true;
    nextTick(() => {
      if ((_dom?.clientWidth as number) > ((window.innerWidth - dom!.offsetLeft) as number)) {
        x.value = ((window.innerWidth - _dom!.clientWidth) as number) / 2;
      } else {
        x.value = (dom?.offsetLeft as number) - (((_dom?.clientWidth as number) - dom!.clientWidth) as number) / 2;
      }
    });
  } else {
    open.value = false;
  }
};

watch(
  () => route,
  () => {
    setTimeout(() => {
      if (route.name?.includes("news-and-insights")) {
        topPage.value = true;
      }
    }, 100);
  },
  { immediate: true }
);
</script>

<style lang="less" scoped>
.white{
  color: #333;
  div{
    background-color: #333;
  }
}
</style>
