<template>
    <div class="wp-block-cover__inner-container" id="Img">
            <slot></slot>
            <button
                class="absolute bottom-[30px] left-6 cursor-pointer lg:bottom-[96px] lg:left-[auto] lg:right-6 xl:bottom-[116px]"
                role="button" tabindex="0" aria-label="go to next section" data-sal="slide-up" data-sal-delay="150"
                data-sal-easing="ease-out-sine" data-sal-duration="600"><svg class="transition-all duration-300"
                    width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"
                    role="presentation" @click.stop="transformY()">
                    <circle class="stroke-theme fill-transparent transition-colors duration-300" cx="24" cy="24" r="23"
                        transform="rotate(90 24 24)" stroke-width="2"></circle>
                    <path class="stroke-white transition-all duration-300 delay-300" d="M31 21L24 28L17 21"
                        stroke-width="2"></path>
                    <path stroke-dasharray="0" class="stroke-white transition-all delay-100 duration-300"
                        d="M24 15L24 31" stroke-width="2" style="stroke-dasharray:16;stroke-dashoffset:-16"></path>
                </svg>
            </button>
        </div>
</template>

<script setup lang="ts">

const transformY = () => {
    window?.scrollTo({
        top: document.getElementById('Img')?.parentNode?.clientHeight as number - 108,
        left: 0,
        behavior: 'smooth'
    })
}
</script>

<style lang="less" scoped></style>